import { UniqueIdentifier } from '@dnd-kit/core';
import { Chip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react';
import { UserAvatar } from '../../../../../components';
import { useTypedSelector } from '../../../../../store/hooks';
import { getAssignedCollaboratorsSelector, Task } from '../../../../../store/slices';

type EditTaskSubtaskCollaboratorsComponent = React.FC<{
    taskId: UniqueIdentifier | null | undefined;
}>;

export const EditTaskSubtaskCollaborators: EditTaskSubtaskCollaboratorsComponent = ({ taskId }) => {
    const assignees = useTypedSelector((state) => getAssignedCollaboratorsSelector(state, taskId));
    return (
        <Stack direction='row' flexWrap='wrap'>
            {assignees?.map((assignee) => (
                <Box sx={{ p: 0.25 }} key={`subtask-${taskId}-${assignee?.username}`}>
                    <Chip
                        avatar={<UserAvatar user={assignee!} size={3} />}
                        label={`@${assignee?.username}`}
                        variant='outlined'
                        size='small'
                    />
                </Box>
            ))}
        </Stack>
    );
};
