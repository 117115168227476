import { UniqueIdentifier } from '@dnd-kit/core';
import { buildCreateApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';

import { api } from './api';

export type TaskLog = {
    id: UniqueIdentifier;
    message: string;
    createdAt: string;
    updatedAt: string;
    user?: UniqueIdentifier;
    task?: UniqueIdentifier;
};

export const tasksLogApi = api.enhanceEndpoints({ addTagTypes: ['task-log'] }).injectEndpoints({
    endpoints: (builder) => ({
        getTaskLog: builder.query<
            {
                data: Record<UniqueIdentifier, TaskLog>;
                meta: {
                    total: number;
                    offset: number;
                    limit: number;
                };
            },
            { id: UniqueIdentifier; offset?: number; limit?: number }
        >({
            query: ({ id, offset = 0, limit = 20 }) => `/tasks-logs/${id}?offset=${offset}&limit=${limit}`,
            // providesTags: (results) =>
            //     results
            //         ? [
            //               ...results.data.map(({ id }) => ({ type: 'task-log' as const, id })),
            //               { type: 'task-log', id: 'LIST' },
            //           ]
            //         : [{ type: 'task-log', id: 'LIST' }],
        }),
    }),
});

export const { useGetTaskLogQuery, useLazyGetTaskLogQuery } = tasksLogApi;
