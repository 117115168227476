import type { UniqueIdentifier } from '@dnd-kit/core';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';
import { SectionType } from '../slices';
import { Project } from '../slices/projects';
import { providesKeys, providesList } from '../utills';

import { treeApi } from './tree';

export type CreateProjectRequest = {
    project: {
        title: string;
        color: string;
        display: string;
    };
    leaf?: {
        parent: UniqueIdentifier;
        index?: number;
        collapsed: boolean;
    } | null;
    template?: {
        sections: {
            title: string;
            type: string;
        }[];
    };
};

export type UpdateProjectRequest = {
    id: UniqueIdentifier;
    title: string;
    color: string;
    display: string;
};

type ProjectsResponse = Record<UniqueIdentifier, Project>;
type ProjectResponse = Omit<Project, 'user' | 'users'>;

export const projectApi = treeApi
    .enhanceEndpoints({
        addTagTypes: ['project'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getProjects: builder.query<ProjectsResponse, void>({
                query: () => '/projects',
                providesTags: (results) => [...providesKeys(Object.keys(results || {}), 'project')],
            }),
            createProject: builder.mutation<ProjectsResponse, CreateProjectRequest>({
                query: (data) => ({
                    url: '/projects',
                    method: 'POST',
                    body: {
                        data,
                    },
                }),
                invalidatesTags: ['project', 'leaf', 'section'],
            }),
            editProject: builder.mutation<ProjectsResponse, UpdateProjectRequest>({
                query: (data) => ({
                    url: `/projects/${data.id}`,
                    method: 'PUT',
                    body: {
                        data,
                    },
                }),
                invalidatesTags: ['project'],
            }),
            deleteProject: builder.mutation<ProjectResponse, UniqueIdentifier>({
                query: (id) => ({
                    url: `/projects/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['project', 'leaf'],
            }),
            getProject: builder.query<ProjectResponse, UniqueIdentifier>({
                query: (id) => `/projects/${id}`,
            }),
        }),
    });

export const {
    useGetProjectsQuery,
    useCreateProjectMutation,
    useEditProjectMutation,
    useGetProjectQuery,
    useDeleteProjectMutation,
} = projectApi;
