import { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Project } from '../../../store/slices/projects';
import { Leaf } from '../../../store/slices/tree';
import { IDndEvent } from '../dashboardContext';

export type Tree = Leaf & {
    children: Tree[];
};

export const buildTree = (leaves: Record<UniqueIdentifier, Leaf>): Tree[] => {
    let sorted = Object.values(leaves).sort((a, b) => a.index - b.index);
    const hashTable = Object.create(null);

    sorted.forEach((value) => (hashTable[value.id] = { ...value, children: [] }));
    const tree = [] as Tree[];
    sorted.forEach((value) => {
        if (value.parent) hashTable[value.parent].children.push(hashTable[value.id]);
        else tree.push(hashTable[value.id]);
    });

    return tree;
};

export type FlattenTree = Tree & {
    depth: number;
};

export const flatten = (items: Tree[], parent: UniqueIdentifier | null = null, depth = 0): FlattenTree[] =>
    items.reduce(
        (acc, item) =>
            [
                ...acc,
                { ...item, parent: parent, depth: depth },
                ...flatten(item.children, item.id, depth + 1),
            ] as FlattenTree[],
        [] as FlattenTree[]
    );

export const filter = (items: FlattenTree[], active: UniqueIdentifier | null) => {
    const collapsed = items.reduce<UniqueIdentifier[]>(
        (acc, { children, collapsed, id }) => (collapsed && children.length ? [...acc, id] : acc),
        []
    );

    return removeChildrenOf(items, active ? [active, ...collapsed] : collapsed);
};

export const removeChildrenOf = (items: FlattenTree[], ids: UniqueIdentifier[]): FlattenTree[] => {
    const excludeParentIds = [...ids];

    return items.filter((item) => {
        if (item.parent && excludeParentIds.includes(item.parent)) {
            if (item.children.length) {
                excludeParentIds.push(item.id);
            }
            return false;
        }

        return true;
    });
};

const getDragDepth = (offset: number, indentationWidth: number) => Math.round(offset / indentationWidth);

const getMaxDepth = ({ previousItem }: { previousItem: FlattenTree }) =>
    previousItem ? previousItem.depth + 1 : 0;

const getMinDepth = ({ nextItem }: { nextItem: FlattenTree }) => (nextItem ? nextItem.depth : 0);

export const getProjection = (
    items: FlattenTree[],
    active: IDndEvent,
    over: IDndEvent,
    dragOffset: number,
    indentationWidth: number
) => {
    const activeIndex = items.findIndex(({ id }) => id === active.id);
    const overIndex = items.findIndex(({ id }) => id === over.id);

    if (activeIndex === -1) return null;

    const activeItem = items[activeIndex];
    const newItems = arrayMove(items, activeIndex, overIndex);

    const previousItem = newItems[overIndex - 1];
    const nextItem = newItems[overIndex + 1];

    const dragDepth = getDragDepth(dragOffset, indentationWidth);
    const projectedDepth = activeItem.depth + dragDepth;

    const maxDepth = getMaxDepth({
        previousItem,
    });
    const minDepth = getMinDepth({ nextItem });

    let depth = projectedDepth;

    if (projectedDepth >= maxDepth) {
        depth = maxDepth;
    } else if (projectedDepth < minDepth) {
        depth = minDepth;
    }

    function getParentId() {
        if (depth === 0 || !previousItem) {
            return null;
        }

        if (depth === previousItem.depth) {
            return previousItem.parent;
        }

        if (depth > previousItem.depth) {
            return previousItem.id;
        }

        const newParent = newItems
            .slice(0, overIndex)
            .reverse()
            .find((item) => item.depth === depth)?.parent;

        return newParent ?? null;
    }

    return { depth, maxDepth, minDepth, parent: getParentId() };
};

export const moveProjects = (
    flattened: FlattenTree[],
    over: UniqueIdentifier,
    active: UniqueIdentifier,
    depth: number,
    parent: UniqueIdentifier | null
): FlattenTree[] => {
    const clonedProjects: FlattenTree[] = JSON.parse(JSON.stringify(flattened));

    const overIndex = flattened.findIndex(({ id }) => id === over);
    const activeIndex = flattened.findIndex(({ id }) => id === active);

    console.log(overIndex, activeIndex);

    const activeTreeItem = clonedProjects[activeIndex];
    clonedProjects[activeIndex] = { ...activeTreeItem, depth, parent };
    const sortedProjects = arrayMove(clonedProjects, activeIndex, overIndex);

    //console.log(clonedProjects);
    //console.log(sortedProjects);

    // UNCOLLAPSE PARENT
    const parentIndex = sortedProjects.findIndex(({ id }) => id === parent);
    if (parentIndex !== -1) sortedProjects[parentIndex].collapsed = false;

    const indices = {} as Record<string, number>;

    //console.log(sortedProjects);

    const indxedProjects = sortedProjects.map((project) => {
        let parent = project.parent === null ? 'null' : project.parent;
        if (indices.hasOwnProperty(parent)) {
            indices[parent] = indices[parent] + 1;
            return { ...project, index: indices[parent] };
        } else {
            indices[parent] = 0;
            return { ...project, index: indices[parent] };
        }
    });

    return indxedProjects;
};

export function setProperty<T extends keyof Tree>(
    items: Tree[],
    id: UniqueIdentifier,
    property: T,
    setter: (value: Tree[T]) => Tree[T]
) {
    for (const item of items) {
        if (item.id === id) {
            item[property] = setter(item[property]);
            continue;
        }

        if (item.children.length) {
            item.children = setProperty(item.children, id, property, setter);
        }
    }

    return [...items];
}

function countChildren(items: Tree[], count = 0): number {
    return items.reduce((acc, { children }) => {
        if (children.length) {
            return countChildren(children, acc + 1);
        }

        return acc + 1;
    }, count);
}
