import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    TextFieldProps,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash-es';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ConfirmDialog } from '../../../../components/dialogs';
import { useTypedSelector } from '../../../../store/hooks';
import {
    Assigment,
    Collaborator,
    collaboratorSelector,
    collaboratorsSelector,
    Project,
    Task,
    taskSelector,
} from '../../../../store/slices';
import { DashboardContext, dashboardContext, useIsMobile } from '../../dashboardContext';
import {
    EditTaskAssignments,
    EditTaskAuthor,
    EditTaskGoBack,
    EditTaskLog,
    EditTaskSubtasks,
    TaskChat,
} from './components';

import { Cancel, Circle, FormatLineSpacing } from '@mui/icons-material';
import { format } from 'date-fns';
import { FluentEditProvider } from '@react-fluent-edit/core';
import { MentionsProvider } from '@react-fluent-edit/mentions';

import { TaskActions } from '../../project/actions/TaskActions';
import { Upload } from '../../../../components/upload';
import { StrapiMedia, useUpdateTaskMutation } from '../../../../store/api';
import { CompleteTask } from '../../components/CompleteTask';
import { useSnackbar } from 'notistack';

type EditTaskDialogComponent = React.FC<{
    task: Task;
    project: Project;
    assignments: Assigment[];
    subtasks: Task[];
    collaborators: Collaborator[];
}>;

export type EditTaskTask = Task & {
    assignments: Partial<Assigment>[];
    subtasks: Partial<Task>[];
};

const validationSchema = Yup.object().shape({
    title: Yup.string().max(800, 'Название слишком длинное').required('Название необходимо'),
    description: Yup.string().max(2400, 'Описание слишком длинное'),
    due: Yup.date().typeError('Дата указана не верно').nullable(),
    media: Yup.array(
        Yup.object().shape({
            id: Yup.number().integer(),
        })
    ).nullable(),
    assignmenyts: Yup.array(
        Yup.object().shape({
            id: Yup.number().integer().optional(),
            user: Yup.number().integer(),
            assigned: Yup.number().integer(),
            task: Yup.number().integer(),
        })
    ),
    project: Yup.number().integer().nullable(),
    section: Yup.number().integer().nullable(),
    index: Yup.number().integer().nullable(),
    subtasks: Yup.array(
        Yup.object().shape({
            index: Yup.number().integer(),
            title: Yup.string().max(800, 'Название слишком длинное').required('Название необходимо'),
            description: Yup.string().max(2400, 'Описание слишком длинное'),
        })
    ),
    color: Yup.string()
        .oneOf(['disabled', 'action', 'success', 'primary', 'secondary', 'error', 'info', 'warning', null])
        .nullable(),
});

export const EditTaskDialog: EditTaskDialogComponent = ({
    task,
    project,
    assignments,
    subtasks,
    collaborators,
}) => {
    const [searchParams] = useSearchParams();
    const isMobile = useIsMobile();
    const previousTask = useTypedSelector((state) => taskSelector(state, task.task));
    const author = useTypedSelector((state) => collaboratorSelector(state, task.user));
    const user = useTypedSelector((state) => state.user);
    const { colors } = useContext(dashboardContext) as DashboardContext;

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [updateTask, { isLoading, isError, error }] = useUpdateTaskMutation();

    const formik = useFormik<EditTaskTask>({
        initialValues: {
            ...task,
            assignments,
            subtasks,
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                await updateTask(values).unwrap();
            } catch (e: any) {
                enqueueSnackbar('Ошибка при обновлении задачи', { variant: 'error' });
                console.log(e);
            }
        },
        enableReinitialize: true,
    });

    const actions = useMemo(
        () => ({
            cancel: () =>
                navigate({ pathname: `/dashboard/projects/${project.id}`, search: searchParams.toString() }),
            goback: () =>
                previousTask && previousTask.project
                    ? navigate({
                        pathname: `/dashboard/projects/${previousTask.project}/tasks/${previousTask.id}/edit`,
                        search: searchParams.toString(),
                    })
                    : navigate({
                        pathname: `/dashboard/projects/${project.id}`,
                        search: searchParams.toString(),
                    }),
        }),
        [project, previousTask]
    );
    const [confirm, setConfirm] = useState<null | 'goback' | 'cancel'>(null);

    const handleClose = () => (isEmpty(formik.touched) ? actions.cancel() : setConfirm('cancel'));
    const handleGoBack = () => (isEmpty(formik.touched) ? actions.goback() : setConfirm('goback'));

    const handleConfirmCancel = () => setConfirm(null);
    const handleConfirmSuccess = () => {
        if (confirm) actions[confirm]();
        handleConfirmCancel();
    };

    const handleDate = (e: Date | null) => {
        formik.setFieldValue('due', e ? format(e, 'yyyy-MM-dd') : null, true);
        formik.handleBlur({ target: { name: 'due', value: e ? format(e, 'yyyy-MM-dd') : null } });
    };
    const handleDateClear = () => {
        formik.setFieldValue('due', null, true);
        formik.setTouched({ ...formik.touched, due: true });
    };

    const handleMediaClick = (media: StrapiMedia) => {
        navigate(
            {
                pathname: `/dashboard/projects/${project.id}/tasks/${task.id}/edit/media/${media.id}`,
                search: searchParams.toString(),
            },
            {
                state: formik.values.media,
            }
        );
    };

    const handleUploaded = (media: StrapiMedia[]) => {
        formik.setFieldValue('media', formik.values.media ? [...formik.values.media, ...media] : media);
    };

    const handleDeleted = (item: StrapiMedia) => {
        formik.setFieldValue(
            'media',
            formik.values.media!.filter(({ id }) => id !== item.id)
        );
    };

    // useEffect(() => {
    //     // stream update subtasks
    //     if (subtasks) {
    //         let results = formik.values.subtasks;
    //         for (let i = 0; i < results.length; i++) {
    //             if (results[i].id) {
    //                 const target = subtasks.find(({ id }) => id == results[i].id);
    //                 if (target) results[i] = target;
    //             }
    //         }
    //         formik.setFieldValue('subtasks', results, false);
    //     }
    // }, [subtasks]);

    useEffect(() => {
        if (task) {
            Object.entries(task).forEach(([key, value]) => {
                if (formik.values[key as keyof Task] != value) {
                    formik.setFieldValue(key, value);
                }
            });
        }
    }, [task]);

    return (
        <>
            <Dialog
                open
                onClose={handleClose}
                fullScreen={isMobile}
                //sx={{ p: isMobile ? 2 : 0 }}
                scroll='body'
                maxWidth='sm'
                fullWidth
                keepMounted={false}
            >
                <DialogTitle>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <EditTaskGoBack previousTask={previousTask} onClick={handleGoBack} />
                        <EditTaskAuthor author={author} />
                        <span style={{ flexGrow: 1 }}>Редактировать задачу {task.id}</span>
                        <CompleteTask task={task} />
                        <EditTaskLog task={task} />
                        <TaskActions task={task} />
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ ...(isMobile && { p: 1 }) }}>
                    <Stack spacing={1} sx={{ py: 2 }}>
                        <TextField
                            label='Название'
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name='title'
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                            multiline
                            fullWidth
                        />
                        <TextField
                            size='small'
                            label='Описание'
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name='description'
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            InputProps={{ sx: { fontSize: (theme) => theme.typography.body2.fontSize } }}
                            multiline
                            minRows={4}
                        />
                        <EditTaskSubtasks
                            subtasks={formik.values.subtasks}
                            formik={formik}
                            collaborators={collaborators}
                        />
                        <DatePicker
                            label='Срок выполнения'
                            value={formik.values.due}
                            disablePast
                            onChange={handleDate}
                            renderInput={(params: TextFieldProps) => (
                                <TextField
                                    sx={{ flex: 1 }}
                                    fullWidth
                                    name='due'
                                    //size='small'
                                    onBlur={(e) => formik.handleBlur(e)}
                                    {...params}
                                    error={formik.touched.due && Boolean(formik.errors.due)}
                                    helperText={formik.touched.due && formik.errors.due}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: formik.values.due ? (
                                            <IconButton sx={{ pr: 1 }} onClick={handleDateClear}>
                                                <Cancel />
                                            </IconButton>
                                        ) : undefined,
                                    }}
                                />
                            )}
                        />
                        {collaborators && (
                            <EditTaskAssignments
                                formik={formik}
                                collaborators={collaborators}
                                user={user as Collaborator}
                                task={task}
                            />
                        )}
                        <Select
                            name='color'
                            onChange={formik.handleChange}
                            value={formik.values.color ? formik.values.color : 'action'}
                            error={formik.touched.color && Boolean(formik.errors.color)}
                        >
                            {Object.entries(colors).map(([key, value]) => (
                                <MenuItem value={key} key={`color-${key}-${value}`}>
                                    <Stack direction='row' alignItems='center'>
                                        <Circle sx={{ color: value, mr: 1 }} />
                                        {key}
                                    </Stack>
                                </MenuItem>
                            ))}
                        </Select>
                        <Upload
                            media={formik.values.media}
                            onClick={handleMediaClick}
                            onUploaded={handleUploaded}
                            onDeleted={handleDeleted}
                            size='medium'
                        />
                        <FluentEditProvider providers={[<MentionsProvider />]}>
                            <TaskChat task={task} collaborators={collaborators} />
                        </FluentEditProvider>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <LoadingButton variant='contained' onClick={() => formik.handleSubmit()}>
                        Редактировать
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                open={Boolean(confirm)}
                onCancel={handleConfirmCancel}
                onConfirm={handleConfirmSuccess}
            />
            <Outlet />
        </>
    );
};
