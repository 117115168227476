import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';
import { StrapiMedia } from '.';

import { authApi } from './auth';
import { Collaborator } from '../slices/collaborators';
import { UniqueIdentifier } from '@dnd-kit/core';

type UserResponse = Omit<Collaborator, 'avatar'> & { avatar: StrapiMedia | null };

export const userApi = authApi
    .enhanceEndpoints({
        addTagTypes: ['profile'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUserProfile: builder.query<UserResponse, void>({
                query: () => `/users/me?populate=avatar`,
                providesTags: ['profile'],
            }),
            updateUserProfile: builder.mutation<
                UserResponse,
                {
                    id: UniqueIdentifier;
                    data:
                        | Partial<Collaborator>
                        | Partial<Omit<Collaborator, 'avatar'> & { avatar: StrapiMedia[] }>;
                }
            >({
                query: ({ id, data }) => ({
                    url: `/users/${id}?populate=avatar`,
                    method: 'PUT',
                    body: {
                        ...data,
                    },
                }),
                invalidatesTags: ['profile'],
            }),
            deleteUserAvatar: builder.mutation<StrapiMedia, UniqueIdentifier>({
                query: (id) => ({ url: `/upload/files/${id}`, method: 'DELETE' }),
                invalidatesTags: ['profile'],
            }),

            inviteUser: builder.mutation<{ success: string }, { email: string }>({
                query: (data) => ({ url: `/user-invites`, method: 'POST', body: { data } }),
            }),
        }),
    });

export const {
    useGetUserProfileQuery,
    useUpdateUserProfileMutation,
    useDeleteUserAvatarMutation,
    useInviteUserMutation,
} = userApi;
