import { UniqueIdentifier, useDndMonitor } from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useMemo, useRef } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { SEO } from '../../../components/SEO';
import { useAddSectionMutation, useUpdateSectionsMutation } from '../../../store/api/section';
import { useUpdateTasksMutation } from '../../../store/api/task';
import { useTypedDispatch, useTypedSelector } from '../../../store/hooks';
import { moveTasks } from '../../../store/slices/tasks';
import { ProjectActions } from '../actions/ProjectActions';
import { DashboardContext, dashboardContext } from '../dashboardContext';
import { AddTask } from '../task';
import { arrayRemove, dndType } from '../utils';
import { AddSection } from './actions/AddSection';
import { ProjectDivider } from './components/ProjectDivider';
//import { AddTask } from './actions/AddTask';
import { Section } from './section/Section';
import { Task } from './task/Task';
import { dragOverTaskSection, dragOverTaskTask, findSectionByTask } from './utils';

interface IProject { }

export const Project: React.FC<IProject> = ({ }) => {
    let { projectId } = useParams();
    const [searchParams] = useSearchParams();

    const [updateTasks, updateTasksAction] = useUpdateTasksMutation();
    const [updateSections, updateSectionsActions] = useUpdateSectionsMutation();
    const [addSection, addSectionActions] = useAddSectionMutation();

    const { active, over, reset, isMobile } = useContext(dashboardContext) as DashboardContext;
    const projects = useTypedSelector((state) => state.projects);
    const sections = useTypedSelector((state) => state.sections);
    const tasks = useTypedSelector((state) => state.tasks);
    const dispatch = useTypedDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const project = useMemo(() => (projectId ? projects[projectId] : null), [projects, projectId]);

    const invalidate = useRef<Boolean>(false);

    const items = useMemo(
        () =>
            project
                ? Object.values(sections)
                    .filter(({ project: p }) => p === project.id)
                    .sort((a, b) => a.index - b.index)
                    .map((section) => ({
                        ...section,
                        tasks: Object.values(tasks)
                            .filter(({ section: id }) => id === section.id)
                            .sort((a, b) => a.index - b.index),
                    }))
                : [],
        [project, sections, tasks]
    );

    const itemIds = useMemo(() => items.map(({ id }) => `section-${id}`), [items]);

    useDndMonitor({
        onDragOver: (e) => {
            if (dndType(e, 'task', 'section') && e.over) {
                const result = dragOverTaskSection(
                    items,
                    e.active.data.current!.id,
                    e.over!.data.current!.id
                );
                if (result) {
                    dispatch(moveTasks(result));
                    invalidate.current = true;
                }
            } else if (dndType(e, 'task', 'task') && e.over) {
                const result = dragOverTaskTask(
                    items,
                    e.active.data.current!.id,
                    e.over!.data.current!.id,
                    e
                );
                if (result) {
                    dispatch(moveTasks(result));
                    invalidate.current = true;
                }
            }
        },
        onDragEnd: async (e) => {
            if (dndType(e, 'section', 'section') && e.over) {
                //console.log(e.over.data.current);
                const activeIndex = e.active.data.current!.sortable.index;
                const overIndex = e.over.data.current!.sortable.index;
                //console.log(activeIndex, overIndex);

                const result = arrayMove(items, activeIndex, overIndex).map((section, index) => ({
                    ...section,
                    index,
                }));

                updateSections(result.map(({ id, index }) => ({ id, index }))).unwrap();
            }
            if (dndType(e, 'task', 'task') || (dndType(e, 'task', 'section') && invalidate.current)) {
                invalidate.current = false;
                const data = items.reduce(
                    (acc, item) => [
                        ...acc,
                        ...item.tasks.map(({ id, index, section }) => ({
                            id,
                            index,
                            section,
                        })),
                    ],
                    [] as { id: UniqueIdentifier; index: number; section: UniqueIdentifier | null }[]
                );

                await updateTasks(data);
            }
            if (dndType(e, 'task', 'new-section')) {
                const activeTaskId = e.active.data.current?.id;
                const activeTask = tasks[activeTaskId];
                if (activeTaskId && activeTask) {
                    const activeSection = findSectionByTask(items, activeTaskId);
                    if (activeSection && project) {
                        const section = await addSection({
                            title: 'Новый раздел',
                            project: project.id,
                            index: items.length,
                        }).unwrap();

                        if (section) {
                            await updateTasks([
                                {
                                    ...activeTask,
                                    section: section.id,
                                    index: 0,
                                },
                            ]);
                        }
                    }
                }
            }
            if (dndType(e, 'section', 'new-section')) {
                const activeSectionId = e.active.data.current?.id;
                const activeSection = sections[activeSectionId];
                if (activeSectionId && activeSection && project) {
                    const section = await addSection({
                        title: 'Новый раздел',
                        project: project.id,
                        index: items.length - 1,
                    }).unwrap();
                    if (section) {
                        const sections = [...arrayRemove(items, activeSection.index), section, activeSection];
                        await updateSections(sections.map(({ id }, index) => ({ id, index })));
                    }
                }
            }
            if (dndType(e, 'task', 'project') || dndType(e, 'section', 'project')) {
                enqueueSnackbar('Не имплементировано', { variant: 'error' });
            }
        },
    });

    const visibilityChecked = searchParams.get('visibility');

    return (
        <>
            <SEO title={`${project?.title}`} />
            <Box
                sx={{
                    pl: 2,
                    pt: 2,
                    //height: '100%', overflow: 'auto'
                }}
            >
                <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography variant='h3' sx={{ py: 2 }}>
                        {project?.title} {project?.id}
                    </Typography>
                    {project && <ProjectActions project={project} />}
                    <Stack sx={{ flex: 1, pr: 2, alignItems: 'flex-end', justifyContent: 'center' }}></Stack>
                </Stack>
                <Grid container flexWrap={isMobile ? 'wrap' : 'nowrap'}>
                    <SortableContext
                        items={[...itemIds, 'new-section']}
                        strategy={horizontalListSortingStrategy}
                    >
                        {items.map((section) => (
                            <React.Fragment key={`section-${section.id}`}>
                                <Section section={section} project={project!.id}>
                                    <SortableContext
                                        items={section.tasks.map(({ id }) => `task-${id}`)}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        {section.tasks.map((task) => (
                                            <Task
                                                key={`task-${task.id}`}
                                                task={task}
                                                section={section.id}
                                                project={project!.id}
                                                over={over?.type === 'task' && over?.id === task.id}
                                                sectionType={section.type}
                                                visibility={visibilityChecked}
                                            />
                                        ))}
                                        {project && (
                                            // <AddTask
                                            //     section={section.id}
                                            //     project={project.id}
                                            //     index={section.tasks.length}
                                            // />
                                            <AddTask
                                                sectionId={section.id}
                                                projectId={project.id}
                                                index={section.tasks.length}
                                            />
                                        )}
                                    </SortableContext>
                                </Section>
                                <ProjectDivider section={section} />
                            </React.Fragment>
                        ))}
                        {project && <AddSection index={items?.length} project={project.id} />}
                    </SortableContext>
                </Grid>
            </Box>
            <Outlet />
        </>
    );
};
