import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useCallback } from 'react';
import { Route, Routes } from 'react-router';
import { PrivateRoute } from './components/PrivateRoute';
import { ForgotPassword } from './containers/auth/ForgotPassword';
import { Login } from './containers/auth/Login';
import { Register } from './containers/auth/Register';
import { ResetPassword } from './containers/auth/ResetPassword';
import { AcceptProject } from './containers/dashboard/actions/AcceptProject';
import { DeleteProject } from './containers/dashboard/actions/DeleteProject';
import { DeleteSection } from './containers/dashboard/actions/DeleteSection';
import { DeleteTask } from './containers/dashboard/actions/DeleteTask';
import { EditProject } from './containers/dashboard/actions/EditProject';
import { EditSection } from './containers/dashboard/actions/EditSection';
//import { EditTask } from './containers/dashboard/actions/EditTask';
//import { EditTaskChatLightbox } from './containers/dashboard/actions/EditTask/components/EditTaskChat/EditTaskChatLightbox';
//import { EditTaskLightbox } from './containers/dashboard/actions/EditTaskLightbox';
import { ShareProject } from './containers/dashboard/actions/ShareProject';
import { DashboardLayout } from './containers/dashboard/Dashboard';
import { Project } from './containers/dashboard/project/Project';
import { Welcome } from './containers/dashboard/welcome';
import { Home } from './containers/home';
import { Invites } from './containers/user/tabs/Invites';
import { Password, Profile, Settings, User } from './containers/user/';
import { DndTest } from './tests/DndTest';
import { NotificationsSettings } from './containers/user/tabs/NotificationsSettings';
import { EditTask, EditTaskLightbox } from './containers/dashboard/task/EditTask';
import { ChatLightbox } from './containers/dashboard/task/TaskChat';

const App = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route
                path='/dashboard/*'
                element={
                    <PrivateRoute>
                        <Routes>
                            <Route element={<DashboardLayout />}>
                                <Route index element={<Welcome />} />
                                <Route path='projects/:projectId' element={<Project />}>
                                    <Route path='edit' element={<EditProject />} />
                                    <Route path='share' element={<ShareProject />} />
                                    <Route path='accept' element={<AcceptProject />} />
                                    <Route path='delete' element={<DeleteProject />} />
                                    <Route path='sections/:sectionId'>
                                        <Route path='edit' element={<EditSection />} />
                                        <Route path='delete' element={<DeleteSection />} />
                                    </Route>
                                    <Route path='tasks/:taskId'>
                                        <Route path='edit' element={<EditTask />}>
                                            <Route path='media/:mediaId' element={<EditTaskLightbox />} />
                                            <Route path='messages/:messageId'>
                                                <Route path='media/:mediaId' element={<ChatLightbox />} />
                                            </Route>
                                        </Route>
                                        <Route path='delete' element={<DeleteTask />} />
                                    </Route>
                                </Route>
                                <Route path='user' element={<User />}>
                                    <Route path='profile' element={<Profile />}>
                                        <Route path='password' element={<Password />} />
                                    </Route>
                                    <Route path='settings' element={<Settings />} />
                                    <Route path='notifications' element={<NotificationsSettings />} />
                                    <Route path='invites' element={<Invites />} />
                                </Route>
                            </Route>
                        </Routes>
                    </PrivateRoute>
                }
            />
            <Route path='/test' element={<DndTest />} />
        </Routes>
    );
};

export default App;
