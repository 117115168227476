import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Collaborator, collaboratorsSelector, Task } from '../../../../store/slices';
import { UniqueIdentifier } from '@dnd-kit/core';
import {
    Autocomplete,
    Box,
    Button,
    Collapse,
    FormControl,
    FormControlLabel,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material';
import { DashboardContext, dashboardContext } from '../../dashboardContext';
import {
    Add,
    AddCircleOutlineOutlined,
    Cancel,
    CheckCircle,
    Circle,
    Delete,
    RadioButtonUnchecked,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import { StrapiMedia, useAddTaskMutation } from '../../../../store/api';
import { useTypedSelector } from '../../../../store/hooks';
import { UserAvatar } from '../../../../components/UserAvatar';
import { Upload } from '../../../../components/upload';
import { values } from 'lodash';

type AddTaskComponent = React.FC<{
    projectId: UniqueIdentifier;
    sectionId: UniqueIdentifier;
    index: number;
}>;

const validationSchema = Yup.object().shape({
    title: Yup.string().max(800, 'Название слишком длинное').required('Название необходимо'),
    description: Yup.string().max(2400, 'Описание слишком длинное'),
    due: Yup.date().typeError('Дата указана не верно').nullable(),
    media: Yup.array(
        Yup.object().shape({
            id: Yup.number().integer(),
        })
    ),
    assignments: Yup.array(Yup.number().integer()),
    project: Yup.number().integer().nullable(),
    section: Yup.number().integer().nullable(),
    index: Yup.number().integer().nullable(),
    subtasks: Yup.array(
        Yup.object().shape({
            index: Yup.number().integer(),
            title: Yup.string().max(800, 'Название слишком длинное').required('Название необходимо'),
            description: Yup.string().max(2400, 'Описание слишком длинное'),
        })
    ),
    color: Yup.string()
        .oneOf(['disabled', 'action', 'success', 'primary', 'secondary', 'error', 'info', 'warning', null])
        .nullable(),
});

const subtaskValidationSchema = Yup.object().shape({
    index: Yup.number().integer(),
    title: Yup.string().max(800, 'Название слишком длинное').required('Название необходимо'),
    description: Yup.string().max(2400, 'Описание слишком длинное'),
});

export const AddTask: AddTaskComponent = ({ projectId, sectionId, index }) => {
    const [addTask, { isLoading, isError, error }] = useAddTaskMutation();
    const { drag, colors } = useContext(dashboardContext) as DashboardContext;
    const collaborators = useTypedSelector((state) => collaboratorsSelector(state, projectId));
    const formik = useFormik<{
        title: string;
        description: string;
        assignments: UniqueIdentifier[];
        due: Date | null;
        media: StrapiMedia[];
        project: UniqueIdentifier;
        section: UniqueIdentifier;
        index: number;
        subtasks: {
            index: number;
            title: string;
            description: string;
        }[];
        color: 'disabled' | 'action' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' | null;
    }>({
        initialValues: {
            title: '',
            description: '',
            assignments: [],
            due: null,
            media: [],
            project: projectId,
            section: sectionId,
            index: index,
            subtasks: [],
            color: null,
        },
        validationSchema,
        onSubmit: async (values) => {
            handleClose();
            await addTask(values);
        },
    });
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        formik.resetForm();
        setOpenSubtask(false);
    };

    const handleDate = (e: Date | null) => {
        formik.setFieldValue('due', e ? format(e, 'yyyy-MM-dd') : null, true);
        formik.handleBlur({ target: { name: 'due', value: e ? format(e, 'yyyy-MM-dd') : null } });
    };
    const handleDateClear = () => {
        formik.setFieldValue('due', null, true);
        formik.setTouched({ ...formik.touched, due: true });
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        await formik.handleSubmit();
    };

    const [subtaskIndex, setSubtaskIndex] = useState(formik.values.subtasks.length);
    const subtaskFormik = useFormik<{ title: string; description: string; index: number }>({
        initialValues: {
            title: '',
            description: '',
            index: subtaskIndex,
        },
        validationSchema: subtaskValidationSchema,
        onSubmit: (values) => {
            formik.setFieldValue('subtasks', [...formik.values.subtasks, values]);
            setSubtaskIndex(formik.values.subtasks.length);
            subtaskFormik.resetForm();
        },
        //enableReinitialize: true,
    });
    const [openSubtask, setOpenSubtask] = useState(false);
    const handleOpenSubtask = () => {
        subtaskFormik.resetForm();
        setOpenSubtask(true);
    };
    const handleCloseSubtask = () => setOpenSubtask(false);
    const handleAddSubtask = () => subtaskFormik.handleSubmit();
    const handleDeleteSubtask = (idx: number) => {
        formik.setFieldValue(
            'subtasks',
            formik.values.subtasks.filter((_, i) => i !== idx)
        );
        setSubtaskIndex(formik.values.subtasks.length);
    };

    const [inputValue, setInputValue] = useState('');
    const handleAssignmentsChange = (e: React.SyntheticEvent<Element, Event>, value: Collaborator[]) => {
        formik.setFieldValue(
            'assignments',
            value.map(({ id }) => id)
        );
    };

    const handleAssignmentsInputChange = (e: React.SyntheticEvent<Element, Event>, value: string) => {
        setInputValue(value);
    };

    const handleUploaded = (media: StrapiMedia[]) => {
        formik.setFieldValue('media', [...formik.values.media, ...media]);
    };

    const handleDeleted = (item: StrapiMedia) => {
        formik.setFieldValue(
            'media',
            formik.values.media.filter(({ id }) => id !== item.id)
        );
    };

    return (
        <Box sx={{ py: 2 }} data-no-dnd='true'>
            {open ? (
                <Stack spacing={1}>
                    <TextField
                        size='small'
                        label='Название'
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name='title'
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                        multiline
                    />
                    <TextField
                        size='small'
                        label='Описание'
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name='description'
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                        InputProps={{ sx: { fontSize: (theme) => theme.typography.body2.fontSize } }}
                        multiline
                        minRows={4}
                    />

                    <FormControl variant='outlined'>
                        <Paper variant='outlined'>
                            <Paper variant='outlined' sx={{ m: 0.5 }}>
                                <Stack sx={{ px: 1, py: 0.5 }}>
                                    {formik.values.subtasks.map((subtask, i) => (
                                        <Stack
                                            key={`add-subtask-${i}`}
                                            direction='row'
                                            spacing={1}
                                            alignItems='center'
                                        >
                                            <RadioButtonUnchecked
                                                sx={{ fontSize: (theme) => theme.typography.body2 }}
                                            />
                                            <Typography variant='body2' sx={{ flex: 1 }}>
                                                {subtask.title}
                                            </Typography>
                                            <IconButton size='small' onClick={() => handleDeleteSubtask(i)}>
                                                <Delete fontSize='small' />
                                            </IconButton>
                                        </Stack>
                                    ))}
                                </Stack>
                            </Paper>
                            <Stack justifyContent='center' sx={{ px: 0.05, pt: 0.25 }}>
                                <Collapse in={openSubtask}>
                                    <Stack spacing={0.5} sx={{ p: 0.5 }}>
                                        <TextField
                                            size='small'
                                            label='Название'
                                            value={subtaskFormik.values.title}
                                            onChange={subtaskFormik.handleChange}
                                            onBlur={subtaskFormik.handleBlur}
                                            name='title'
                                            error={
                                                subtaskFormik.touched.title &&
                                                Boolean(subtaskFormik.errors.title)
                                            }
                                            helperText={
                                                subtaskFormik.touched.title && subtaskFormik.errors.title
                                            }
                                            multiline
                                        />
                                        <TextField
                                            size='small'
                                            label='Описание'
                                            value={subtaskFormik.values.description}
                                            onChange={subtaskFormik.handleChange}
                                            onBlur={subtaskFormik.handleBlur}
                                            name='description'
                                            error={
                                                subtaskFormik.touched.description &&
                                                Boolean(subtaskFormik.errors.description)
                                            }
                                            helperText={
                                                subtaskFormik.touched.description &&
                                                subtaskFormik.errors.description
                                            }
                                            multiline
                                        />
                                    </Stack>
                                </Collapse>

                                <Stack
                                    direction='row'
                                    justifyContent={openSubtask ? 'end' : 'center'}
                                    spacing={1}
                                    sx={{ py: 0.5, px: 0.5 }}
                                >
                                    {openSubtask ? (
                                        <>
                                            <Button
                                                size='small'
                                                color='secondary'
                                                onClick={handleCloseSubtask}
                                            >
                                                Отменить
                                            </Button>
                                            <Button
                                                size='small'
                                                variant='contained'
                                                onClick={handleAddSubtask}
                                            >
                                                Добавить
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            color='secondary'
                                            size='small'
                                            onClick={handleOpenSubtask}
                                            startIcon={<AddCircleOutlineOutlined />}
                                            sx={{ flex: 1, alignItems: 'center', lineHeight: 0 }}
                                        >
                                            Добавить подзадачу
                                        </Button>
                                    )}
                                </Stack>
                            </Stack>
                        </Paper>
                    </FormControl>
                    <DatePicker
                        label='Срок выполнения'
                        value={formik.values.due}
                        disablePast
                        onChange={handleDate}
                        renderInput={(params: TextFieldProps) => (
                            <TextField
                                sx={{ flex: 1 }}
                                name='due'
                                size='small'
                                onBlur={(e) => formik.handleBlur(e)}
                                {...params}
                                error={formik.touched.due && Boolean(formik.errors.due)}
                                helperText={formik.touched.due && formik.errors.due}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: formik.values.due ? (
                                        <IconButton sx={{ pr: 1 }} onClick={handleDateClear}>
                                            <Cancel />
                                        </IconButton>
                                    ) : undefined,
                                }}
                            />
                        )}
                    />
                    {collaborators && (
                        <Autocomplete
                            size='small'
                            multiple
                            value={collaborators.filter(({ id }) => formik.values.assignments.includes(id))}
                            inputValue={inputValue}
                            onChange={handleAssignmentsChange}
                            onInputChange={handleAssignmentsInputChange}
                            options={collaborators}
                            autoHighlight
                            getOptionLabel={(option) => option.username}
                            filterOptions={(options, { inputValue }) =>
                                options.filter(
                                    ({ username, firstname, lastname, email }) =>
                                        username.startsWith(inputValue) ||
                                        firstname.startsWith(inputValue) ||
                                        lastname.startsWith(inputValue) ||
                                        email.startsWith(inputValue)
                                )
                            }
                            renderOption={(props, user) => (
                                <MenuItem
                                    sx={{
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                    }}
                                    {...props}
                                >
                                    <UserAvatar user={user} />
                                    <Stack>
                                        <Typography variant='h6'>
                                            {user.lastname} {user.firstname}
                                        </Typography>
                                        <Typography variant='caption'>
                                            {user.username} {user.email}
                                        </Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            isOptionEqualToValue={(o, v) => o.id === v.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Выберите пользователя'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    )}
                    <Select
                        name='color'
                        onChange={formik.handleChange}
                        value={formik.values.color ? formik.values.color : 'action'}
                        error={formik.touched.color && Boolean(formik.errors.color)}
                        size='small'
                    >
                        {Object.entries(colors).map(([key, value]) => (
                            <MenuItem value={key} key={`color-${key}-${value}`}>
                                <Stack direction='row' alignItems='center'>
                                    <Circle sx={{ color: value, mr: 1 }} />
                                    {key}
                                </Stack>
                            </MenuItem>
                        ))}
                    </Select>
                    <Upload
                        media={formik.values.media}
                        onUploaded={handleUploaded}
                        onDeleted={handleDeleted}
                    />
                    <Stack direction='row' justifyContent='end' spacing={1}>
                        <Button color='secondary' onClick={handleClose}>
                            Отменить
                        </Button>
                        <LoadingButton variant='contained' onClick={handleSubmit} loading={isLoading}>
                            Добавить
                        </LoadingButton>
                    </Stack>
                </Stack>
            ) : (
                <Button
                    color='secondary'
                    sx={{ alignItems: 'center', lineHeight: 0 }}
                    startIcon={<AddCircleOutlineOutlined />}
                    onClick={handleOpen}
                    disabled={Boolean(drag)}
                >
                    Добавить задачу
                </Button>
            )}
        </Box>
    );
};
