import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ErrorDialog } from '../../../../components/dialogs';
import { useTypedSelector } from '../../../../store/hooks';
import { EditTaskDialog } from './EditTaskDialog';

import {
    taskSelector,
    projectSelector,
    assignmentsSelector,
    subtasksSelector,
    collaboratorsSelector,
} from '../../../../store/slices';
import { Dialog } from '@mui/material';
import { SEO } from '../../../../components';
import { EditTaskLog } from './components/EditTaskLog';

type EditTaskComponent = React.FC<{}>;

export const EditTask: EditTaskComponent = ({ }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { projectId, taskId } = useParams();

    const project = useTypedSelector((state) => projectSelector(state, projectId));
    const task = useTypedSelector((state) => taskSelector(state, taskId));
    const assignments = useTypedSelector((state) => assignmentsSelector(state, taskId));
    const subtasks = useTypedSelector((state) => subtasksSelector(state, taskId));
    const collaborators = useTypedSelector((state) => collaboratorsSelector(state, projectId));

    const handleError = () => {
        if (project) navigate({ pathname: `/dashborad/projects/${project.id}`, search: searchParams.toString() });
        else navigate(`/dashboard`);
    };

    return (
        <>
            {project && task ? (
                <>
                    <SEO title={`${project.title} | ${task.title}`} />
                    <EditTaskDialog
                        project={project}
                        task={task}
                        assignments={assignments}
                        subtasks={subtasks}
                        collaborators={collaborators}
                    />
                    <EditTaskLog task={task} />
                </>
            ) : (
                <ErrorDialog onConfirm={handleError} />
            )}
        </>
    );
};
