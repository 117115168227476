import React, { CSSProperties } from 'react';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
    Collapse,
    Divider,
    IconButton,
    LinearProgress,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material';
import {
    Add,
    Collections,
    ExpandLess,
    ExpandMore,
    Folder,
    PlusOne,
    TheaterComedySharp,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface ISortableTreeItem {
    id: string;
    depth: number;
    clone?: boolean;
    collapsed?: boolean;
    collapsible?: boolean;
    childCount?: number;
    disableInteraction?: boolean;
    disableSelection?: boolean;
    ghost?: boolean;
    handleProps?: any;
    indicator?: boolean;
    indentationWidth: number;
    value: string;
    onCollapse?(): void;
    onRemove?(): void;
    wrapperRef?(node: HTMLLIElement): void;
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
    isSorting || wasDragging ? false : true;

export const SortableTreeItem: React.FC<ISortableTreeItem> = ({
    id,
    depth,
    value,
    collapsed,
    onCollapse,
    collapsible,
    onRemove,
    indentationWidth,
    clone,
}) => {
    const {
        attributes,
        isDragging,
        isSorting,
        listeners,
        setDraggableNodeRef,
        setDroppableNodeRef,
        transform,
        transition,
    } = useSortable({
        id,
        data: { type: 'project' },
        animateLayoutChanges,
    });

    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    const navigate = useNavigate();

    const handleClik = () => navigate(`/dashboard/project/${id}`);

    return (
        <ListItem
           ref={setDroppableNodeRef}
            sx={{
                ml: depth * 4,
                background: isDragging
                    ? (theme) => theme.palette.primary.main
                    : clone
                    ? (theme) => theme.palette.background.paper
                    : undefined,
                opacity: clone ? 0.5 : 1.0,
                '&:before': isDragging
                    ? {
                          content: '""',
                          position: 'absolute',
                          top: -2,
                          left: -6,
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          border: '1px solid',
                          borderColor: (theme) => theme.palette.primary.main,
                          background: 'white',
                      }
                    : undefined,
                touchAction: 'none',
            }}
            disablePadding
            disableGutters
            style={style}
        >
            <ListItemAvatar>
                {collapsible && (
                    <Collapse in={!isDragging}>
                        <IconButton onClick={onCollapse} disabled={clone}>
                            {collapsed ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Collapse>
                )}
            </ListItemAvatar>
            <ListItemText>
                {/* TODO FIX */}
                <Collapse in={!isDragging}>
                    <ListItemButton
                        sx={{ cursor: 'grab' }}
                        ref={setDraggableNodeRef}
                        onClick={handleClik}
                        {...attributes}
                        {...listeners}
                    >
                        {value}
                    </ListItemButton>
                </Collapse>
            </ListItemText>

            <ListItemSecondaryAction>
                <Collapse in={!isDragging}>
                    <IconButton onClick={onRemove} disabled={clone}>
                        <Add />
                    </IconButton>
                </Collapse>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
