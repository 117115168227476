import { Delete, Download, ErrorOutline, ZoomIn } from '@mui/icons-material';
import { Avatar, AvatarProps, Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import useMeasure from 'react-use-measure';
import { StrapiMedia } from '../../store/api';
import { UploadItem } from './Upload';
import { isImage } from './utils';
import { saveAs } from 'file-saver';
import { thumbnailSizes as sizes } from '../../theme';

type UploadItemStrapiComponent = React.FC<
    {
        size?: 'small' | 'medium' | 'large';
        item: StrapiMedia;
        onClick?: (item: StrapiMedia) => void;
        onDelete?: (item: StrapiMedia) => void;
    } & Omit<AvatarProps, 'onClick'>
>;

export const UploadItemStrapi: UploadItemStrapiComponent = ({
    item,
    onClick,
    onDelete,
    size = 'small',
    ...props
}) => {
    const [hover, setHover] = useState(false);
    const image = useMemo(() => isImage(item.mime), [item]);
    const preview = useMemo(
        () =>
            image
                ? item.formats
                    ? item.formats.thumbnail
                        ? item.formats.thumbnail.url
                        : item.formats.small
                        ? item.formats.small.url
                        : item.formats.medium
                        ? item.formats.medium.url
                        : item.formats.large
                        ? item.formats.large.url
                        : item.url
                    : item.url
                : item.ext,
        [image]
    );

    return (
        <Box
            sx={{ position: 'relative', p: 0.25 }}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(false)}
        >
            {size === 'small' ? (
                <>
                    {onClick ? (
                        <IconButton
                            size={size}
                            sx={{
                                position: 'absolute',
                                zIndex: 1,
                                opacity: hover ? 1.0 : 0,
                                left: '50%',
                                top: '50%',
                                transform: `translate(-50%,-50%)`,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClick(item);
                            }}
                        >
                            <ZoomIn fontSize={size} />
                        </IconButton>
                    ) : (
                        onDelete && (
                            <IconButton
                                sx={{ position: 'absolute', zIndex: 1, opacity: hover ? 1.0 : 0 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete(item);
                                }}
                            >
                                <Delete />
                            </IconButton>
                        )
                    )}
                </>
            ) : (
                <>
                    {onClick && (
                        <IconButton
                            size={size}
                            sx={{
                                position: 'absolute',
                                zIndex: 1,
                                opacity: hover ? 1.0 : 0,
                                left: '50%',
                                top: '50%',
                                transform: `translate(-50%,-50%)`,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClick(item);
                            }}
                        >
                            <ZoomIn fontSize={size} />
                        </IconButton>
                    )}
                    {onDelete && (
                        <IconButton
                            size='small'
                            sx={{
                                position: 'absolute',
                                zIndex: 1,
                                opacity: hover ? 1.0 : 0,
                                right: 0,
                                top: 0,
                                transform: `translate(25%,-25%)`,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(item);
                            }}
                        >
                            <Delete fontSize='small' />
                        </IconButton>
                    )}
                    <IconButton
                        size='small'
                        sx={{
                            position: 'absolute',
                            zIndex: 1,
                            opacity: hover ? 1.0 : 0,
                            left: 0,
                            top: 0,
                            transform: `translate(-25%,-25%)`,
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            saveAs(`${process.env.REACT_APP_API_URL}${item.url}`, item.name);
                        }}
                    >
                        <Download fontSize='small' />
                    </IconButton>
                </>
            )}
            {image ? (
                <Avatar
                    variant='rounded'
                    src={`${process.env.REACT_APP_API_URL}${preview}`}
                    alt={item.alternativeText || item.name}
                    sx={{ opacity: hover ? 0.7 : 0.9, ...sizes[size], ...props.sx }}
                    {...props}
                />
            ) : (
                <Avatar
                    variant='rounded'
                    sx={{ opacity: hover ? 0.7 : 0.9, ...sizes[size], ...props.sx }}
                    {...props}
                >
                    <Typography variant='caption' fontWeight='bold'>
                        {item.ext}
                    </Typography>
                </Avatar>
            )}
        </Box>
    );
};

type UploadItemFileComponent = React.FC<
    {
        size?: 'small' | 'medium' | 'large';
        item: UploadItem;
        onClick: (item: UploadItem) => void;
    } & Omit<AvatarProps, 'onClick'>
>;

export const UploadItemFile: UploadItemFileComponent = ({ item, size = 'small', onClick, sx, ...props }) => {
    const [hover, setHover] = useState(false);
    return (
        <Box
            sx={{ position: 'relative', p: 0.25 }}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(false)}
        >
            {item.status === 'loading' && (
                <Stack
                    sx={{ position: 'absolute', zIndex: 1, p: 1, left: 0, right: 0, top: 0, bottom: 0 }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <CircularProgress
                        sx={{
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                </Stack>
            )}
            {item.status === 'error' && (
                <IconButton
                    sx={{ position: 'absolute', zIndex: 1, p: 1 }}
                    color='error'
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick(item);
                    }}
                >
                    <ErrorOutline />
                </IconButton>
            )}
            <Avatar
                variant='rounded'
                src={item.preview}
                sx={{ opacity: item.status === 'loading' ? 0.5 : hover ? 0.7 : 0.9, ...sizes[size], ...sx }}
                {...props}
            />
        </Box>
    );
};
