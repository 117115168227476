import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';
import { UniqueIdentifier } from '@dnd-kit/core';
import { StrapiMedia } from './upload';
import { Message } from '../slices/chat';
import { api } from './api';
import { result } from 'lodash-es';
import { getSocket } from '../slices';

export const chatApi = api
    .enhanceEndpoints({
        addTagTypes: ['chat', 'message'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            messages: builder.query<
                {
                    data: Record<UniqueIdentifier, Message>;
                    meta: {
                        offset: number;
                        limit: number;
                        total: number;
                    };
                },
                { chat: UniqueIdentifier; offset?: number; limit?: number }
            >({
                query: ({ chat, offset = 0, limit = 20 }) => `/chats/${chat}?offset=${offset}&limit=${limit}`,
                async onCacheEntryAdded(
                    arg,
                    { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }
                ) {
                    //const socket = getSocket();

                    await cacheDataLoaded;
                    // socket?.on('message.create', (message: Message) => {
                    //     if (message.chat === arg.chat) {
                    //         if (arg.offset === 0) {
                    //             updateCachedData((draft) => {
                    //                 draft.data = { ...draft.data, [message.id]: message };
                    //                 draft.meta.total += 1;
                    //             });
                    //         }
                    //     }
                    // });

                    // socket?.on('message.update', (message: Message) => {
                    //     if (message.chat === arg.chat) {
                    //         updateCachedData((draft) => {
                    //             if (draft.data.hasOwnProperty(message.id)) {
                    //                 draft.data[message.id] = message;
                    //             }
                    //         });
                    //     }
                    // });

                    // socket?.on('message.delete', (message: Message) => {
                    //     if (message.chat === arg.chat) {
                    //         updateCachedData((draft) => {
                    //             if (draft.data.hasOwnProperty(message.id)) {
                    //                 delete draft.data[message.id];
                    //                 draft.meta.total -= 1;
                    //             }
                    //         });
                    //     }
                    // });

                    await cacheEntryRemoved;
                    // socket?.off('message.create');
                    // socket?.off('message.update');
                    // socket?.off('message.delete');
                },
            }),
            getMessagesCount: builder.query<number, UniqueIdentifier>({
                query: (id) => `/chats/${id}/count`,
            }),
            getMessage: builder.query<Message, UniqueIdentifier>({
                query: (id) => ({ url: `/messages/${id}` }),
            }),

            addMessage: builder.mutation<Message, { chat: UniqueIdentifier; data: Partial<Message> }>({
                query: ({ chat, data }) => ({
                    url: `/chats/${chat}`,
                    method: 'POST',
                    body: {
                        data,
                    },
                }),
            }),
            editMessage: builder.mutation<Message, { id: UniqueIdentifier; data: Partial<Message> }>({
                query: ({ id, data }) => ({
                    url: `/messages/${id}`,
                    method: 'PUT',
                    body: {
                        data,
                    },
                }),
            }),
            deleteMessage: builder.mutation<Message, UniqueIdentifier>({
                query: (id) => ({
                    url: `/messages/${id}`,
                    method: 'DELETE',
                }),
            }),
        }),
    });

export const {
    useMessagesQuery,
    useGetMessagesCountQuery,
    useGetMessageQuery,
    useAddMessageMutation,
    useEditMessageMutation,
    useDeleteMessageMutation,
} = chatApi;
