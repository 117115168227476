import { Add } from '@mui/icons-material';
import { Button, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useAddSectionMutation } from '../../../../store/api/section';
import { UniqueIdentifier } from '@dnd-kit/core';
import { SectionWrapper } from '../section/SectionWrapper';

type AddSectionComponent = React.FC<{
    index: number;
    project: UniqueIdentifier;
}>;

const validationSchema = Yup.object().shape({
    title: Yup.string().max(120, 'Title is too long'),
});

export const AddSection: AddSectionComponent = ({ index, project }) => {
    const [addSection, { isLoading, isError, error }] = useAddSectionMutation();

    const [open, setOpen] = useState(false);
    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            title: 'Новый раздел',
            index: index,
            project: project,
        },
        validationSchema,
        onSubmit: async (values) => {
            await addSection(values);
            handleCancel();
        },
    });

    const handleOpen = () => setOpen(true);
    const handleCancel = () => {
        setOpen(false);
        resetForm();
    };

    useEffect(() => {
        resetForm({
            values: {
                title: 'Новый раздел',
                index: index,
                project: project,
            },
        });
        setOpen(false);
    }, [index, project]);

    return (
        <SectionWrapper type='new-section' disabled={open}>
            {open ? (
                <Stack spacing={1}>
                    <TextField
                        size='small'
                        label='Новый раздел'
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='title'
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                    />
                    <Stack direction='row' justifyContent='end' spacing={1}>
                        <Button color='secondary' onClick={handleCancel}>
                            Cancel
                        </Button>
                        <LoadingButton variant='contained' onClick={() => handleSubmit()} loading={isLoading}>
                            Ok
                        </LoadingButton>
                    </Stack>
                </Stack>
            ) : (
                <Button color='secondary' startIcon={<Add />} onClick={handleOpen}>
                    Add section
                </Button>
            )}
        </SectionWrapper>
    );
};
