import { Logout, Person, Settings, Visibility, VisibilityOff } from '@mui/icons-material';
import {
    AppBar,
    Toolbar,
    Stack,
    Typography,
    IconButton,
    Avatar,
    Menu,
    MenuItem,
    Divider,
    Badge,
    Button,
} from '@mui/material';
import { } from '@mui/system';
import React, { useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { UserAvatar } from '../../../components/UserAvatar';
import { TextLink } from '../../../components/TextLink';
import { useTypedDispatch, useTypedSelector } from '../../../store/hooks';
import { logout, faultyToken } from '../../../store/slices/user';
import { Collaborator } from '../../../store/slices/collaborators';
import { Notifications } from '../notifications';

interface IHeader { }

export const Header = React.forwardRef<HTMLHeadingElement, IHeader>((props, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const user = useTypedSelector((state) => state.user);
    const dispatch = useTypedDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpen = (e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleLogout = () => {
        dispatch(logout());
        setSearchParams();
    };

    const handleProfileClick = () => navigate({ pathname: '/dashboard/user/profile', search: searchParams.toString() }, { state: { location } });
    const handleSettingsClick = () => navigate({ pathname: '/dashboard/user/settings', search: searchParams.toString() }, { state: { location } });
    const handleNotificationsClick = () => navigate({ pathname: '/dashboard/user/notifications', search: searchParams.toString() }, { state: { location } });
    const handleInvitesClick = () => navigate({ pathname: '/dashboard/user/invites', search: searchParams.toString() }, { state: { location } });

    const visibilityChecked = searchParams.get('visibility');
    const handleVisibilityChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (visibilityChecked) searchParams.delete('visibility');
        else searchParams.append('visibility', 'true');

        setSearchParams(searchParams);
    };

    return (
        <>
            <AppBar ref={ref} position='static' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Stack direction='row' justifyContent='space-between' flexGrow={1}>
                        <Stack direction='row' alignItems='center'>
                            <TextLink variant='h5' href='/dashboard'>
                                SISTEMA
                            </TextLink>
                        </Stack>
                        {/* <Button onClick={() => dispatch(faultyToken())}>Faluty token</Button> */}
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <IconButton
                                onClick={handleVisibilityChange}
                            >
                                {visibilityChecked ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            <Notifications />
                            <IconButton onClick={handleOpen}>
                                <UserAvatar user={user} />
                                {/* <Avatar /> */}
                            </IconButton>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                id='account-menu'
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                <MenuItem sx={{ display: 'felex', flexDirection: 'row' }} onClick={handleProfileClick}>
                    <UserAvatar user={user} />
                    <Stack>
                        <Typography>@{user.username}</Typography>
                        <Typography>{user.email}</Typography>
                    </Stack>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleSettingsClick}>
                    <Avatar>
                        <Settings />
                    </Avatar>
                    Настройки
                </MenuItem>
                <MenuItem onClick={handleNotificationsClick}>
                    <Avatar>
                        <Person />
                    </Avatar>
                    Уведомления
                </MenuItem>
                <MenuItem onClick={handleInvitesClick}>
                    <Avatar>
                        <Person />
                    </Avatar>
                    Приглашения
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                    <Avatar>
                        <Logout />
                    </Avatar>
                    Log out
                </MenuItem>
            </Menu>
        </>
    );
});
