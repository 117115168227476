import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { HelmetProvider } from 'react-helmet-async';
import { Loading } from './components/Loading';
import { Theme } from './components/theme/Theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const persistor = persistStore(store);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={<Loading />} persistor={persistor}>
                <HelmetProvider>
                    {/* <ThemeProvider theme={theme}> */}
                    <Theme>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
                            <BrowserRouter>
                                <CssBaseline />
                                <SnackbarProvider
                                    maxSnack={3}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                >
                                    <App />
                                </SnackbarProvider>
                            </BrowserRouter>
                        </LocalizationProvider>
                        {/* </ThemeProvider> */}
                    </Theme>
                </HelmetProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
