import { UniqueIdentifier } from '@dnd-kit/core';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';
import { providesKeys, providesList } from '../utills';

import { projectApi } from './project';

export type ApiOrder = {
    id: UniqueIdentifier;
    index: number;
    collapsed: boolean;
};

export const orderApi = projectApi.injectEndpoints({
    endpoints: (builder) => ({
        order: builder.mutation<void, ApiOrder[]>({
            query: (data) => ({
                url: '/orders',
                method: 'PATCH',
                body: {
                    data,
                },
            }),
            invalidatesTags: ['project'],
        }),
    }),
});

export const { useOrderMutation } = orderApi;
