import { ThemeProvider } from '@mui/material';
import React, { useMemo } from 'react';
import { useTypedSelector } from '../../store/hooks';
import { getThemeSelector } from '../../store/slices/settings';
import { lightTheme } from '../../theme/light';
import { darkTheme } from '../../theme/dark';

type ThemeComponent = React.FC<{
    children: React.ReactNode;
}>;

export const Theme: ThemeComponent = ({ children }) => {
    const themeKey = useTypedSelector((state) => getThemeSelector(state));

    const theme = useMemo(() => (themeKey === 'dark' ? darkTheme : lightTheme), [themeKey]);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
