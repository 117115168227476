import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface ILogo {}

export const Logo: React.FC<ILogo> = ({}) => {
    return (
        <Typography variant='button' fontSize={24}>
            <Link to='/'>SISTEMA</Link>
        </Typography>
    );
};
