import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from '..';

export type Pagination = {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).user.jwt;
            if (token) headers.set('authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({}),
});

export type ValidationError = {
    status: 400;
    data: {
        error: {
            status: 400;
            name: string;
            message: string;
            details?: any;
        };
    };
};
