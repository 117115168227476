import { UniqueIdentifier } from '@dnd-kit/core';
import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { Collaborator } from '.';
import type { RootState } from '../.';
import { authApi } from '../api/auth';
import { userApi } from '../api/user';

export type Avatar = {
    id: UniqueIdentifier;
    url: string;
    small?: string;
    medium?: string;
    large?: string;
    thumbnail?: string;
};

type UserState =
    | {
          id?: UniqueIdentifier;
          username?: string;
          email?: string;
          firstname?: string;
          lastname?: string;
          jwt: string;
          avatar?: Avatar | null;
      }
    | undefined
    | null;

// TO DO
// REDO AUTH LOGIC
// Protected route > fetch me > if user

const slice = createSlice({
    name: 'user',
    initialState: {} as UserState,
    reducers: {
        logout: (state) => {
            return null;
        },
        faultyToken: (state) => {
            if (state !== null && state !== undefined) state.jwt = '123';
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                authApi.endpoints.login.matchFulfilled,
                authApi.endpoints.register.matchFulfilled,
                authApi.endpoints.changePassword.matchFulfilled,
                authApi.endpoints.resetPassword.matchFulfilled
            ),
            (state, { payload }) => {
                const { jwt, user } = payload;
                return {
                    ...user,
                    jwt,
                };
            }
        );
        builder.addMatcher(
            isAnyOf(userApi.endpoints.getUserProfile.matchFulfilled, userApi.endpoints.me.matchFulfilled),
            (state, { payload }) => {
                if (state) {
                    return {
                        ...state,
                        ...payload,
                        avatar: payload.avatar
                            ? {
                                  id: payload.avatar.id,
                                  url: payload.avatar.url,
                                  small: payload.avatar.formats?.small?.url || undefined,
                                  medium: payload.avatar.formats?.medium?.url || undefined,
                                  large: payload.avatar.formats?.large?.url || undefined,
                                  thumbnail: payload.avatar.formats?.thumbnail?.url || undefined,
                              }
                            : null,
                    };
                }
            }
        );
    },
});

export const { logout, faultyToken } = slice.actions;

export default slice.reducer;

export const userSelector = createSelector(
    (state: RootState) => state.user,
    ({ id, username, email, firstname, lastname, avatar }) =>
        ({ id, username, email, firstname, lastname, avatar } as Collaborator)
);
