import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';
import { api } from './api';
import { UniqueIdentifier } from '@dnd-kit/core';
import { INotification } from '../slices/notifications';
import { providesKeys } from '../utills';

export const notificationApi = api
    .enhanceEndpoints({
        addTagTypes: ['notification'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            notifications: builder.query<
                {
                    data: Record<UniqueIdentifier, INotification>;
                    meta: {
                        total: number;
                        unread: number;
                        offset: number;
                        limit: number;
                    };
                },
                { offset?: number; limit?: number }
            >({
                query: ({ offset = 0, limit = 20 }) => `/notifications?offset=${offset}&limit=${limit}`,
                providesTags: (results) => [...providesKeys(Object.keys(results || {}), 'notification')],
            }),
            updateNotification: builder.mutation<INotification, Partial<INotification>>({
                query: ({ id, ...data }) => ({
                    url: `/notifications/${id}`,
                    method: 'PUT',
                    body: {
                        data: { ...data },
                    },
                }),
                invalidatesTags: ['notification'],
            }),
            readAllNotifications: builder.mutation<Record<UniqueIdentifier, INotification>, void>({
                query: () => ({
                    url: `/notifications`,
                    method: 'PUT',
                }),
                invalidatesTags: ['notification'],
            }),
        }),
    });

export const { useNotificationsQuery, useUpdateNotificationMutation, useReadAllNotificationsMutation } =
    notificationApi;
