import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';
import { api } from './api';
import { Avatar } from '../slices/user';
import { StrapiMedia } from './upload';

export type User = {
    id: number | string;
    username: string;
    email: string;
    firstname: string;
    lastname: string;
    avatar: Avatar | null;
};

export type LoginResponse = {
    user: User;
    jwt: string;
};

export type LoginRequest = {
    identifier: string;
    password: string;
};

export type RegisterRequest = {
    username: string;
    email: string;
    password: string;
};

export type UserResponse = Omit<User, 'avatar'> & {
    avatar?: StrapiMedia;
};

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (credentials) => ({ url: `/auth/local`, method: 'POST', body: credentials }),
        }),
        register: builder.mutation<LoginResponse, RegisterRequest>({
            query: (user) => ({ url: `/auth/local/register`, method: 'POST', body: user }),
        }),
        me: builder.query<UserResponse, string | null | undefined>({
            query: () => '/users/me?populate=*',
        }),
        changePassword: builder.mutation<
            LoginResponse,
            { currentPassword: string; password: string; passwordConfirmation: string }
        >({
            query: (body) => ({
                url: `/auth/change-password`,
                method: 'POST',
                body,
            }),
        }),
        resetPassword: builder.mutation<
            LoginResponse,
            { code: string; password: string; passwordConfirmation: string }
        >({
            query: (body) => ({
                url: `/auth/reset-password`,
                method: 'POST',
                body,
            }),
        }),
        forgotPassword: builder.mutation<any, { email: string }>({
            query: ({ email }) => ({
                url: `/auth/forgot-password`,
                method: 'POST',
                body: { email },
            }),
        }),
    }),
});

export const {
    useRegisterMutation,
    useLoginMutation,
    useMeQuery,
    useChangePasswordMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
} = authApi;
