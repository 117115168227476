import { UniqueIdentifier } from '@dnd-kit/core';
import { createSelector, createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../.';

import { projectApi } from '../api/project';
import { getSocket, connect, disconnect } from './socket';

export type Project = {
    id: UniqueIdentifier;
    title: string;
    user: UniqueIdentifier;
    users: UniqueIdentifier[];
    color: 'disabled' | 'action' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning';
    display: string;
};

const slice = createSlice({
    name: 'projects',
    initialState: {} as Record<UniqueIdentifier, Project>,
    reducers: {
        create: (state, { payload }: PayloadAction<Project>) => ({ ...state, [payload.id]: payload }),
        update: (state, { payload }: PayloadAction<Project>) => {
            state[payload.id] = { ...state[payload.id], ...payload };
        },
        delete: (state, { payload }: PayloadAction<Partial<Project> & { id: UniqueIdentifier }>) => {
            delete state[payload.id];
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(projectApi.endpoints.getProjects.matchFulfilled, (state, { payload }) => {
            return payload;
        });
    },
});

export const {} = slice.actions;

export default slice.reducer;

export const projectSelector = createSelector(
    (state: RootState) => state.projects,
    (state: RootState, projectId: UniqueIdentifier | undefined | null) => (projectId ? projectId : null),
    (projects, projectId) => (projectId ? projects[projectId] : null)
);

export const projectsMiddleware: Middleware = (store) => (next) => (action) => {
    const socket = getSocket();
    if (connect.match(action)) {
        // HMM?
        socket?.on('project.create', (project: Project) => {
            const user = (store.getState() as RootState).user;
            if (project.user === user.id || project.users.includes(user.id!)) {
                store.dispatch(slice.actions.create(project));
            }
        });
        socket?.on('project.update', (project: Project) => {
            const user = (store.getState() as RootState).user;
            if (project.user === user.id || project.users.includes(user.id!)) {
                store.dispatch(slice.actions.update(project));
            }
        });
        //HMM2?
        socket?.on('project.delete', (project: Project) => {
            const user = (store.getState() as RootState).user;
            if (project.user === user.id || project.users.includes(user.id!)) {
                store.dispatch(slice.actions.delete(project));
            }
        });

        socket?.on(
            'collaboration.delete',
            ({ project, collaborator }: { project: UniqueIdentifier; collaborator: UniqueIdentifier }) => {
                const user = (store.getState() as RootState).user;
                if (user.id === collaborator) {
                    store.dispatch(slice.actions.delete({ id: project }));
                }
            }
        );
    }
    if (disconnect.match(action)) {
        socket?.off('project.create');
        socket?.off('project.update');
        socket?.off('project.delete');
        socket?.off('collaboration.delete');
    }

    return next(action);
};
