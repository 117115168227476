import { UniqueIdentifier } from '@dnd-kit/core';
import {
    CircularProgress,
    ClickAwayListener,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Section as SectionType } from '../../../../store/slices/sections';
import { SectionActions } from '../actions/SectionActions';
import { SectionWrapper } from './SectionWrapper';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Apple, Check, Edit, Replay, Undo } from '@mui/icons-material';
import { useEditSectionMutation } from '../../../../store/api/section';
import { isEmpty } from 'lodash-es';

type SectionComponent = React.FC<{
    section: SectionType;
    project: UniqueIdentifier;
    children: React.ReactNode;
}>;

const validationSchema = Yup.object().shape({
    id: Yup.number().required('Нет id объекта'),
    title: Yup.string().max(120, 'Название слишком длинное'),
});

export const Section: SectionComponent = ({ section, project, children }) => {
    const [editSection, { isLoading, isError, error }] = useEditSectionMutation();

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            id: section.id,
            title: section.title,
        },
        validationSchema,
        onSubmit: async (data) => {
            if (data.title !== section.title) {
                await editSection({ id: section.id, data });
            }
            handleClose();
        },

        enableReinitialize: true,
    });

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <SectionWrapper id={section.id}>
            <Stack spacing={1}>
                <Stack direction='row' alignItems='center' data-no-dnd={open ? 'true' : 'false'}>
                    {open ? (
                        <ClickAwayListener onClickAway={() => handleSubmit()}>
                            <TextField
                                size='small'
                                name='title'
                                fullWidth
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={handleEnter}
                                error={touched.title && Boolean(errors.title)}
                                helperText={touched.title && errors.title}
                                InputProps={{
                                    sx: { fontSize: (theme) => theme.typography.h5 },
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {isLoading ? (
                                                <CircularProgress />
                                            ) : (
                                                <Stack direction='row'>
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => handleSubmit()}
                                                        disabled={!isEmpty(errors)}
                                                    >
                                                        <Check fontSize='small' />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </ClickAwayListener>
                    ) : (
                        <Typography
                            variant='h5'
                            sx={{
                                p: 1,
                                flexGrow: 1,
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-all',
                                // hyphens: 'auto',
                                cursor: 'pointer',
                            }}
                            onClick={handleOpen}
                        >
                            {section.title}
                        </Typography>
                    )}
                    {project && <SectionActions project={project} section={section.id} />}
                </Stack>
                <Stack spacing={1}>{children}</Stack>
            </Stack>
        </SectionWrapper>
    );
};
