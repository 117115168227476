import { Autocomplete, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useMemo, useState } from 'react';
import { UserAvatar } from '../../../../../components';
import { User } from '../../../../../store/api';
import { Collaborator, Task } from '../../../../../store/slices';
import { EditTaskTask } from '../EditTaskDialog';

type EditTaskAssignmentsComponent = React.FC<{
    formik: FormikProps<EditTaskTask>;
    collaborators: Collaborator[];
    user: Collaborator;
    task: Task;
}>;

export const EditTaskAssignments: EditTaskAssignmentsComponent = ({ formik, collaborators, user, task }) => {
    const [inputValue, setInputValue] = useState('');
    const handleAssignmentsChange = (e: React.SyntheticEvent<Element, Event>, value: Collaborator[]) => {
        formik.setFieldValue(
            'assignments',
            value.map((collaborator) =>
                formik.values.assignments.some(({ assigned }) => assigned == collaborator.id)
                    ? formik.values.assignments.find(({ assigned }) => assigned == collaborator.id)
                    : {
                          user: user.id,
                          assigned: collaborator.id,
                          task: task.id,
                      }
            )
        );
    };

    const handleAssignmentsInputChange = (e: React.SyntheticEvent<Element, Event>, value: string) => {
        setInputValue(value);
    };

    const values = useMemo(() => {
        let values = [] as Collaborator[];
        for (let assignment of formik.values.assignments) {
            const idx = collaborators.findIndex(({ id }) => id == assignment.assigned);
            if (idx !== -1) values.push(collaborators[idx]);
        }
        return values;
    }, [formik, collaborators]);

    return (
        <Autocomplete
            multiple
            value={values}
            inputValue={inputValue}
            onChange={handleAssignmentsChange}
            onInputChange={handleAssignmentsInputChange}
            options={collaborators}
            autoHighlight
            getOptionLabel={(option) => option.username}
            filterOptions={(options, { inputValue }) =>
                options.filter(
                    ({ username, firstname, lastname, email }) =>
                        username.startsWith(inputValue) ||
                        firstname.startsWith(inputValue) ||
                        lastname.startsWith(inputValue) ||
                        email.startsWith(inputValue)
                )
            }
            renderOption={(props, user) => (
                <MenuItem
                    sx={{
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                    }}
                    {...props}
                >
                    <UserAvatar user={user} />
                    <Stack>
                        <Typography variant='h6'>
                            {user.lastname} {user.firstname}
                        </Typography>
                        <Typography variant='caption'>
                            {user.username} {user.email}
                        </Typography>
                    </Stack>
                </MenuItem>
            )}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label='Выберите пользователя'
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
};
