import { UniqueIdentifier } from '@dnd-kit/core';
import { createSlice, current, Middleware, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../.';
import { FlattenTree } from '../../containers/dashboard/tree/utils';
import { projectApi } from '../api/project';
import { treeApi } from '../api/tree';

import { getSocket, connect, disconnect } from './socket';

export type Leaf = {
    id: UniqueIdentifier;
    user: UniqueIdentifier;
    parent: UniqueIdentifier | null;
    project: UniqueIdentifier;
    index: number;
    collapsed: boolean;
    childs: number;
};

const slice = createSlice({
    name: 'tree',
    initialState: {} as Record<UniqueIdentifier, Leaf>,
    extraReducers: (builder) => {
        builder.addMatcher(treeApi.endpoints.getTree.matchFulfilled, (state, { payload }) => {
            return payload;
        });
        builder.addMatcher(treeApi.endpoints.setTree.matchPending, (state, { meta }) => {
            (meta.arg.originalArgs as FlattenTree[]).forEach((leaf) => {
                state[leaf.id] = leaf;
            });
        });
        builder.addMatcher(projectApi.endpoints.deleteProject.matchPending, (state, { meta }) => {
            const leaf = Object.values(state).find(({ project }) => project === meta.arg.originalArgs);
            if (leaf) {
                const leafs = Object.values(state).filter(({ parent }) => parent === leaf.id);
                leafs.forEach(({ id }) => {
                    state[id].parent = leaf.parent;
                });
            }
        });
    },
    reducers: {
        create: (state, { payload }: PayloadAction<Leaf>) => ({ ...state, [payload.id]: payload }),
        update: (state, { payload }: PayloadAction<Leaf>) => {
            state[payload.id] = { ...state[payload.id], ...payload };
        },
        delete: (state, { payload }: PayloadAction<Leaf>) => {
            delete state[payload.id];
        },
        setTree: (state, { payload }: PayloadAction<FlattenTree[]>) => {
            payload.forEach((leaf) => {
                state[leaf.id] = leaf;
            });
        },
    },
});

export const { setTree } = slice.actions;
export default slice.reducer;

export const treeMiddleware: Middleware = (store) => (next) => (action) => {
    const socket = getSocket();
    if (connect.match(action)) {
        socket?.on('leaf.create', (leaf: Leaf) => {
            const user = (store.getState() as RootState).user;
            if (user.id === leaf.user) {
                store.dispatch(slice.actions.create(leaf));
            }
        });
        socket?.on('leaf.update', (leaf: Leaf) => {
            const user = (store.getState() as RootState).user;
            if (user.id === leaf.user) {
                store.dispatch(slice.actions.update(leaf));
            }
        });
        // NEEDS TESTING
        socket?.on('leaf.delete', (leaf: Leaf) => {
            //console.log('LEAF DELETE');

            const user = (store.getState() as RootState).user;
            if (user.id === leaf.user) {
                store.dispatch(slice.actions.delete(leaf));
            }
        });
    }
    if (disconnect.match(action)) {
        socket?.off('leaf.create');
        socket?.off('leaf.update');
        socket?.off('leaf.delete');
    }

    return next(action);
};
