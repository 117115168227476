import {
    Alert,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    MenuItem,
    Select,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTypedSelector } from '../../../store/hooks';
import { DashboardContext, dashboardContext } from '../dashboardContext';
import { useEditProjectMutation } from '../../../store/api/project';
import { Circle, Dashboard } from '@mui/icons-material';
import { ValidationError } from '../../../store/api';
import { LoadingButton } from '@mui/lab';

interface IEditProject { }

const validationSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Название слишком короткое')
        .max(20, 'Название слишком длинное')
        .required('Проект должен иметь название'),
    color: Yup.string()
        .oneOf(['disabled', 'action', 'success', 'primary', 'secondary', 'error', 'info', 'warning'])
        .required(),
    display: Yup.string().oneOf(['board']).required(),
});

export const EditProject: React.FC<IEditProject> = ({ }) => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const projects = useTypedSelector((state) => state.projects);
    const { colors, displays } = useContext(dashboardContext) as DashboardContext;
    const [editProject, { isLoading, isError, error }] = useEditProjectMutation();

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            id: projects[projectId!].id,
            title: projects[projectId!].title,
            color: projects[projectId!].color,
            display: projects[projectId!].display,
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                await editProject(values);
                navigate({
                    pathname: `/dashboard/projects/${projectId}`,
                    search: searchParams.toString(),
                });
            } catch (e: any) {
                console.log(e.message);
            }
        },
    });
    const handleCancel = () => navigate({
        pathname: `/dashboard/projects/${projectId}`, search: searchParams.toString(),
    });

    return (
        <Dialog open onClose={handleCancel} maxWidth='sm' fullWidth>
            <DialogTitle>Редактировать проект</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack spacing={2} sx={{ py: 2 }}>
                    <TextField
                        label='Название'
                        name='title'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                    />
                    <Select
                        name='color'
                        onChange={handleChange}
                        value={values.color}
                        error={touched.color && Boolean(errors.color)}
                    //helperText={touched.color && errors.color}
                    >
                        {Object.entries(colors).map(([key, value]) => (
                            <MenuItem value={key} key={`color-${key}-${value}`}>
                                <Stack direction='row' alignItems='center'>
                                    <Circle sx={{ color: value, mr: 1 }} />
                                    {key}
                                </Stack>
                            </MenuItem>
                        ))}
                    </Select>
                    <ToggleButtonGroup size='large' exclusive value={values.display} onChange={handleChange}>
                        {Object.entries(displays).map(([key, value]) => (
                            <ToggleButton value={key} key={`diplay-{${key}}`}>
                                <Stack justifyContent='center' alignItems='center' spacing={1}>
                                    <Dashboard />
                                    <Typography variant='caption'>{value}</Typography>
                                </Stack>
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>

                    <Collapse in={isError}>
                        <Alert severity='error' title={(error as ValidationError)?.data.error.name}>
                            {(error as any)?.data.error.message}
                        </Alert>
                    </Collapse>
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleCancel}>Отменить</Button>
                <LoadingButton variant='contained' onClick={() => handleSubmit()} loading={isLoading}>
                    Редактировать
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
