import React, { useState } from 'react';
import * as Yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import { Collaborator, Task } from '../../../../../store/slices';
import { EditTaskTask } from '../EditTaskDialog';
import {
    Button,
    Collapse,
    FormControl,
    IconButton,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { AddCircleOutlineOutlined, Delete, RadioButtonUnchecked } from '@mui/icons-material';
import { CompleteTask } from '../../../components/CompleteTask';
import { EditTaskSubtaskCompleted } from './EditTaskSubtaskCompleted';
import { TextLink } from '../../../../../components';
import { EditTaskSubtaskCollaborators } from './EditTaskSubtaskCollaborators';

type EditTaskSubtasksComponent = React.FC<{
    subtasks: Partial<Task>[];
    formik: FormikProps<EditTaskTask>;
    collaborators: Collaborator[];
}>;

const subtaskValidationSchema = Yup.object().shape({
    index: Yup.number().integer(),
    title: Yup.string().max(800, 'Название слишком длинное').required('Название необходимо'),
    description: Yup.string().max(2400, 'Описание слишком длинное'),
    completed: Yup.boolean(),
});

export const EditTaskSubtasks: EditTaskSubtasksComponent = ({ subtasks, formik }) => {
    const [subtaskIndex, setSubtaskIndex] = useState(subtasks.length);
    const subtaskFormik = useFormik<{
        title: string;
        description: string;
        index: number;
        completed: boolean;
    }>({
        initialValues: {
            title: '',
            description: '',
            index: subtaskIndex,
            completed: false,
        },
        validationSchema: subtaskValidationSchema,
        onSubmit: (values) => {
            formik.setFieldValue('subtasks', [...formik.values.subtasks, values]);
            setSubtaskIndex(formik.values.subtasks.length);
            subtaskFormik.resetForm();
        },
        enableReinitialize: true,
    });

    const [openSubtask, setOpenSubtask] = useState(false);
    const handleOpenSubtask = () => {
        subtaskFormik.resetForm();
        setOpenSubtask(true);
    };
    const handleCloseSubtask = () => setOpenSubtask(false);
    const handleAddSubtask = () => subtaskFormik.handleSubmit();

    const handleDeleteSubtask = (idx: number) => {
        formik.setFieldValue(
            'subtasks',
            formik.values.subtasks.filter((_, i) => i !== idx)
        );
        setSubtaskIndex(formik.values.subtasks.length);
    };

    return (
        <FormControl variant='outlined'>
            <Paper variant='outlined' sx={{ background: 'none' }}>
                <Paper variant='outlined' sx={{ m: 0.5, background: 'none' }}>
                    <Stack sx={{ px: 1, py: 0.5 }}>
                        {subtasks.map((subtask, i) => (
                            <Stack
                                key={`add-subtask-${i}`}
                                direction='row'
                                spacing={1}
                                alignItems='center'
                                sx={{
                                    color: (theme) =>
                                        subtask.completed
                                            ? theme.palette.text.disabled
                                            : theme.palette.text.primary,
                                }}
                            >
                                <EditTaskSubtaskCompleted subtask={subtask} formik={formik} index={i} />
                                {/* <RadioButtonUnchecked sx={{ fontSize: (theme) => theme.typography.body2 }} /> */}
                                {subtask.id ? (
                                    <TextLink
                                        href={`/dashboard/projects/${subtask.project}/tasks/${subtask.id}/edit`}
                                        sx={{ flex: 1 }}
                                    >
                                        {subtask.title}
                                    </TextLink>
                                ) : (
                                    <Typography sx={{ flex: 1 }}>{subtask.title}</Typography>
                                )}
                                <EditTaskSubtaskCollaborators taskId={subtask.id} />
                                <IconButton size='small' onClick={() => handleDeleteSubtask(i)}>
                                    <Delete fontSize='small' />
                                </IconButton>
                            </Stack>
                        ))}
                    </Stack>
                </Paper>
                <Stack justifyContent='center' sx={{ px: 0.05, pt: 0.25 }}>
                    <Collapse in={openSubtask}>
                        <Stack spacing={0.5} sx={{ p: 0.5 }}>
                            <TextField
                                size='small'
                                label='Название'
                                value={subtaskFormik.values.title}
                                onChange={subtaskFormik.handleChange}
                                onBlur={subtaskFormik.handleBlur}
                                name='title'
                                error={subtaskFormik.touched.title && Boolean(subtaskFormik.errors.title)}
                                helperText={subtaskFormik.touched.title && subtaskFormik.errors.title}
                                multiline
                            />
                            <TextField
                                size='small'
                                label='Описание'
                                value={subtaskFormik.values.description}
                                onChange={subtaskFormik.handleChange}
                                onBlur={subtaskFormik.handleBlur}
                                name='description'
                                error={
                                    subtaskFormik.touched.description &&
                                    Boolean(subtaskFormik.errors.description)
                                }
                                helperText={
                                    subtaskFormik.touched.description && subtaskFormik.errors.description
                                }
                                multiline
                                minRows={2}
                            />
                        </Stack>
                    </Collapse>

                    <Stack
                        direction='row'
                        justifyContent={openSubtask ? 'end' : 'center'}
                        spacing={1}
                        sx={{ py: 0.5, px: 0.5 }}
                    >
                        {openSubtask ? (
                            <>
                                <Button size='small' color='secondary' onClick={handleCloseSubtask}>
                                    Отменить
                                </Button>
                                <Button size='small' variant='contained' onClick={handleAddSubtask}>
                                    Добавить
                                </Button>
                            </>
                        ) : (
                            <Button
                                color='secondary'
                                size='small'
                                onClick={handleOpenSubtask}
                                startIcon={<AddCircleOutlineOutlined />}
                                sx={{ flex: 1, alignItems: 'center', lineHeight: 0 }}
                            >
                                Добавить подзадачу
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </Paper>
        </FormControl>
    );
};
