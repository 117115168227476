import type { UniqueIdentifier } from '@dnd-kit/core';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';

import { taskApi } from './task';
import { providesKeys } from '../utills';
import { Section } from '../slices/sections';

export const sectionApi = taskApi
    .enhanceEndpoints({
        addTagTypes: ['section'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getSections: builder.query<Record<UniqueIdentifier, Section>, void>({
                query: () => '/sections',
                providesTags: (results) =>
                    results
                        ? [...providesKeys(Object.keys(results), 'section')]
                        : [{ id: 'LIST', type: 'section' }],
            }),
            addSection: builder.mutation<Section, Partial<Section>>({
                query: (data) => ({
                    url: '/sections',
                    method: 'POST',
                    body: {
                        data,
                    },
                }),
                invalidatesTags: ['section'],
            }),
            editSection: builder.mutation<Section, { id: UniqueIdentifier; data: Partial<Section> }>({
                query: ({ id, data }) => ({
                    url: `/sections/${id}`,
                    method: 'PUT',
                    body: {
                        data,
                    },
                }),
                invalidatesTags: ['section'],
            }),
            deleteSection: builder.mutation<Section, UniqueIdentifier>({
                query: (id) => ({
                    url: `/sections/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['section', 'task'],
            }),
            updateSections: builder.mutation<Record<UniqueIdentifier, Section>, Array<Partial<Section>>>({
                query: (data) => ({
                    url: `/sections`,
                    method: 'PUT',
                    body: {
                        data,
                    },
                }),
                invalidatesTags: ['section'],
            }),
        }),
    });

export const {
    useGetSectionsQuery,
    useAddSectionMutation,
    useEditSectionMutation,
    useDeleteSectionMutation,
    useUpdateSectionsMutation,
} = sectionApi;
