import { RadioButtonUnchecked, TaskAlt } from '@mui/icons-material';
import { Checkbox, CheckboxProps, CircularProgress, Tooltip } from '@mui/material';
import React from 'react';
import { useUpdateTaskMutation } from '../../../store/api/task';
import { Task } from '../../../store/slices/tasks';

type CompleteTaskComponent = React.FC<
    CheckboxProps & {
        task: Task;
    }
>;

export const CompleteTask: CompleteTaskComponent = ({ task, ...rest }) => {
    const [updateTask, { isLoading, isError, error }] = useUpdateTaskMutation();

    const handleTaskCompleted = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        await updateTask({ ...task, completed: !task.completed });
    };
    return (
        <Tooltip title={`Задача ${task.completed ? `выполнена` : `не выполнена`}`}>
            <Checkbox
                onClick={handleTaskCompleted}
                checked={task.completed}
                icon={isLoading ? <CircularProgress size={24} /> : <RadioButtonUnchecked />}
                checkedIcon={isLoading ? <CircularProgress size={24} /> : <TaskAlt />}
                {...rest}
            />
        </Tooltip>
    );
};
