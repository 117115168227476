import React from 'react';
import { UserAvatar } from '../../../../../components';
import { Collaborator } from '../../../../../store/slices';

type EditTaskAuthorComponent = React.FC<{
    author: Partial<Collaborator> | null | undefined;
}>;

export const EditTaskAuthor: EditTaskAuthorComponent = ({ author }) => {
    return <>{author && author.id && <UserAvatar user={author} size={6} tooltip />}</>;
};
