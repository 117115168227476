import { UniqueIdentifier } from '@dnd-kit/core';
import { PriorityHigh } from '@mui/icons-material';
import { CircularProgress, IconButton, Menu, Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { TaskLog, useGetTaskLogQuery } from '../../../../../store/api/tasks-log';
import { Task } from '../../../../../store/slices';
import { useIsMobile } from '../../../dashboardContext';

type EditTaskLogComponent = React.FC<{
    task: Task;
}>;
const limit = 20;
export const EditTaskLog: EditTaskLogComponent = ({ task }) => {
    const isMobile = useIsMobile();
    const [offset, setOffset] = useState(0);

    const { data, isLoading, isError, error } = useGetTaskLogQuery(
        { id: task.id, offset, limit },
        { refetchOnFocus: true, refetchOnMountOrArgChange: true, refetchOnReconnect: true }
    );

    const [items, setItems] = useState<Record<UniqueIdentifier, TaskLog>>({});
    const total = useMemo(() => data?.meta.total || 0, [data]);

    useEffect(() => {
        if (data?.data) {
            setItems((items) => ({ ...items, ...data.data }));
        }
    }, [data]);

    const handleIncrement = () => {
        setOffset(Math.min(offset + limit, total));
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);
    const handleClose = async () => {
        setAnchorEl(null);
    };

    const Row = useMemo(
        () => (index: number, log: TaskLog) => {
            return (
                <Typography variant='caption' sx={{ m: 0 }}>
                    {`[${format(parseISO(log.updatedAt), 'MM.dd.yy HH:mm:ss')}]: ${log.message}`}
                </Typography>
            );
        },
        [data]
    );

    const Footer = useMemo(
        () => () =>
            (
                <Stack justifyContent='center' alignItems='center' sx={{ py: 2 }}>
                    {total !== Object.values(items).length && <CircularProgress />}
                </Stack>
            ),
        [total, items]
    );

    return (
        <>
            <IconButton onClick={handleOpen}>
                <PriorityHigh />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                //transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                //anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                onClose={handleClose}
                transitionDuration={0}
                PaperProps={
                    {
                        //sx: { p: 1 },
                    }
                }
            >
                <Virtuoso
                    data={Object.values(items).reverse()}
                    style={{ width: isMobile ? '100vw' : 512, height: 400 }}
                    itemContent={Row}
                    totalCount={total}
                    initialTopMostItemIndex={0}
                    initialItemCount={Object.values(items).length}
                    atBottomStateChange={handleIncrement}
                    overscan={100}
                    components={{ Footer }}
                />
            </Menu>
        </>
    );
};
