import { UniqueIdentifier } from '@dnd-kit/core';
import React, { useMemo } from 'react';
import { useLocation, useNavigate, useNavigation, useParams, useSearchParams } from 'react-router-dom';
import { ErrorDialog } from '../../../../components/dialogs';
import { Lightbox } from '../../../../components/lightbox/Lightbox';
import { StrapiMedia } from '../../../../store/api';
import { useTypedSelector } from '../../../../store/hooks';
import { taskSelector } from '../../../../store/slices';

type EditTaskLightboxComponent = React.FC<{}>;

export const EditTaskLightbox: EditTaskLightboxComponent = ({ }) => {
    const [searchParams] = useSearchParams();
    const { taskId, mediaId, projectId } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const task = useTypedSelector((state) => taskSelector(state, taskId));

    const media = useMemo(() => (state ? (state as StrapiMedia[]) : task?.media), [task, state]);

    const currentIndex = useMemo(
        () => (mediaId && media ? media.findIndex(({ id }) => id === Number.parseInt(mediaId)) : 0),
        [media, mediaId]
    );
    const current = useMemo(() => (media ? media[currentIndex] : null), [mediaId]);
    const next = useMemo(
        () => (media && media.length > 1 ? media[(currentIndex + 1) % media.length] : null),
        [media, currentIndex]
    );
    const previous = useMemo(
        () => (media && media.length > 1 ? media[(currentIndex + media.length - 1) % media.length] : null),
        [media, currentIndex]
    );

    const handleNext = () =>
        navigate(
            {
                pathname: `/dashboard/projects/${projectId}/tasks/${taskId}/edit/media/${next?.id}`,
                search: searchParams.toString(),
            },
            { state }
        );

    const handlePrevious = () =>
        navigate(
            {
                pathname: `/dashboard/projects/${projectId}/tasks/${taskId}/edit/media/${previous?.id}`,
                search: searchParams.toString(),
            },
            { state }
        );

    const handleClick = (id: UniqueIdentifier) =>
        navigate(
            {
                pathname: `/dashboard/projects/${projectId}/tasks/${taskId}/edit/media/${id}`,
                search: searchParams.toString(),
            },
            { state }
        );

    const handleClose = () =>
        navigate({
            pathname: `/dashboard/projects/${projectId}/tasks/${taskId}/edit`,
            search: searchParams.toString(),
        });

    const handleError = () =>
        projectId && taskId
            ? navigate({
                pathname: `/dashboard/projects/${projectId}/tasks/${taskId}/edit`,
                search: searchParams.toString(),
            })
            : projectId
                ? navigate({ pathname: `/dashboard/projects/${projectId}`, search: searchParams.toString() })
                : navigate({ pathname: `/dashboard/`, search: searchParams.toString() });

    return (
        <>
            {media && current ? (
                <Lightbox
                    media={media}
                    current={current}
                    next={next}
                    previous={previous}
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                    onClick={handleClick}
                    onClose={handleClose}
                    currentIndex={currentIndex}
                />
            ) : (
                <ErrorDialog onConfirm={handleError} />
            )}
        </>
    );
};
