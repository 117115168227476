import { arrayMove } from '@dnd-kit/sortable';

type Project = {
    title: string;
    index: number;
    parent: null | string;
    name: string;
    collapsed: boolean;
};

type ProjectTree = Project & {
    children: ProjectTree[];
};

export const buildTree = (projects: Project[]): ProjectTree[] => {
    let hashTable = Object.create(null);
    projects.forEach((project) => (hashTable[project.name] = { ...project, children: [] }));

    //projects = projects.sort((a, b) => a.index - b.index);

    //console.log(projects);
    let dataTree: ProjectTree[] = [];
    projects.forEach((project) => {
        if (project.parent) hashTable[project.parent].children.push(hashTable[project.name]);
        else dataTree.push(hashTable[project.name]);
    });

    return dataTree;
};

export type ProjectFlatten = ProjectTree & {
    depth: number;
};

function flatten(items: ProjectTree[], parent: string | null = null, depth = 0): ProjectFlatten[] {
    return items.reduce<ProjectFlatten[]>((acc, item, index) => {
        return [...acc, { ...item, parent, depth, index }, ...flatten(item.children, item.name, depth + 1)];
    }, []);
}

export const flattenTree = (tree: ProjectTree[]) => flatten(tree);

export const removeChildrenOf = (items: ProjectFlatten[], ids: string[]): ProjectFlatten[] => {
    const excludeParentIds = [...ids];

    return items.filter((item) => {
        if (item.parent && excludeParentIds.includes(item.parent)) {
            if (item.children.length) {
                excludeParentIds.push(item.name);
            }
            return false;
        }

        return true;
    });
};

export function setProperty<T extends keyof ProjectTree>(
    items: ProjectTree[],
    id: string,
    property: T,
    setter: (value: ProjectTree[T]) => ProjectTree[T]
) {
    for (const item of items) {
        if (item.name === id) {
            item[property] = setter(item[property]);
            continue;
        }

        if (item.children.length) {
            item.children = setProperty(item.children, id, property, setter);
        }
    }

    return [...items];
}

export const removeProject = (items: ProjectTree[], id: string) => {
    return items.filter((item) => {
        if (item.children.length) item.children = removeProject(item.children, id);
        return item.name !== id;
    });
};

function getDragDepth(offset: number, indentationWidth: number) {
    return Math.round(offset / indentationWidth);
}

function getMaxDepth({ previousItem }: { previousItem: ProjectFlatten }) {
    if (previousItem) {
        return previousItem.depth + 1;
    }
    return 0;
}

function getMinDepth({ nextItem }: { nextItem: ProjectFlatten }) {
    if (nextItem) {
        return nextItem.depth;
    }

    return 0;
}

export const getProjection = (
    items: ProjectFlatten[],
    activeId: string,
    overId: string,
    dragOffset: number,
    indentationWidth: number
) => {
    const overItemIndex = items.findIndex(({ name }) => name === overId);
    const activeItemIndex = items.findIndex(({ name }) => name === activeId);

    const activeItem = items[activeItemIndex];
    const newItems = arrayMove(items, activeItemIndex, overItemIndex);

    const previousItem = newItems[overItemIndex - 1];
    const nextItem = newItems[overItemIndex + 1];

    const dragDepth = getDragDepth(dragOffset, indentationWidth);
    const projectedDepth = activeItem.depth + dragDepth;

    const maxDepth = getMaxDepth({
        previousItem,
    });
    const minDepth = getMinDepth({ nextItem });

    let depth = projectedDepth;

    if (projectedDepth >= maxDepth) {
        depth = maxDepth;
    } else if (projectedDepth < minDepth) {
        depth = minDepth;
    }

    function getParentId() {
        if (depth === 0 || !previousItem) {
            return null;
        }

        if (depth === previousItem.depth) {
            return previousItem.parent;
        }

        if (depth > previousItem.depth) {
            return previousItem.name;
        }

        const newParent = newItems
            .slice(0, overItemIndex)
            .reverse()
            .find((item) => item.depth === depth)?.parent;

        return newParent ?? null;
    }

    return { depth, maxDepth, minDepth, parent: getParentId() };
};

const countChildren = (items: ProjectTree[], count = 0): number => {
    return items.reduce((acc, { children }) => {
        if (children.length) return countChildren(children, acc + 1);
        return acc + 1;
    }, count);
};

export const getProjectChildCount = (items: ProjectTree[], id: string) => {
    if (!id) return 0;
    const item = findProject(items, id);

    return item ? countChildren(item.children) : 0;
};

const findProject = (items: ProjectTree[], id: string): ProjectTree | undefined => {
    for (const item of items) {
        const { name, children } = item;
        if (name === id) return item;
        if (children.length > 0) {
            const child = findProject(children, id);
            if (child) return child;
        }
    }

    return undefined;
};
