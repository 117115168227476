import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { StrapiMedia, useUploadMutation } from '../../store/api';
import { UploadItem } from './Upload';

export const useUpload = ({
    media = [],
    onUploaded,
}: {
    media?: StrapiMedia[] | null;
    onUploaded: (media: StrapiMedia[]) => void;
}) => {
    const [upload, { isLoading, isError, error }] = useUploadMutation();
    const [files, setFiles] = useState<UploadItem[]>([]);

    const handleDrop = useCallback(
        (droppedFiles: File[]) => {
            setFiles([
                ...files,
                ...(droppedFiles
                    .filter(
                        (f) =>
                            !(
                                media?.some(({ name }) => f.name === name) ||
                                files?.some(({ file, status }) => f.name === file.name && status !== 'error')
                            )
                    )
                    .map((file) => ({
                        file,
                        preview: URL.createObjectURL(file),
                        status: 'added' as 'added',
                    })) as UploadItem[]),
            ]);
        },
        [files, media]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, isFocused, open } =
        useDropzone({
            onDrop: handleDrop,
        });

    const handleUpload = async () => {
        let indices: number[] = [];
        const itemsToUpload = files.filter(({ status }, i) => {
            if (status === 'added') {
                indices.push(i);
                return true;
            }
            return false;
        });

        if (itemsToUpload.length > 0) {
            try {
                let data = new FormData();
                itemsToUpload.forEach(({ file }) => data.append('files', file));

                setFiles(
                    (files) =>
                        files.map((item, i) =>
                            indices.includes(i) ? { ...item, status: 'loading' as 'loading' } : item
                        ) as UploadItem[]
                );

                let res = await upload(data).unwrap();

                setFiles((files) => files.filter(({ file }) => !res.some(({ name }) => file.name === name)));

                onUploaded(res);
            } catch (e) {
                console.log(e);
                setFiles(
                    (files) =>
                        files.map((item, i) =>
                            indices.includes(i) ? { ...item, status: 'error' as 'error' } : item
                        ) as UploadItem[]
                );
            }
        }
    };

    useLayoutEffect(() => {
        if (!isLoading) {
            handleUpload();
        }
    }, [isLoading, files]);

    return {
        open,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        isFocused,
        media,
        files,
    };
};
