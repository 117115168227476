import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography,
} from '@mui/material';
import React from 'react';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDeleteProjectMutation } from '../../../store/api/project';
import { useTypedSelector } from '../../../store/hooks';
import { projectSelector } from '../../../store/slices/projects';

type DeleteProjectComponent = React.FC<{}>;

export const DeleteProject: DeleteProjectComponent = ({ }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { projectId } = useParams();

    const [deleteProject, { isLoading, isError, error }] = useDeleteProjectMutation();
    const project = useTypedSelector((state) => projectSelector(state, projectId));

    // tasks count
    // messages count
    // files count

    if (!project) return <Navigate to={{ pathname: '/dashboard', search: searchParams.toString() }} />;

    const handleCancel = () => navigate({
        pathname: `/dashboard/projects/${project.id}`,
        search: searchParams.toString(),
    });
    const handleSubmit = async () => {
        if (project) {
            await deleteProject(project.id);
            navigate({
                pathname: `/dashboard/`,
                search: searchParams.toString(),
            });
        }
    };

    return (
        <Dialog open onClose={handleCancel} maxWidth='sm' fullWidth>
            <DialogTitle>Удалить проект</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack spacing={2}>
                    <Typography variant='h5'>{project?.title}</Typography>
                    <Typography>{`Пользователей: ${1 + project.users.length}`}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Отменить</Button>
                <LoadingButton variant='contained' onClick={handleSubmit} loading={isLoading}>
                    Удалить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
