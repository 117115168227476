import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

type ErrorDialogComponent = React.FC<{
    open?: boolean;
    onConfirm: () => void;
    title?: string;
    message?: string;
    confirm?: string;
}>;

export const ErrorDialog: ErrorDialogComponent = ({
    open = true,
    onConfirm,
    title = 'Запрашиваемый объект не найден',
    message = 'Возможно у вас нет доступа к нему',
    confirm = 'Продолжить',
}) => {
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} variant='contained' color='primary'>
                    {confirm}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
