import { UniqueIdentifier } from '@dnd-kit/core';
import { createSelector, createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit';
import { assignmentApi } from '../api/assignment';
import type { RootState } from '../.';
import collaborators, { Collaborator, collaboratorsSelector } from './collaborators';
import { taskApi, UpdateTaskRequest } from '../api/task';
import { getSocket, connect, disconnect } from './socket';

export type Assigment = {
    id: UniqueIdentifier;
    user: UniqueIdentifier;
    assigned: UniqueIdentifier;
    task: UniqueIdentifier;
};

const slice = createSlice({
    name: 'assignments',
    initialState: {} as Record<UniqueIdentifier, Assigment>,
    reducers: {
        create: (state, { payload }: PayloadAction<Assigment>) => ({ ...state, [payload.id]: payload }),
        update: (state, { payload }: PayloadAction<Assigment>) => {
            state[payload.id] = { ...state[payload.id], ...payload };
        },
        delete: (state, { payload }: PayloadAction<Assigment>) => {
            delete state[payload.id];
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            assignmentApi.endpoints.getAssignments.matchFulfilled,
            (state, { payload }) => payload
        );
        // builder.addMatcher(taskApi.endpoints.updateTask.matchPending, (state, { meta }) => {
        //     const { assignments } = meta.arg.originalArgs;
        //     const { id } = meta.arg.originalArgs.data;
        //     if (id) {
        //         const current = Object.values(state).filter(({ task }) => task === id);
        //         current.forEach((cur) => {
        //             if (!assignments?.some(({ id }) => id === cur.id)) {
        //                 delete state[cur.id];
        //             }
        //         });
        //         assignments?.forEach((assignment) => {
        //             if (assignment.id) state[assignment.id] = assignment as Assigment;
        //             else
        //                 state[Date.now().toString()] = {
        //                     id: Date.now(),
        //                     ...assignment,
        //                 } as Assigment;
        //         });
        //     }
        // });
    },
});

export default slice.reducer;

export const assignmentsMiddleware: Middleware = (store) => (next) => (action) => {
    const socket = getSocket();
    if (connect.match(action)) {
        socket?.on('assignment.create', (assignment: Assigment) => {
            const tasks = Object.keys((store.getState() as RootState).tasks);
            if (tasks.includes(assignment.task.toString())) {
                store.dispatch(slice.actions.create(assignment));
            }
        });
        socket?.on('assignment.update', (assignment: Assigment) => {
            const tasks = Object.keys((store.getState() as RootState).tasks);
            if (tasks.includes(assignment.task.toString())) {
                store.dispatch(slice.actions.update(assignment));
            }
        });
        socket?.on('assignment.delete', (assignment: Assigment) => {
            const tasks = Object.keys((store.getState() as RootState).tasks);
            if (tasks.includes(assignment.task.toString())) {
                store.dispatch(slice.actions.delete(assignment));
            }
        });
    }

    if (disconnect.match(action)) {
        socket?.off('task.create');
        socket?.off('task.update');
        socket?.off('task.delete');
    }

    return next(action);
};

export const assignmentsSelector = createSelector(
    (state: RootState) => state.assignments,
    (state: RootState, taskId: UniqueIdentifier | undefined | null) => taskId,
    (assignments, taskId) =>
        taskId ? Object.values(assignments).filter(({ task }) => task == taskId) : ([] as Assigment[])
);
