import React from 'react';
import { Helmet } from 'react-helmet-async';

interface ILayout {
    children: React.ReactNode;
    title?: string;
}

const meta = {
    title: 'SISTEMA',
};

export const Layout: React.FC<ILayout> = ({ children, title }) => {
    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>
                    {meta.title}
                    {title && `| ${title}`}
                </title>
            </Helmet>
            {children}
        </>
    );
};
