import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';
import { api } from '.';
import { UniqueIdentifier } from '@dnd-kit/core';

export type StrapiMedia = {
    id: UniqueIdentifier;
    name: string;
    alternativeText: string | null;
    caption: null;
    width: number | null;
    height: number | null;
    formats?: StrapiImageFormats | null;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string | null;
    provider: string;
    provider_metadata: null | string;
    createdAt: Date;
    updatedAt: Date;
};

type StrapiImageFormats = {
    large: StrapiImageFormat;
    small: StrapiImageFormat;
    medium: StrapiImageFormat;
    thumbnail: StrapiImageFormat;
};

type StrapiImageFormat = {
    ext: string;
    url: string;
    hash: string;
    mime: string;
    name: string;
    path: null;
    size: number;
    width: number;
    height: number;
};

export const uploadApi = api.injectEndpoints({
    endpoints: (builder) => ({
        upload: builder.mutation<StrapiMedia[], FormData>({
            query: (body) => ({ url: '/upload', method: 'POST', body }),
        }),
        delete: builder.mutation<StrapiMedia, UniqueIdentifier>({
            query: (id) => ({ url: `/upload/files/${id}`, method: 'DELETE' }),
        }),
    }),
});

export const { useUploadMutation, useDeleteMutation } = uploadApi;
