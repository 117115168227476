import React, { useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { Draggable } from './components/Draggable';
import { Droppable } from './components/Droppable';
import { Container } from '@mui/material';
import { SortableTree } from './SortableTree/SortableTree';

interface IDndTest {}

export const DndTest: React.FC<IDndTest> = ({}) => {
    return (
        <Container maxWidth='sm'>
            <SortableTree />
        </Container>
    );
};
