import { Add, Circle, Dashboard, ViewColumn } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useContext, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { ValidationError } from '../../../../store/api';
import { useCreateProjectMutation } from '../../../../store/api/project';
import { DashboardContext, dashboardContext } from '../../dashboardContext';

interface IAddProject { }

const validationSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Название слишком короткое')
        .max(20, 'Название слишком длинное')
        .required('Проект должен иметь название'),
    color: Yup.string()
        .oneOf(['disabled', 'action', 'success', 'primary', 'secondary', 'error', 'info', 'warning'])
        .required(),
    display: Yup.string().oneOf(['board', 'columns']).required(),
});

export const CreateProject: React.FC<IAddProject> = () => {
    const { colors } = useContext(dashboardContext) as DashboardContext;
    const [createProject, { isLoading, isError, error }] = useCreateProjectMutation();
    const [open, setOpen] = useState(false);

    const displays = useMemo(
        () => [
            {
                title: 'board',
                value: 'Доска',
            },
            {
                title: 'columns',
                value: 'колонки',
                template: {
                    sections: [
                        {
                            title: 'НУЖНО СДЕЛАТЬ',
                            type: 'todo',
                        },
                        {
                            title: 'В РАБОТЕ',
                            type: 'work',
                        },
                        {
                            title: 'СДЕЛАНО',
                            type: 'done',
                        },
                    ],
                },
            },
        ],
        []
    );

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            title: 'Новый проект',
            color: 'action',
            display: displays[0].title,
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                if (values.display !== 'board') {
                    await createProject({
                        project: values,
                        template: displays[1].template,
                    });
                } else
                    await createProject({
                        project: values,
                    });
                setOpen(false);
            } catch (e: any) {
                console.log(e.message);
            }
        },
    });

    const handleOpen = () => {
        setOpen(true);
        resetForm();
    };
    const handleClose = () => setOpen(false);

    //    const handleSelectChange = (event: SelectChangeEvent<string>) => console.log(event);

    return (
        <>
            <IconButton onClick={handleOpen}>
                <Add />
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
                <DialogTitle>Создать проект</DialogTitle>
                <Divider />
                <DialogContent>
                    <Stack spacing={2} sx={{ py: 2 }}>
                        <TextField
                            label='Название'
                            name='title'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            error={touched.title && Boolean(errors.title)}
                            helperText={touched.title && errors.title}
                        />
                        <Select
                            name='color'
                            onChange={handleChange}
                            value={values.color}
                            error={touched.color && Boolean(errors.color)}
                        //helperText={touched.color && errors.color}
                        >
                            {Object.entries(colors).map(([key, value]) => (
                                <MenuItem value={key} key={`color-${key}-${value}`}>
                                    <Stack direction='row' alignItems='center'>
                                        <Circle sx={{ color: value, mr: 1 }} />
                                        {key}
                                    </Stack>
                                </MenuItem>
                            ))}
                        </Select>
                        <ToggleButtonGroup
                            size='large'
                            exclusive
                            value={values.display}
                            onChange={(e, value) => handleChange({ target: { name: 'display', value } })}
                        >
                            {displays.map(({ title, value }) => (
                                <ToggleButton value={title} key={`diplay-{${title}}`}>
                                    <Stack justifyContent='center' alignItems='center' spacing={1}>
                                        {title === 'board' ? <Dashboard /> : <ViewColumn />}
                                        <Typography variant='caption'>{value}</Typography>
                                    </Stack>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>

                        <Collapse in={isError}>
                            <Alert severity='error' title={(error as ValidationError)?.data.error.name}>
                                {(error as any)?.data.error.message}
                            </Alert>
                        </Collapse>
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button>Отменить</Button>
                    <LoadingButton variant='contained' onClick={() => handleSubmit()} loading={isLoading}>
                        Создать
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
