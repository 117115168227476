import { ArrowBack } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { Task } from '../../../../../store/slices';

type EditTaskGoBackComponent = React.FC<{
    onClick: () => void;
    previousTask: Task | null;
}>;

export const EditTaskGoBack: EditTaskGoBackComponent = ({ previousTask, onClick }) => {
    return (
        <>
            {/* {previousTask && (
                <Tooltip title={`К задачe "${previousTask.title}"`}> */}
            <IconButton onClick={onClick}>
                <ArrowBack />
            </IconButton>
            {/* </Tooltip>
            )} */}
        </>
    );
};
