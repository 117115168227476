import {
    Alert,
    AlertTitle,
    Button,
    ClickAwayListener,
    Collapse,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PageDialog } from '../../components/containers/DialogPage';
import { Layout } from '../../components/containers/Layout';
import { Logo } from '../../components/Logo';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { isEmpty } from 'lodash-es';
import { useResetPasswordMutation } from '../../store/api/auth';
import { useSnackbar } from 'notistack';
import { TextLink } from '../../components/TextLink';
import { ValidationError } from '../../store/api';

type ResetPasswordComponent = React.FC<{}>;

const validationSchema = Yup.object().shape({
    code: Yup.string().required('Отсутсвует код востановления пароля'),
    password: Yup.string()
        .min(8, 'Password is too short')
        .max(24, 'Password is too long')
        .required('Password is required'),
    passwordConfirmation: Yup.string()
        .min(8, 'Password is too short')
        .max(24, 'Password is too long')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Password is required'),
});

export const ResetPassword: ResetPasswordComponent = ({}) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const [resetPassword, { isLoading, isError, isSuccess, error }] = useResetPasswordMutation();

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            code: params.get('code'),
            password: '',
            passwordConfirmation: '',
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            if (values.code)
                try {
                    const res = await resetPassword({ ...values, code: values.code! }).unwrap();
                    navigate('/dashboard');
                } catch (e: any) {
                    console.log(e);
                }
        },
    });

    const handleErrorClick = () => navigate(`/login`);

    const handleTrySubmit = () => {
        if (isEmpty(touched) || !isEmpty(errors)) handleSubmit();
    };

    const [show, setShow] = useState({
        password: false,
        passwordConfirmation: false,
    });

    const handleShow = (key: 'password' | 'passwordConfirmation') =>
        setShow((state) => ({ ...state, [key]: !state[key] }));
    const handleHide = (key: 'password' | 'passwordConfirmation') =>
        setShow((state) => ({ ...state, [key]: false }));

    return (
        <Layout title={'Смена пароля'}>
            <PageDialog>
                <Stack spacing={4}>
                    <Logo />
                    <Stack spacing={2}>
                        <Typography variant='h5'>Смена пароля</Typography>
                        <ClickAwayListener onClickAway={() => handleHide('password')}>
                            <FormControl variant='outlined'>
                                <InputLabel htmlFor='password'>Новый пароль</InputLabel>
                                <OutlinedInput
                                    label='Новый пароль'
                                    id='password'
                                    type={show.password ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='password'
                                    error={touched.password && Boolean(errors.password)}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton onClick={() => handleShow('password')} tabIndex={-1}>
                                                {show.password ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    onSubmit={handleTrySubmit}
                                    disabled={!values.code}
                                />
                                {touched.password && Boolean(errors.password) && (
                                    <FormHelperText error={touched.password && Boolean(errors.password)}>
                                        {touched.password && errors.password}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </ClickAwayListener>
                        <ClickAwayListener onClickAway={() => handleHide('passwordConfirmation')}>
                            <FormControl variant='outlined'>
                                <InputLabel htmlFor='password-сonfirmation'>Повторите пароль</InputLabel>
                                <OutlinedInput
                                    label='Повторите пароль'
                                    id='password-сonfirmation'
                                    type={show.passwordConfirmation ? 'text' : 'password'}
                                    value={values.passwordConfirmation}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='passwordConfirmation'
                                    error={
                                        touched.passwordConfirmation && Boolean(errors.passwordConfirmation)
                                    }
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={() => handleShow('passwordConfirmation')}
                                                tabIndex={-1}
                                            >
                                                {show.passwordConfirmation ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    onSubmit={handleTrySubmit}
                                    disabled={!values.code}
                                />
                                {touched.passwordConfirmation && Boolean(errors.passwordConfirmation) && (
                                    <FormHelperText
                                        error={
                                            touched.passwordConfirmation &&
                                            Boolean(errors.passwordConfirmation)
                                        }
                                    >
                                        {touched.passwordConfirmation && errors.passwordConfirmation}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </ClickAwayListener>
                        <Collapse in={!Boolean(values.code) || Boolean(errors?.code)}>
                            <Alert severity='error' onClick={handleErrorClick}>
                                <AlertTitle>Ошибка</AlertTitle>
                                Не действительный токен сброса пароля
                            </Alert>
                        </Collapse>
                        <Collapse in={isError}>
                            <Alert title={(error as ValidationError)?.data.error.name} severity='error'>
                                {(error as ValidationError)?.data.error.message}
                            </Alert>
                        </Collapse>
                        <LoadingButton
                            variant='contained'
                            size='large'
                            onClick={() => handleSubmit()}
                            disabled={!values.code || !isEmpty(errors)}
                        >
                            Восстановить
                        </LoadingButton>
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <TextLink color='primary' href='/register'>
                            Регистрация
                        </TextLink>
                        <TextLink color='primary' href='/login'>
                            Вход
                        </TextLink>
                    </Stack>
                </Stack>
            </PageDialog>
        </Layout>
    );
};
