import { UniqueIdentifier } from '@dnd-kit/core';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';

import { sectionApi } from './section';

import { providesKeys } from '../utills';
import { Leaf } from '../slices/tree';

type LeafResponse = Record<UniqueIdentifier, Leaf>;

type SetTreeRequest = {};

export const treeApi = sectionApi
    .enhanceEndpoints({
        addTagTypes: ['leaf'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getTree: builder.query<LeafResponse, void>({
                query: () => '/tree',
                providesTags: (results) => [...providesKeys(Object.keys(results || {}), 'leaf')],
            }),
            setTree: builder.mutation<LeafResponse, SetTreeRequest>({
                query: (data) => ({
                    url: '/tree',
                    method: 'PUT',
                    body: {
                        data,
                    },
                }),
                invalidatesTags: ['leaf'],
            }),
        }),
    });

export const { useGetTreeQuery, useSetTreeMutation } = treeApi;
