import { UniqueIdentifier } from '@dnd-kit/core';
import { Avatar, AvatarProps, Box, Button, ButtonBase, Card, CardMedia, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { StrapiMedia } from '../../store/api/upload';
import { thumbnailSizes as sizes } from '../../theme';
import { isImage } from '../../utils';

type StrapiMediaItemComponent = React.FC<
    Omit<AvatarProps, 'onClick'> & {
        item: StrapiMedia;
        size?: 'small' | 'medium' | 'large';
        onClick: (id: UniqueIdentifier) => void;
        selected?: boolean;
    }
>;

export const StrapiMediaItem: StrapiMediaItemComponent = ({
    item,
    size = 'medium',
    onClick,
    selected,
    ...props
}) => {
    const [hover, setHover] = useState(true);
    const image = useMemo(() => isImage(item.mime), [item]);
    const preview = useMemo(
        () =>
            image
                ? item.formats
                    ? item.formats.thumbnail
                        ? item.formats.thumbnail.url
                        : item.formats.small
                        ? item.formats.small.url
                        : item.formats.medium
                        ? item.formats.medium.url
                        : item.formats.large
                        ? item.formats.large.url
                        : item.url
                    : item.url
                : item.ext,
        [image, item]
    );

    const opacity = useMemo(() => {
        if (selected === undefined) return 1;
        else if (selected) return 1;
        else return 0.5;
    }, [selected]);

    return (
        <Box
            sx={{ position: 'relative', p: 0.25, opacity: selected || hover ? 1.0 : 0.7 }}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(false)}
        >
            {image ? (
                <Avatar
                    variant='rounded'
                    src={`${process.env.REACT_APP_API_URL}${preview}`}
                    alt={item.alternativeText || item.name}
                    sx={{ opacity: hover ? 0.7 : 0.9, ...sizes[size], ...props.sx }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick(item.id);
                    }}
                    {...props}
                />
            ) : (
                <Avatar
                    variant='rounded'
                    sx={{ opacity: hover ? 0.7 : 0.9, ...sizes[size], ...props.sx }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick(item.id);
                    }}
                    {...props}
                >
                    <Typography variant='caption' fontWeight='bold'>
                        {item.ext}
                    </Typography>
                </Avatar>
            )}
        </Box>
    );
};
