import { Delete, Edit, MoreHoriz, Replay } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { Message } from '../../../../../store/slices';

type MessageActionsComponent = React.FC<{
    isAuthor: boolean;
    message: Message;
    onReplay: (message: Message) => void;
    onEdit: (message: Message) => void;
    onDelete: (message: Message) => void;
}>;

export const MessageActions: MessageActionsComponent = ({
    isAuthor,
    message,
    onReplay,
    onEdit,
    onDelete,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    display: Boolean(anchorEl) ? 'block' : 'none',
                    right: (theme) => theme.spacing(0.25),
                    top: (theme) => theme.spacing(0.25),
                    zIndex: (theme) => theme.zIndex.drawer,
                }}
            >
                <IconButton size='small' onClick={handleOpen}>
                    <MoreHoriz fontSize='small' />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                sx={{
                    '& .MuiSvgIcon-root': {
                        mr: 0.5,
                    },
                }}
            >
                <MenuItem onClick={() => onReplay(message)}>
                    <Replay fontSize='small' /> Ответить
                </MenuItem>
                {isAuthor && (
                    <MenuItem onClick={() => onEdit(message)}>
                        <Edit fontSize='small' /> Редактировать
                    </MenuItem>
                )}
                {isAuthor && (
                    <MenuItem onClick={() => onDelete(message)}>
                        <Delete fontSize='small' /> Удалить
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};
