import { IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { truncate } from 'lodash-es';
import React from 'react';
import { UserAvatar } from '../../../../../components';
import { UploadItemStrapi } from '../../../../../components/upload';
import { StrapiMedia } from '../../../../../store/api';
import { Collaborator, Message } from '../../../../../store/slices';

type MessageReplyComponent = {
    message: Message;
    collaborators: Collaborator[];
    onMediaClick: (message: Message, item: StrapiMedia) => void;
};

export const MessageReply = React.memo<MessageReplyComponent>(({ message, collaborators, onMediaClick }) => {
    const author = collaborators.find(({ id }) => id === message.user);
    const date = format(parseISO(message.updatedAt), 'MM/dd HH:ss');
    return (
        <Stack sx={{ pl: 0.5, pr: 0, flex: 1 }} direction='row' alignItems='flex-end'>
            {author && (
                <Tooltip title={`@${author.username}`}>
                    <IconButton sx={{ p: 0, m: 0 }}>
                        <UserAvatar user={author} size={4} />
                    </IconButton>
                </Tooltip>
            )}
            <Paper sx={{ px: 0.5, color: (theme) => theme.palette.text.disabled, ml: 1 }}>
                <Stack>
                    <Stack direction='row' flexWrap='wrap' sx={{ py: 0.5 }}>
                        {message.media?.map((item) => (
                            <UploadItemStrapi
                                item={item}
                                key={`media-${item.id}-i`}
                                onClick={(item) => onMediaClick(message, item)}
                                size='small'
                            />
                        ))}
                    </Stack>
                    <Typography variant='body2'>{truncate(message.message, { length: 86 })}</Typography>
                    <Typography
                        sx={{
                            fontSize: 10,
                            color: (theme) => theme.palette.action.disabled,
                        }}
                        variant='caption'
                    >
                        {date}
                    </Typography>
                </Stack>
            </Paper>
        </Stack>
    );
});
