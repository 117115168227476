import { UniqueIdentifier } from '@dnd-kit/core';
import {
    AttachFileOutlined,
    ChatOutlined,
    FormatListBulletedOutlined,
    RadioButtonChecked,
    RadioButtonUnchecked,
    TaskAlt,
} from '@mui/icons-material';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { subtract, truncate } from 'lodash-es';
import React, { useMemo } from 'react';
import { UserAvatar } from '../../../../components/UserAvatar';
import { useTypedSelector } from '../../../../store/hooks';
import {
    getAssignedCollaboratorsSelector,
    collaboratorSelector,
    SectionType,
} from '../../../../store/slices';
import { subtasksSelector, Task as TaskType } from '../../../../store/slices/tasks';
import { CompleteTask } from '../../components/CompleteTask';
import { TaskActions } from '../actions/TaskActions';
import { TaskWrapper } from './TaskWrapper';

type TaskComponent = React.FC<{
    task: TaskType;
    project?: UniqueIdentifier;
    section?: UniqueIdentifier;
    clone?: boolean;
    over?: boolean;
    sectionType?: SectionType;
    visibility?: null | string;
}>;

export const Task: TaskComponent = ({ task, project, section, clone, over, sectionType, visibility }) => {
    const title = useMemo(() => truncate(task.title, { length: 100 }), [task]);
    const description = useMemo(() => truncate(task.description, { length: 160 }), [task]);
    const subtasks = useTypedSelector((state) => subtasksSelector(state, task.id));
    const assignees = useTypedSelector((state) => getAssignedCollaboratorsSelector(state, task.id));
    const author = useTypedSelector((state) => collaboratorSelector(state, task.user));
    const userId = useTypedSelector((state) => state.user.id);

    return (
        <TaskWrapper
            id={task.id}
            section={section}
            project={project}
            clone={clone}
            over={over}
            assigned={assignees?.some((assignee) => assignee?.id === userId)}
            completed={task.completed}
            color={task.color}
            visibility={visibility}
        >
            <Stack spacing={1} direction='row' alignItems='flex-start'>
                <Stack
                    spacing={1}
                    sx={{
                        flexGrow: 1,
                        color: (theme) =>
                            task.completed ? theme.palette.text.disabled : theme.palette.text.primary,
                    }}
                >
                    <Stack direction='row' alignItems='center' spacing={0.5} >
                        {author && <UserAvatar user={author} size={3} tooltip />}
                        <Typography
                            sx={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                wordWrap: 'break-word',
                                hyphens: 'auto',
                                flexWrap: 'wrap'
                            }}
                        >
                            {title}
                        </Typography>
                    </Stack>

                    {Boolean(task.description) && (
                        <Typography
                            variant='body2'
                            sx={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                wordWrap: 'break-word',
                                hyphens: 'auto',
                                flexWrap: 'wrap'
                            }}
                        >
                            {description}
                        </Typography>
                    )}
                    {Boolean(subtasks) && (
                        <Stack>
                            {subtasks?.map((subtask) => (
                                <Stack
                                    key={`subtask-${subtask.id}`}
                                    direction='row'
                                    alignItems='center'
                                    spacing={1}
                                    sx={{ fontSize: (theme) => theme.typography.caption }}
                                >
                                    {subtask.completed ? (
                                        <RadioButtonChecked fontSize='inherit' />
                                    ) : (
                                        <RadioButtonUnchecked fontSize='inherit' />
                                    )}
                                    <Typography variant='caption'>
                                        {truncate(subtask.title, { length: 32 })}
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                    )}

                    <Stack spacing={1} sx={{ color: (theme) => theme.palette.text.secondary }}>
                        <Stack direction='row' spacing={0.5} color='disabled' alignItems='center'>
                            <Stack direction='row' spacing={0.5} color='disabled' alignItems='center'>
                                <ChatOutlined fontSize='inherit' />
                                <Typography variant='caption'>{task.messages}</Typography>
                            </Stack>
                            <Stack direction='row' spacing={0.5} color='disabled' alignItems='center'>
                                <AttachFileOutlined fontSize='inherit' />
                                <Typography variant='caption'>{task.media?.length || 0}</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction='row' spacing={0.5} color='disabled' alignItems='center'>
                            <FormatListBulletedOutlined fontSize='inherit' />
                            <Typography variant='caption'>
                                {task.tasks} / {task.tasksCompleted}
                            </Typography>
                        </Stack>
                        <Stack direction='row' flexWrap='wrap'>
                            {assignees?.map((assignee) => (
                                <Box sx={{ p: 0.25 }} key={`task-${task.id}-${assignee?.username}`}>
                                    <Chip
                                        avatar={<UserAvatar user={assignee!} size={3} />}
                                        label={`@${assignee?.username}`}
                                        variant='outlined'
                                        size='small'
                                    />
                                </Box>
                            ))}
                        </Stack>
                    </Stack>
                </Stack>

                <Stack spacing={1}>
                    {task && (
                        <>
                            <TaskActions task={task} />
                            {(sectionType === 'done' || sectionType === 'none') && (
                                <CompleteTask task={task} />
                            )}
                        </>
                    )}
                </Stack>
            </Stack>
        </TaskWrapper>
    );
};
