import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDeleteTaskMutation } from '../../../store/api/task';
import { useTypedSelector } from '../../../store/hooks';
import { taskSelector } from '../../../store/slices';

type DeleteTaskComponent = React.FC<{}>;

export const DeleteTask: DeleteTaskComponent = ({ }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { projectId, sectionId, taskId } = useParams();

    const [deleteTask, { isLoading, isError, error }] = useDeleteTaskMutation();

    const task = useTypedSelector((state) => taskSelector(state, taskId));

    //const tasks = useTypedSelector((state) => state.tasks);
    //const task = useMemo(() => (taskId ? tasks[taskId] : null), [tasks, taskId]);

    const handleCancel = () => navigate({
        pathname: `/dashboard/projects/${projectId}`,
        search: searchParams.toString(),
    });

    const handleSubmit = async () => {
        if (task) {
            await deleteTask(task.id);
            handleCancel();
        }
    };

    return (
        <Dialog open onClose={handleCancel} maxWidth='sm' fullWidth>
            <DialogTitle>Удалить задачу</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack spacing={2}>
                    <Typography variant='h5'>{task?.title}</Typography>
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleCancel}>Отменить</Button>
                <LoadingButton variant='contained' onClick={handleSubmit} loading={isLoading}>
                    Удалить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
