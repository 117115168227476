import { Box, Button, CircularProgress, Collapse, Drawer, Fab, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useMeasure from 'react-use-measure';

import { Header } from './header/Header';

import { useGetProjectsQuery } from '../../store/api/project';
import { useGetSectionsQuery } from '../../store/api/section';
import { useGetTasksQuery } from '../../store/api/task';
import { DashboardProvider } from './dashboardContext';

import { SEO } from '../../components/SEO';
import { SocketContext } from '../../components/socket/SocketContext';
import { useGetAssignmentsQuery } from '../../store/api/assignment';
import { useGetCollaboratorsQuery } from '../../store/api/collaboration';
import { useGetUserSettingsQuery } from '../../store/api/settings';
import { useGetTreeQuery } from '../../store/api/tree';
import { useGetUserProfileQuery } from '../../store/api/user';
import { useTypedDispatch, useTypedSelector } from '../../store/hooks';
import { Tree } from './tree/Tree';
import { ExpandLess } from '@mui/icons-material';
import { TreeDrawer } from './treeDrawer';

interface IDashboard {
    children?: React.ReactNode;
}

export const DashboardLayout: React.FC<IDashboard> = ({ children }) => {
    const dispatch = useTypedDispatch();
    const user = useTypedSelector((state) => state.user);
    const {
        data: settings,
        isLoading: isSettingsLoading,
        isError,
        error,
    } = useGetUserSettingsQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
    });
    const { data: profile, isLoading: isProfileLoading } = useGetUserProfileQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
    });
    const { data: assignments, isLoading: isAssignmentsLoading } = useGetAssignmentsQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
    });
    const { data: projects, isLoading: isProjectsLoading } = useGetProjectsQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
    });
    const { data: tree, isLoading: isTreeLoading } = useGetTreeQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
    });
    const { data: collaborators, isLoading: isCollaboratorsLoading } = useGetCollaboratorsQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
    });
    const { data: sections, isLoading: isSectionsLoading } = useGetSectionsQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
    });
    const { data: tasks, isLoading: isTasksLoading } = useGetTasksQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
    });

    const [headerRef, { height: headerHeight }] = useMeasure();
    const [projectsRef, { width: treeWidth }] = useMeasure();

    const [open, setOpen] = useState(true);
    const handleOpen = () => setOpen(!open);

    if (isError) {
        console.log(error);
        if (error) {
            const status = (error as any).status;
            if (status === 401) return <Navigate to='/login' />;
        }
    }

    if (
        isSettingsLoading ||
        isProfileLoading ||
        isProjectsLoading ||
        isTreeLoading ||
        isCollaboratorsLoading ||
        isSectionsLoading ||
        isTasksLoading ||
        isAssignmentsLoading
    )
        return (
            <Stack sx={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Stack>
        );

    return (
        <SocketContext>
            <DashboardProvider>
                <SEO title='Welcome' />
                <Header ref={headerRef} />

                <TreeDrawer top={headerHeight} ref={projectsRef} onOpen={handleOpen} open={open}>
                    <Tree />
                </TreeDrawer>
                <Fab
                    sx={{
                        position: 'absolute',
                        left: open ? treeWidth : 0,
                        top: '50%',
                        transform: `translate(-50%,-50%)`,
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        transition: (theme) =>
                            theme.transitions.create(['left'], {
                                easing: open
                                    ? theme.transitions.easing.easeOut
                                    : theme.transitions.easing.sharp,
                                duration: open
                                    ? theme.transitions.duration.enteringScreen
                                    : theme.transitions.duration.leavingScreen,
                            }),
                    }}
                    size='small'
                    color='primary'
                    onClick={handleOpen}
                >
                    <ExpandLess
                        sx={{
                            transform: open ? 'rotate(-90deg)' : 'rotate(90deg)',
                            transition: (theme) =>
                                theme.transitions.create(['transform'], {
                                    easing: open
                                        ? theme.transitions.easing.easeOut
                                        : theme.transitions.easing.sharp,
                                    duration: open
                                        ? theme.transitions.duration.enteringScreen
                                        : theme.transitions.duration.leavingScreen,
                                }),
                        }}
                    />
                </Fab>
                <Stack direction='row' sx={{ height: `calc(100vh - ${headerHeight}px)` }}>
                    <Box
                        sx={{
                            marginLeft: open ? treeWidth / 8 : 0,
                            transition: (theme) =>
                                theme.transitions.create(['marginLeft'], {
                                    easing: open
                                        ? theme.transitions.easing.easeOut
                                        : theme.transitions.easing.sharp,
                                    duration: open
                                        ? theme.transitions.duration.enteringScreen
                                        : theme.transitions.duration.leavingScreen,
                                }),
                        }}
                    />
                    <Box sx={{ flex: 1, overflow: 'auto' }}>
                        <Outlet />
                    </Box>
                </Stack>
            </DashboardProvider>
        </SocketContext>
    );
};
