import { UniqueIdentifier } from '@dnd-kit/core';
import {
    Box,
    CircularProgress,
    Collapse,
    FormControl,
    IconButton,
    InputBase,
    Paper,
    Stack,
} from '@mui/material';

import { Close, Edit, Reply } from '@mui/icons-material';
import { useFluentEdit } from '@react-fluent-edit/core';
import { useFormik } from 'formik';
import { isEmpty, trim } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import * as Yup from 'yup';
import { UploadItemFile, UploadItemStrapi, useUpload } from '../../../../components/upload';
import {
    StrapiMedia,
    useAddMessageMutation,
    useDeleteMessageMutation,
    useEditMessageMutation,
    useMessagesQuery,
} from '../../../../store/api';
import { Collaborator, getSocket, Message, Task } from '../../../../store/slices';
import { useIsMobile } from '../../dashboardContext';
import { MessageReply, MessageRow } from './components';
import { ChatInput } from './components/ChatInput';

type TaskChatComponent = React.FC<{
    task: Task;
    collaborators: Collaborator[];
}>;

const validationSchema = Yup.object().shape({
    id: Yup.number().integer().optional(),
    message: Yup.string()
        .max(2400, 'Сообщение слишком длинное')
        .when('media', {
            is: (media: StrapiMedia[] | null) => media === null,
            then: Yup.string().required('Нет сообщения'),
            otherwise: Yup.string(),
        }),
    mentions: Yup.array(Yup.number().integer().nullable()),
    replay: Yup.number().integer().nullable(),
    media: Yup.array(
        Yup.object().shape({
            id: Yup.number().integer(),
        })
    ).nullable(),
});

const initialValues = {
    id: undefined,
    message: '',
    mentions: [],
    replay: null,
    media: null,
};

const limit = 20;
export const TaskChat: TaskChatComponent = ({ task, collaborators }) => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const virtuoso = useRef<VirtuosoHandle>(null!);
    const [offset, setOffset] = useState(0);
    const { data, isLoading, isFetching, ...rest } = useMessagesQuery(
        { chat: task.chat!, offset: offset },
        { refetchOnMountOrArgChange: true, refetchOnFocus: true, refetchOnReconnect: true }
    );

    const [messages, setMessages] = useState<Record<UniqueIdentifier, Message>>({});
    const [total, setTotal] = useState<number | null>(null);
    const [count, setCount] = useState<number | null>(null);

    const [startIndex, setStartIndex] = useState<number | null>(null);

    useEffect(() => {
        if (data) {
            const { total } = data.meta;
            const msgs = { ...messages, ...data.data };
            const count = Object.keys(msgs).length;

            setStartIndex(Math.max(0, total - 1 - count));
            setMessages(msgs);
            setTotal(data.meta.total);
            setCount(count);
        }
    }, [data]);

    useEffect(() => {
        const socket = getSocket();

        socket?.on('message.create', (message: Message) => {
            if (message.chat === task.chat) {
                setMessages((messages) => ({ ...messages, [message.id]: message }));
            }
        });
        socket?.on('message.delete', (message: Message) => {
            console.log(message);
            if (message.chat === task.chat) {
                setMessages((messages) => {
                    const { [message.id]: removedKey, ...rest } = messages;
                    return rest;
                });
            }
        });
        socket?.on('message.update', (message: Message) => {
            if (message.chat === task.chat) {
                setMessages((messages) => ({ ...messages, [message.id]: message }));
            }
        });

        return () => {
            socket?.off('message.create');
            socket?.off('message.update');
            socket?.off('message.delete');
        };
    }, []);

    const handlePreapend = () => setOffset(offset + limit);

    const [addMessage, { isLoading: isAddMessageLoading }] = useAddMessageMutation();
    const [editMessage, { isLoading: isEditMessageLoading }] = useEditMessageMutation();
    const [deleteMessage, { isLoading: isDeleteMessageLoading }] = useDeleteMessageMutation();

    const { resetEditor, focusEditor } = useFluentEdit();

    const [edit, setEdit] = useState<Message | null>(null);
    const formik = useFormik<Partial<Message>>({
        validateOnMount: true,
        validateOnChange: true,
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                if (values.id) {
                    await editMessage({ id: values.id, data: values }).unwrap();
                } else {
                    await addMessage({ chat: task.chat, data: values }).unwrap();
                    total && virtuoso.current.scrollToIndex(total - 1);
                }
            } catch (e: any) { }

            formik.resetForm({ values: initialValues });
            resetEditor();
            setEdit(null);
        },
    });

    const { media, files, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } =
        useUpload({
            media: formik.values.media,
            onUploaded: (media) => {
                formik.setFieldValue(
                    'media',
                    formik.values.media ? [...formik.values.media, ...media] : media
                );
            },
        });

    const handleStrapiDelete = (item: StrapiMedia) =>
        formik.setFieldValue(
            'media',
            formik.values.media?.filter(({ id }) => id !== item.id)
        );

    const handleMediaClick = (message: Message, item: StrapiMedia) => {
        navigate(
            {
                pathname: `/dashboard/projects/${task.project}/tasks/${task.id}/edit/messages/${message.id}/media/${item.id}`,
                search: searchParams.toString(),
            },
            {
                state: message.media,
            }
        );
    };

    const handleReplay = (message: Message) => {
        formik.setFieldValue('replay', message.id);
        formik.setTouched({ ...formik.touched, replay: true });
        setEdit(null);
        focusEditor();
    };

    const handleClearReplay = () => {
        formik.setFieldValue('replay', null);
        let { replay, ...rest } = formik.touched;
        formik.setTouched({ ...rest });
    };

    const handleEdit = (message: Message) => {
        formik.resetForm({ values: message });
        setEdit(message);
        resetEditor(message.message);
    };

    const handleClearEdit = () => {
        setEdit(null);
        formik.resetForm();
        resetEditor();
    };

    const handleClear = () => {
        formik.resetForm();
        resetEditor();
    };

    const handleDelete = async (message: Message) => {
        await deleteMessage(message.id);
    };

    const handleClearText = () => {
        formik.setFieldValue('message', '');
        resetEditor();
        let { message, ...rest } = formik.touched;
        formik.setTouched({ ...rest });
    };

    const Header = useMemo(
        () => () =>
        (
            <>
                {isFetching && !isLoading && (
                    <Stack
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            height: 80,
                            bgcolor: `rgba(0,0,0,0.5)`,
                        }}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <CircularProgress />
                    </Stack>
                )}
            </>
        ),
        [isFetching, isLoading]
    );

    // console.log({
    //     isLoading,
    //     total,
    //     count,
    //     messages,
    //     startIndex,
    //     condition: Boolean(!isLoading && total && count && messages && startIndex),
    // });

    return (
        <FormControl variant='outlined'>
            <InputBase inputProps={{ ...getInputProps() }} sx={{ display: 'none' }} />
            <Paper
                {...getRootProps()}
                onClick={(e) => e.stopPropagation()}
                variant='outlined'
                sx={{
                    background: 'none',
                    p: 0.5,
                    ...(isDragActive && { border: '1px solid red' }),
                }}
            >
                <Paper variant='outlined' sx={{ background: 'none' }}>
                    <Stack sx={{ px: 1, py: 0.5, minHeight: 280 }}>
                        {isLoading && (
                            <Stack
                                sx={{ width: '100%', height: 272 }}
                                justifyContent='center'
                                alignItems='center'
                            >
                                <CircularProgress />
                            </Stack>
                        )}
                        {!isLoading && (
                            <Virtuoso
                                ref={virtuoso}
                                data={Object.values(messages)}
                                followOutput='smooth'
                                style={{ flex: 1, height: 272, position: 'relative' }}
                                alignToBottom
                                firstItemIndex={Math.max(0, startIndex ?? 0)}
                                initialTopMostItemIndex={Math.max(0, (count ?? 0) - 1)}
                                startReached={handlePreapend}
                                totalCount={total ?? 0}
                                itemContent={(index, message) => (
                                    <MessageRow
                                        index={index}
                                        message={message}
                                        collaborators={collaborators}
                                        messages={messages}
                                        onEdit={handleEdit}
                                        onReplay={handleReplay}
                                        onDelete={handleDelete}
                                        onMediaClick={handleMediaClick}
                                    />
                                )}
                                components={{ Header }}
                            />
                        )}
                    </Stack>
                </Paper>
                <Stack sx={{ p: 0.5 }}>
                    <Collapse in={Boolean(formik.values.replay || edit)}>
                        <Paper variant='outlined' sx={{ p: 0.5, background: 'none' }}>
                            {edit ? (
                                <Stack direction='row' alignItems='center'>
                                    <MessageReply
                                        message={edit}
                                        collaborators={collaborators}
                                        onMediaClick={handleMediaClick}
                                    />
                                    <Stack alignItems='center' alignSelf='center' sx={{ px: 0.5 }}>
                                        <Edit />
                                    </Stack>
                                    <Box sx={{ pl: 1 }}>
                                        <IconButton onClick={handleClearEdit}>
                                            <Close />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            ) : (
                                <>
                                    {formik.values.replay && messages && messages[formik.values.replay] && (
                                        <Stack direction='row' alignItems='center'>
                                            <MessageReply
                                                message={messages[formik.values.replay]}
                                                collaborators={collaborators}
                                                onMediaClick={handleMediaClick}
                                            />

                                            <Stack alignItems='center' alignSelf='center' sx={{ px: 0.5 }}>
                                                <Reply />
                                            </Stack>

                                            <Box sx={{ pl: 1 }}>
                                                <IconButton onClick={handleClearReplay}>
                                                    <Close />
                                                </IconButton>
                                            </Box>
                                        </Stack>
                                    )}
                                </>
                            )}
                        </Paper>
                    </Collapse>
                    <Collapse
                        in={
                            Boolean(formik.values.media && formik.values.media?.length > 0) ||
                            Boolean(files && files.length > 0)
                        }
                    >
                        <Paper variant='outlined' sx={{ p: 0.5, background: 'none' }}>
                            <Stack direction='row' flexWrap='wrap' justifyContent='left' alignItems='center'>
                                {media?.map((item, i) => (
                                    <UploadItemStrapi
                                        item={item}
                                        key={`media-${item.id}-i`}
                                        onDelete={handleStrapiDelete}
                                        size='small'
                                    />
                                ))}
                                {files.map((item, i) => (
                                    <UploadItemFile
                                        item={item}
                                        key={`uploading-${i}`}
                                        onClick={() => { }}
                                        size='small'
                                    />
                                ))}
                            </Stack>
                        </Paper>
                    </Collapse>
                    <ChatInput
                        formik={formik}
                        collaborators={collaborators}
                        open={open}
                        isLoading={isAddMessageLoading || isEditMessageLoading || isDeleteMessageLoading}
                        onClear={handleClearText}
                        isValid={formik.isValid}
                    />
                </Stack>
            </Paper>
        </FormControl>
    );
};
