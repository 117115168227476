import { CircularProgress, FormControlLabel, Stack, Switch, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useUpdateNotificationsSettingsMutation } from '../../../store/api';
import { useTypedSelector } from '../../../store/hooks';
import { NotificationType } from '../../../store/slices/notifications';

type NotificationsSettingsComponent = React.FC<{}>;

export const NotificationsSettings: NotificationsSettingsComponent = ({}) => {
    const theme = useTheme();
    const notifications = useTypedSelector((state) => state.settings.notifications);
    const [updateNotificationSettings, { isLoading }] = useUpdateNotificationsSettingsMutation();

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let key = e.target.name as NotificationType;
        const data = { id: notifications.id, [key]: !notifications[key] };
        updateNotificationSettings(data);
    };

    return (
        <Stack spacing={2}>
            <Typography variant='h4'>E-MAIL УВЕДОМЛEНИЯ</Typography>
            {/* TASKS */}
            <Stack>
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifications['task_assigned']}
                            onChange={handleChange}
                            //disabled={isLoading}
                            name={'task_assigned'}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={`Задача назначена`}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifications['task_unassigned']}
                            onChange={handleChange}
                            //disabled={isLoading}
                            name={'task_unassigned'}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={`Задача назначение снято`}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifications['task_completed']}
                            onChange={handleChange}
                            //disabled={isLoading}
                            name={'task_completed'}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={`Задача выполнена`}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifications['task_uncompleted']}
                            onChange={handleChange}
                            //disabled={isLoading}
                            name={'task_uncompleted'}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={`Задача не выполнена`}
                />
            </Stack>
            {/* COLLABS */}
            <Stack>
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifications['collaboration_sent']}
                            onChange={handleChange}
                            //disabled={isLoading}
                            name={'collaboration_sent'}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={`Приглашение в проект отправлено`}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifications['collaboration_recived']}
                            onChange={handleChange}
                            //disabled={isLoading}
                            name={'collaboration_recived'}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={`Приглашение в проект получено`}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifications['collaboration_accepted']}
                            onChange={handleChange}
                            //disabled={isLoading}
                            name={'collaboration_accepted'}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={`Приглашение в проект принято`}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifications['collaboration_rejected']}
                            onChange={handleChange}
                            //disabled={isLoading}
                            name={'collaboration_rejected'}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={`Приглашение в проект отклонено`}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifications['collaboration_removed']}
                            onChange={handleChange}
                            //disabled={isLoading}
                            name={'collaboration_removed'}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={`Приглашение в проект отозвано`}
                />
            </Stack>
            {/* MESSAGES */}
            <Stack>
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifications['message_mention']}
                            onChange={handleChange}
                            //disabled={isLoading}
                            name={'message_mention'}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={`Упоминание в чате`}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifications['message_replay']}
                            onChange={handleChange}
                            //disabled={isLoading}
                            name={'message_replay'}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={`Ответ на сообщение в чате`}
                />
            </Stack>
            {isLoading && (
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        right: (theme) => theme.spacing(4),
                        bottom: (theme) => theme.spacing(4),
                    }}
                    size={theme.typography.h4.fontSize}
                />
            )}
        </Stack>
    );
};
