import React from 'react';
import { Link as DomLink, useSearchParams } from 'react-router-dom';
import { Typography, Link, SxProps } from '@mui/material';
interface ITextLink {
    color?: 'disabled' | 'action' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning';
    variant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline';
    href: string;
    children?: React.ReactNode;
    sx?: SxProps;
}

export const TextLink: React.FC<ITextLink> = ({ color, variant, href, children, sx }) => {
    const [searchParams] = useSearchParams();

    const search = searchParams ? `?${searchParams.toString()}` : '';

    return (
        <Typography color={color} variant={variant} sx={sx}>
            <DomLink to={href + search}>{children}</DomLink>
        </Typography>
    );
};
