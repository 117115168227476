import { LoadingButton } from '@mui/lab';
import { Alert, Checkbox, Collapse, Divider, FormControlLabel, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PageDialog } from '../../components/containers/DialogPage';
import { Logo } from '../../components/Logo';
import { TextLink } from '../../components/TextLink';
import { ValidationError } from '../../store/api';
import { LoginRequest, useLoginMutation } from '../../store/api/auth';
import { Layout } from '../../components/containers/Layout';
import { useTypedDispatch, useTypedSelector } from '../../store/hooks';
import { logout } from '../../store/slices/user';

interface ILogin {}

export const Login: React.FC<ILogin> = ({}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [login, { isLoading, isError, error }] = useLoginMutation();
    const user = useTypedSelector((state) => state.user);
    const dispatch = useTypedDispatch();

    const [state, setState] = useState<LoginRequest>({
        identifier: '',
        password: '',
    });

    const handleAuth = async () => {
        try {
            const res = await login(state).unwrap();
            navigate(
                location.state && (location.state as any).pathname
                    ? `${(location.state as any).pathname}${location.search}`
                    : '/dashboard'
            );
        } catch (e) {
            console.log((e as ValidationError).data.error);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setState({ ...state, [e.target.name]: e.target.value });

    useEffect(() => {
        if (Boolean(user)) dispatch(logout());
    }, []);

    return (
        <Layout title={'Login'}>
            <PageDialog>
                <Stack spacing={4}>
                    <Logo />
                    <Stack spacing={2}>
                        <TextField
                            label='Имя пользователя / Электронная почта'
                            name='identifier'
                            onChange={handleInputChange}
                        />
                        <TextField
                            label='Пароль'
                            type='password'
                            name='password'
                            onChange={handleInputChange}
                        />
                        <Collapse in={isError}>
                            <Alert title={(error as ValidationError)?.data.error.name} severity='error'>
                                {(error as ValidationError)?.data.error.message}
                            </Alert>
                        </Collapse>
                        <LoadingButton
                            variant='contained'
                            size='large'
                            onClick={handleAuth}
                            loading={isLoading}
                        >
                            Войти
                        </LoadingButton>
                        <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label='Оставаться в системе'
                        />
                        <TextLink color='primary' href='/forgot-password'>
                            Забыли пароль?
                        </TextLink>
                    </Stack>
                    <Divider />
                    <Stack direction='row' spacing={2}>
                        <Typography>Нет аккаунта?</Typography>
                        <TextLink color='primary' href='/register'>
                            Регистрация
                        </TextLink>
                    </Stack>
                </Stack>
            </PageDialog>
        </Layout>
    );
};
