import { UniqueIdentifier } from '@dnd-kit/core';
import { createSelector, createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../.';
import { getSocket, connect, disconnect } from './socket';

import { collaborationApi } from '../api/collaboration';
import { StrapiMedia } from '../api/upload';
import { Avatar } from './user';

export type Collaborator = {
    id: UniqueIdentifier;
    username: string;
    email: string;
    firstname: string;
    lastname: string;
    avatar: Avatar | null;
};

const slice = createSlice({
    name: 'collaborators',
    initialState: {} as Record<UniqueIdentifier, Collaborator>,
    extraReducers: (builder) => {
        builder.addMatcher(
            collaborationApi.endpoints.getCollaborators.matchFulfilled,
            (state, { payload }) => {
                return payload;
            }
        );
    },
    reducers: {},
});

export const {} = slice.actions;
export default slice.reducer;

export const collaboratorsMiddleware: Middleware = (store) => (next) => (action) => {
    const socket = getSocket();
    if (connect.match(action)) {
    }
};

export const collaboratorsSelector = createSelector(
    (state: RootState) => state.collaborators,
    (state: RootState) => state.user,
    (state: RootState, projectId: UniqueIdentifier | undefined | null) =>
        projectId ? state.projects[projectId] : null,
    (collaborators, user, project) =>
        user && project
            ? [
                  {
                      id: user.id,
                      username: user.username,
                      firstname: user.firstname,
                      lastname: user.lastname,
                      email: user.email,
                      avatar: user.avatar,
                  } as Collaborator,
                  ...Object.values(collaborators).filter(
                      (collaborator) =>
                          project.user === collaborator.id || project.users.includes(collaborator.id)
                  ),
              ]
            : ([] as Collaborator[])
);

export const allCollaboratorsSelector = createSelector(
    (state: RootState) => state.user,
    (state: RootState) => state.collaborators,
    (user, collaborators) =>
        ({
            [user.id!]: {
                id: user.id,
                username: user.username,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                avatar: user.avatar,
            },
            ...collaborators,
        } as Record<UniqueIdentifier, Collaborator>)
);
export const collaboratorSelector = createSelector(
    (state: RootState) => Object.values(state.collaborators),
    (state: RootState) => {
        const { id, username, firstname, lastname, email, avatar } = state.user;
        return { id, username, firstname, lastname, email, avatar };
    },
    (state: RootState, userId: UniqueIdentifier | undefined | null) => userId,
    (collaborators, user, userId) =>
        userId ? [...collaborators, user].find(({ id }) => id === userId) || null : null
);

export const getAssignedCollaboratorsSelector = createSelector(
    (state: RootState, taskId: UniqueIdentifier | undefined | null) =>
        taskId ? Object.values(state.assignments).filter(({ task }) => task === taskId) : null,
    (state: RootState, taskId: UniqueIdentifier | undefined | null) =>
        taskId ? collaboratorsSelector(state, state.tasks[taskId]?.project || null) : null,
    (assignments, collaborators) =>
        assignments && collaborators
            ? assignments.flatMap(({ assigned }) =>
                  collaborators.find(({ id }) => id === assigned)
                      ? [collaborators.find(({ id }) => id === assigned)!]
                      : []
              )
            : []
);

// export const getCollaboratorsSelector = (state: RootState, projectId: UniqueIdentifier) => {
//     const project = state.projects[projectId];
//     return [
//         {
//             id: state.user.id!,
//             username: state.user.username!,
//             firstname: state.user.firstname!,
//             lastname: state.user.lastname!,
//             email: state.user.email!,
//         },
//         ...Object.values(state.collaborators).filter(
//             (collaborator) => project.user === collaborator.id || project.users.includes(collaborator.id)
//         ),
//     ];
// };
