import { UniqueIdentifier } from '@dnd-kit/core';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { Button, Divider, Fab, IconButton, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useAddSectionMutation } from '../../../../store/api/section';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Section as SectionType } from '../../../../store/slices/sections';
import { Section } from '../section/Section';
import { LoadingButton } from '@mui/lab';
import { SectionWrapper } from '../section/SectionWrapper';

interface IProjectDivider {
    section: SectionType;
}

const validationSchema = Yup.object().shape({
    title: Yup.string().max(120, 'Title is too long'),
});

export const ProjectDivider: React.FC<IProjectDivider> = ({ section }) => {
    /// TODO INSER SECTION
    const [addSection, { isLoading, isError, error }] = useAddSectionMutation();

    const [open, setOpen] = useState(false);

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            title: 'Новый раздел',
            index: section.index + 1,
            project: section.project,
        },
        validationSchema,
        onSubmit: async (values) => {
            await addSection(values);
            handleCancel();
        },
    });
    const handleOpen = () => setOpen(true);
    const handleCancel = () => {
        setOpen(false);
        resetForm();
    };

    return (
        <>
            <Divider
                orientation='vertical'
                flexItem
                sx={{
                    color: (theme) => theme.palette.primary.main,
                    position: 'relative',
                    opacity: 0,
                    '&::before, &::after': {
                        borderColor: (theme) => theme.palette.primary.main,
                    },
                    '&:hover': {
                        opacity: 1,
                    },
                }}
            >
                <IconButton
                    size='small'
                    color='primary'
                    sx={{
                        position: 'absolute',
                        transform: 'translateX(-50%) translateY(-50%) ',
                        // background: (theme) => theme.palette.background.paper,
                        zIndex: 1,
                    }}
                    onClick={handleOpen}
                >
                    <AddCircleOutlineOutlined />
                </IconButton>
            </Divider>
            {open && (
                <SectionWrapper type='new-section' disabled={open}>
                    <Stack spacing={1}>
                        <TextField
                            size='small'
                            label='Новый раздел'
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='title'
                            error={touched.title && Boolean(errors.title)}
                            helperText={touched.title && errors.title}
                        />
                        <Stack direction='row' justifyContent='end' spacing={1}>
                            <Button color='secondary' onClick={handleCancel}>
                                Cancel
                            </Button>
                            <LoadingButton
                                variant='contained'
                                onClick={() => handleSubmit()}
                                loading={isLoading}
                            >
                                Ok
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </SectionWrapper>
            )}
        </>
    );
};
