import { Breakpoint, CircularProgress, Dialog, Stack } from '@mui/material';
import React from 'react';
import { Loading } from '../Loading';

type DialogModalComponent = React.FC<{
    open?: boolean;
    loading?: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
    fullScreen?: boolean;
    maxWidth?: false | Breakpoint;
}>;

export const LoadingDialog: DialogModalComponent = ({
    open = true,
    onClose,
    loading,
    children,
    fullScreen,
    maxWidth = 'sm',
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={maxWidth}
            fullWidth
            fullScreen={fullScreen}
            scroll='body'
        >
            {loading ? (
                <Stack sx={{ height: '100%' }} justifyItems='center' alignItems='center'>
                    <CircularProgress />
                </Stack>
            ) : (
                children
            )}
        </Dialog>
    );
};
