import { UniqueIdentifier } from '@dnd-kit/core';
import { createSelector, createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit';
import { notificationApi } from '../api';
import { getSocket, connect, disconnect } from './socket';
import type { RootState } from '../.';
import { number } from 'yup';

export type NotificationType =
    | 'task_assigned'
    | 'task_unassigned'
    | 'task_completed'
    | 'task_uncompleted'
    | 'collaboration_sent'
    | 'collaboration_accepted'
    | 'collaboration_recived'
    | 'collaboration_rejected'
    | 'collaboration_removed';

export type INotification = {
    id: UniqueIdentifier;
    from: UniqueIdentifier;
    to: UniqueIdentifier;
    type: NotificationType;
    message: string;
    action?: string;
    unread: boolean;
};

export type NotificationsState = {
    total: number;
    unread: number;
    data: Record<UniqueIdentifier, INotification>;
};

const slice = createSlice({
    name: 'notifications',
    initialState: {
        total: 0,
        unread: 0,
        data: {},
    } as NotificationsState,
    reducers: {
        create: (state, { payload }: PayloadAction<INotification>) => {
            state.data[payload.id] = payload;
        },
        update: (state, { payload }: PayloadAction<INotification>) => {
            state.data[payload.id] = { ...state.data[payload.id], ...payload };
        },
        delete: (state, { payload }: PayloadAction<INotification>) => {
            delete state.data[payload.id];
        },
        updateMeta: (state, { payload }: PayloadAction<{ total: number; unread: number }>) => {
            state.total = payload.total;
            state.unread = payload.unread;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(notificationApi.endpoints.notifications.matchFulfilled, (state, { payload }) => {
            state.total = payload.meta.total;
            state.unread = payload.meta.unread;
            state.data = {
                ...state.data,
                ...payload.data,
            };
        });
    },
});

export const {} = slice;
export default slice.reducer;

export const notificationsMiddleware: Middleware = (store) => (next) => (action) => {
    const socket = getSocket();
    if (connect.match(action)) {
        socket?.on(
            'notification.create',
            ({ data, meta }: { data: INotification; meta: { total: number; unread: number } }) => {
                const user = (store.getState() as RootState).user.id;
                if (data.to === user) {
                    store.dispatch(slice.actions.create(data));
                    store.dispatch(slice.actions.updateMeta(meta));
                }
            }
        );
        socket?.on(
            'notification.update',
            ({ data, meta }: { data: INotification; meta: { total: number; unread: number } }) => {
                const notifications = Object.keys((store.getState() as RootState).notifications.data);
                if (notifications.includes(data.id.toString())) {
                    store.dispatch(slice.actions.update(data));
                    store.dispatch(slice.actions.updateMeta(meta));
                }
            }
        );
        socket?.on(
            'notification.delete',
            ({ data, meta }: { data: INotification; meta: { total: number; unread: number } }) => {
                const notifications = Object.keys((store.getState() as RootState).notifications.data);
                if (notifications.includes(data.id.toString())) {
                    store.dispatch(slice.actions.delete(data));
                    store.dispatch(slice.actions.updateMeta(meta));
                }
            }
        );
        if (disconnect.match(action)) {
            socket?.off('notification.create');
            socket?.off('notification.update');
            socket?.off('notification.delete');
        }
    }
    return next(action);
};
