import { Alert, Box, Collapse, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { PageDialog } from '../../components/containers/DialogPage';
import { Layout } from '../../components/containers/Layout';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../components/Logo';
import { LoadingButton } from '@mui/lab';
import { TextLink } from '../../components/TextLink';
import { useForgotPasswordMutation } from '../../store/api/auth';
import { ValidationError } from '../../store/api';

type ForgotPasswordComponent = React.FC<{}>;

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Некорректный адрес электронной почты')
        .required('Адрес электронной почты необходим'),
});

export const ForgotPassword: ForgotPasswordComponent = ({}) => {
    const navigate = useNavigate();
    const [forgotPassword, { isLoading, isError, isSuccess, error }] = useForgotPasswordMutation();
    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            email: '',
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema,
        onSubmit: async (values) => {
            try {
                const res = await forgotPassword(values).unwrap();
            } catch (e) {
                console.log((e as ValidationError).data.error);
            }
        },
    });

    return (
        <Layout title={'Восстановить пароль'}>
            <PageDialog>
                <Stack spacing={4}>
                    <Logo />
                    <Stack spacing={2}>
                        <Typography variant='h5'>Восстановление пароля</Typography>
                        <TextField
                            label='Адрес электронной почты'
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='email'
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                        />
                        <Collapse in={isError}>
                            <Alert title={(error as ValidationError)?.data.error.name}>
                                {(error as ValidationError)?.data.error.message}
                            </Alert>
                        </Collapse>
                        <Collapse in={isSuccess}>
                            <Alert>Ссылка для восстановления пароля отправлена на почту</Alert>
                        </Collapse>
                        <LoadingButton
                            variant='contained'
                            size='large'
                            onClick={() => handleSubmit()}
                            disabled={!(values.email.length > 0) || !isEmpty(errors)}
                        >
                            Восстановить
                        </LoadingButton>
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        <TextLink color='primary' href='/register'>
                            Регистрация
                        </TextLink>
                        <TextLink color='primary' href='/login'>
                            Вход
                        </TextLink>
                    </Stack>
                </Stack>
            </PageDialog>
        </Layout>
    );
};
