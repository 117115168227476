import type {
    DragCancelEvent,
    DragEndEvent,
    DragMoveEvent,
    DragOverEvent,
    DragStartEvent,
    UniqueIdentifier,
} from '@dnd-kit/core';
import {
    defaultDropAnimation,
    DndContext,
    DragOverlay,
    Modifier,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { MouseSensor, KeyboardSensor } from '../../components/dnd';

import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTypedSelector } from '../../store/hooks';
import { dndType } from './utils';

//import { ApiProject } from '../../store/api/projects';
import { Project } from './tree/components/Project';
import { Task } from './project/task/Task';
import { Leaf } from '../../store/slices/tree';
import { Task as TaskType } from '../../store/slices/tasks';

export type DashboardContext = {
    colors: Record<
        'disabled' | 'action' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning',
        string
    >;
    displays: Record<'board', string>;
    drag: 'project' | 'section' | 'task' | null;
    active: null | IDndEvent;
    over: null | IDndEvent;
    //offset: number;
    reset: () => void;
    isMobile: boolean;
};

export const dashboardContext = React.createContext<DashboardContext | null>(null);

interface IDashboardProvider {
    children?: React.ReactNode;
}

export interface IDndEvent {
    id: UniqueIdentifier;
    type: 'project' | 'section' | 'task';
}

export const DashboardProvider: React.FC<IDashboardProvider> = ({ children }) => {
    const theme = useTheme();
    const colors = useMemo(
        () => ({
            action: theme.palette.action.active,
            disabled: theme.palette.action.disabled,
            error: theme.palette.error.main,
            info: theme.palette.info.main,
            primary: theme.palette.primary.main,
            secondary: theme.palette.secondary.main,
            success: theme.palette.success.main,
            warning: theme.palette.warning.main,
        }),
        [theme]
    );

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const displays = useMemo(() => ({ board: 'доска' }), []);

    const { enqueueSnackbar } = useSnackbar();

    const sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 8 } }),
        useSensor(TouchSensor, { activationConstraint: { delay: 200, tolerance: 1 } })
    );

    const [drag, setDrag] = useState<'project' | 'section' | 'task' | null>(null);
    const [active, setActive] = useState<null | IDndEvent>(null);
    const [over, setOver] = useState<null | IDndEvent>(null);

    //const [offset, setOffset] = useState(0);

    const handleDragStart = (e: DragStartEvent) => {
        setDrag(e?.active?.data?.current?.type);
        setActive(e.active.data.current as IDndEvent);
        setOver(e.active.data.current as IDndEvent);

        document.body.style.setProperty('cursor', 'grabbing');
    };
    //const handleDragMove = (e: DragMoveEvent) => setOffset(e.delta.x);
    const handleDragOver = (e: DragOverEvent) => setOver(e.over?.data.current as IDndEvent | null);
    const handleDragEnd = (e: DragEndEvent) => {
        reset();
    };

    const handleDragCancel = (e: DragCancelEvent) => reset();

    const reset = () => {
        setDrag(null);
        setOver(null);
        setActive(null);
        //setOffset(0);

        document.body.style.setProperty('cursor', '');
    };

    //console.log(active, over);

    return (
        <dashboardContext.Provider
            value={{
                drag,
                active,
                over,
                //offset,
                reset,
                colors,
                displays,
                isMobile,
            }}
        >
            <DndContext
                sensors={sensors}
                onDragStart={handleDragStart}
                //onDragMove={handleDragMove}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
            >
                <>
                    {children}
                    <DndPortal />
                </>
            </DndContext>
        </dashboardContext.Provider>
    );
};

const adjustTranslate: Modifier = ({ transform }) => {
    return {
        ...transform,
        y: transform.y - 50,
    };
};

export const DndPortal: React.FC = () => {
    const theme = useTheme();
    const { active } = useContext(dashboardContext) as DashboardContext;

    const projects = useTypedSelector((state) => state.projects);
    const leaves = useTypedSelector((state) => state.tree);
    const sections = useTypedSelector((state) => state.sections);
    const tasks = useTypedSelector((state) => state.tasks);

    const leaf = useMemo(
        () => (active && active.type === 'project' ? leaves[active.id] : null),
        [leaves, active]
    );
    const project = useMemo(() => (leaf ? projects[leaf.project] : null), [leaf, projects]);

    const task = useMemo(() => (active && active.type === 'task' ? tasks[active.id] : null), [active, tasks]);
    const section = useMemo(
        () => (active && active.type === 'section' ? sections[active.id] : null),
        [active, sections]
    );

    return createPortal(
        <DragOverlay
            dropAnimation={defaultDropAnimation}
            //modifiers={[adjustTranslate]}
            zIndex={theme.zIndex.drawer + 2}
        >
            {leaf && project && <Project id={leaf.id} project={project} clone childs={leaf.childs} />}
            {task && <Task task={task} clone />}
        </DragOverlay>,
        document.body
    );
};

export const useIsMobile = () => {
    const { isMobile } = useContext(dashboardContext) as DashboardContext;

    return useMemo(() => isMobile, [isMobile]);
};
