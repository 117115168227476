import { ExpandLess } from '@mui/icons-material';
import { Drawer, Fab } from '@mui/material';
import React, { useState } from 'react';

interface TreeDrawerProps {
    children: React.ReactNode;
    top: number;
    onOpen: () => void;
    open: boolean;
}

export const TreeDrawer = React.forwardRef<HTMLDivElement, TreeDrawerProps>(
    ({ top, onOpen, open, children }, ref) => {
        return (
            <>
                <Drawer
                    PaperProps={{
                        ref,
                        sx: {
                            top,
                        },
                    }}
                    open={open}
                    variant='persistent'
                    anchor='left'
                >
                    {children}
                </Drawer>
              
            </>
        );
    }
);
