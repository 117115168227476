import { AttachFile, Close, Send } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Collapse, IconButton, InputBase, Paper, Stack, useTheme } from '@mui/material';
import { createMentionsPlugin } from '@react-fluent-edit/mentions';
import { MuiFluentEdit, MuiMentionCombobox } from '@react-fluent-edit/mui';
import { FormikProps, useFormik } from 'formik';
import React, { useImperativeHandle, useMemo } from 'react';
import * as Yup from 'yup';
import { UploadItem, UploadItemFile, UploadItemStrapi, useUpload } from '../../../../../components/upload';
import { StrapiMedia } from '../../../../../store/api';
import { Collaborator, Message } from '../../../../../store/slices';
import { DropzoneRootProps } from 'react-dropzone';
import { isEmpty } from 'lodash-es';
import { useIsMobile } from '../../../dashboardContext';

type ChatInputComponent = React.FC<{
    collaborators: Collaborator[];
    formik: FormikProps<Partial<Message>>;
    open: () => void;
    isLoading: boolean;
    onClear: () => void;
    isValid: boolean;
}>;

export const ChatInput: ChatInputComponent = ({
    collaborators,
    formik,
    open,
    isLoading,
    onClear,
    isValid,
}) => {
    const isMobile = useIsMobile();
    const theme = useTheme();
    const plugin = useMemo(
        () =>
            createMentionsPlugin({
                mentions: [
                    {
                        trigger: '@',
                        style: {
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.background.paper,
                        },
                    },
                ],
            }),
        [theme]
    );

    const options = useMemo(
        () => collaborators?.map(({ username }) => ({ text: `${username}`, trigger: '@' } || [])),
        [collaborators]
    );

    return (
        <Stack direction='row' alignItems='stretch' sx={{ pt: 0.5, maxWidth: '100%' }}>
            <Stack direction='row' alignItems='center' sx={{ px: 1 }}>
                <Box>
                    <IconButton onClick={open}>
                        <AttachFile />
                    </IconButton>
                </Box>
            </Stack>
            <Box sx={{ flex: 1, position: 'relative' }}>
                <Collapse in={Boolean(formik.values.message && formik.values.message.length > 0)}>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: (theme) => theme.zIndex.drawer,
                        }}
                        onClick={onClear}
                    >
                        <Close />
                    </IconButton>
                </Collapse>
                <MuiFluentEdit
                    plugins={[plugin]}
                    onChange={(e) => {
                        formik.setFieldValue('message', e);
                        formik.setFieldValue(
                            'mentions',
                            collaborators
                                .filter(({ username }) => e.includes(`@${username}`))
                                .map(({ id }) => id)
                        );
                    }}
                    onBlur={(e) => formik.handleBlur({ target: { name: 'message' } })}
                    onClick={(e) => e.stopPropagation()}
                    style={{ marginRight: theme.spacing(4), boxSizing: 'border-box', wordBreak: 'break-all' }}
                >
                    <MuiMentionCombobox items={options} />
                </MuiFluentEdit>
            </Box>
            <Stack direction='row' sx={{ pl: 1 }} alignItems='center'>
                <LoadingButton
                    variant='contained'
                    sx={{ height: '100%' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        formik.handleSubmit();
                    }}
                    loading={isLoading}
                    disabled={!isValid}
                >
                    {isMobile ? <Send /> : `Отправить`}
                </LoadingButton>
            </Stack>
        </Stack>
    );
};
