import {
    Dialog, DialogContent,
    DialogTitle,
    Divider,
    Tab,
    Tabs
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

type UserComponent = React.FC<{}>;

export const User: UserComponent = ({ }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { pathname, state } = useLocation();

    const preffeared = useMemo(
        () =>
            pathname.includes('profile')
                ? 'profile'
                : pathname.includes('settings')
                    ? 'settings'
                    : pathname.includes('notifications')
                        ? 'notifications'
                        : pathname.includes('invites')
                            ? 'invites'
                            : null,
        [pathname]
    );

    const navState = useMemo(() => state, []);

    const handleClose = () => {
        if (state) {
            navigate({
                pathname: navState.location.pathname,
                search: searchParams.toString(),
            });
        } else navigate({
            pathname: '/dashboard',
            search: searchParams.toString(),
        });
    };

    const handleProfileClick = () =>
        navigate(
            {
                pathname: '/dashboard/user/profile',
                search: searchParams.toString(),
            },
            { replace: false, state: navState }
        );
    const handleSettingsClick = () =>
        navigate(
            {
                pathname: '/dashboard/user/settings',
                search: searchParams.toString(),
            },
            { replace: false, state: navState }
        );

    const handleInvitesClick = () =>
        navigate(
            {
                pathname: '/dashboard/user/invites',
                search: searchParams.toString(),
            },
            { replace: false, state: navState }
        );
    const handleNotificationsClick = () =>
        navigate(
            {
                pathname: '/dashboard/user/notifications',
                search: searchParams.toString(),
            },
            { replace: false, state: navState }
        );

    return (
        <>
            <Dialog open fullWidth maxWidth='sm' scroll='body' onClose={handleClose}>
                <DialogTitle>Пользователь</DialogTitle>
                <Divider />
                <Stack direction='row'>
                    <Tabs
                        orientation='vertical'
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                        value={preffeared}
                    >
                        <Tab label='Профиль' onClick={handleProfileClick} value='profile' />
                        <Tab label='Настройки' onClick={handleSettingsClick} value='settings' />
                        <Tab label='Уведомления' onClick={handleNotificationsClick} value='notifications' />
                        <Tab label='Приглашения' onClick={handleInvitesClick} value='invites' />
                    </Tabs>
                    <Divider orientation='vertical' />
                    <DialogContent>
                        <Outlet />
                    </DialogContent>
                </Stack>

                <Divider />
            </Dialog>
        </>
    );
};
