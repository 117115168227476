import type {} from '@mui/lab/themeAugmentation';
import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';

export const darkTheme: ThemeOptions = responsiveFontSizes(
    createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#de4c4a',
            },
            secondary: {
                main: '#ffffff',
                contrastText: '#de4c4a',
            },
        },
        components: {
            MuiList: {
                styleOverrides: {
                    root: {
                        paddingTop: 0,
                        paddingBottom: 2,
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
            },
        },
    }),
    {
        factor: 3,
    }
);
