import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography,
} from '@mui/material';

import React, { useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDeleteSectionMutation } from '../../../store/api/section';
import { useTypedSelector } from '../../../store/hooks';

type DeleteSectionComponent = React.FC<{}>;

export const DeleteSection: DeleteSectionComponent = ({ }) => {
    const { projectId, sectionId } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [deleteSection, { isLoading, isError, error }] = useDeleteSectionMutation();

    const sections = useTypedSelector((state) => state.sections);
    const tasks = useTypedSelector((state) => state.tasks);
    const section = useMemo(() => (sectionId ? sections[sectionId] : null), [sectionId, sections]);
    const count = useMemo(
        () =>
            section && section.id
                ? Object.values(tasks).filter(({ section: id }) => id === section.id).length
                : 0,
        [section, tasks]
    );

    const handleCancel = () =>
        navigate({
            pathname: `/dashboard/projects/${projectId}`,
            search: searchParams.toString()
        });

    const handleSubmit = async () => {
        if (section) {
            await deleteSection(section.id);
            handleCancel();
        }
    };

    return (
        <Dialog open onClose={handleCancel} maxWidth='sm' fullWidth>
            <DialogTitle>Удалить раздел</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack spacing={2}>
                    <Typography variant='h5'>{section?.title}</Typography>
                    <Typography>Задач: {count}</Typography>
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleCancel}>Отменить</Button>
                <LoadingButton variant='contained' onClick={handleSubmit} loading={isLoading}>
                    Удалить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
