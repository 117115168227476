import { CircularProgress, Stack } from '@mui/material';
import React from 'react';

import { Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useMeQuery } from '../store/api/auth';
import { useTypedSelector } from '../store/hooks';

interface IPrivateRoute {
    children: React.ReactNode;
}

export const PrivateRoute: React.FC<IPrivateRoute> = ({ children }) => {
    const jwt = useTypedSelector((state) => state.user.jwt);
    const {
        data: user,
        isLoading,
        isError,
        error,
    } = useMeQuery(jwt, { refetchOnMountOrArgChange: true, refetchOnReconnect: true });

    const { pathname, search } = useLocation();

    if (isError) {
        if ((error as any).status === 401) {
            return <Navigate to={`/login${search}`} replace={true} state={{ pathname, search }} />;
        }
    }
    if (jwt === null || jwt === undefined || (isError && (error as any).status === 401))
        return <Navigate to={`/login${search}`} replace={true} state={{ pathname, search }} />;

    if (isLoading)
        return (
            <Stack sx={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Stack>
        );
    return <>{children}</>;
};
