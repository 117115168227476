import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { boolean } from 'yup';
import { useTypedDispatch, useTypedSelector } from '../../store/hooks';
import { connect, disconnect } from '../../store/slices/socket';

type SocketContextComponent = React.FC<{
    children: React.ReactNode;
}>;

export const SocketContext: SocketContextComponent = ({ children }) => {
    const dispatch = useTypedDispatch();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { connected } = useTypedSelector((state) => state.socket);
    const { jwt } = useTypedSelector((state) => state.user);

    const [id, setId] = useState<SnackbarKey | undefined>();

    useEffect(() => {
        if (!connected) {
            if (!id) {
                setId(
                    enqueueSnackbar('Сокет отключен.', {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: true,
                        key: 'socket-snack',
                    })
                );
            }
        } else {
            closeSnackbar(id);
            setId(undefined);
        }
    }, [connected]);

    useEffect(() => {
        if (jwt) {
            if (!connected) {
                dispatch(connect(jwt));
            }
        }
        return () => {
            if (connected) {
                dispatch(disconnect());
            }
        };
    }, []);

    return <>{children}</>;
};
