import { UniqueIdentifier } from '@dnd-kit/core';
import { Delete, Done, Edit, MoreHoriz, RadioButtonUnchecked, TaskAlt } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUpdateTaskMutation } from '../../../../store/api/task';
import { Project } from '../../../../store/slices/projects';
import { Task } from '../../../../store/slices/tasks';

interface ITaskActions {
    task: Task;
}

export const TaskActions: React.FC<ITaskActions> = ({ task }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [updateTask, { isLoading, isError, error }] = useUpdateTaskMutation();

    const open = Boolean(anchorEl);

    const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const handleEdit = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        navigate({
            pathname: `/dashboard/projects/${task.project}/tasks/${task.id}/edit`,
            search: searchParams.toString(),
        });
        setAnchorEl(null);
    };

    const handleDelete = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        navigate({
            pathname: `/dashboard/projects/${task.project}/tasks/${task.id}/delete`,
            search: searchParams.toString(),
        });
        setAnchorEl(null);
    };

    const handleComplete = async (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        await updateTask({ ...task, completed: !task.completed });
    };

    return (
        <>
            <Box>
                <IconButton onClick={handleOpen}>
                    <MoreHoriz />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiSvgIcon-root': {
                            width: 24,
                            height: 24,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                {/* <MenuItem>
                    <Done /> Выполнить задачу
                </MenuItem> */}
                <MenuItem onClick={handleEdit}>
                    <Edit /> Редактировать задачу
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <Delete /> Удалить задачу
                </MenuItem>
                <MenuItem onClick={handleComplete}>
                    {task.completed ? <TaskAlt /> : <RadioButtonUnchecked />} Выполнить задачу
                </MenuItem>
            </Menu>
        </>
    );
};
