import { DragOverEvent, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useTypedDispatch } from '../../../store/hooks';
import { Section } from '../../../store/slices/sections';
import { Task } from '../../../store/slices/tasks';
import { arrayInsert, arrayRemove } from '../utils';

type Sections = Array<
    Section & {
        tasks: Task[];
    }
>;

export const findTask = (items: Sections, id: UniqueIdentifier): Task | null => {
    let res = null;
    items.forEach(({ tasks }) =>
        tasks.forEach((task) => {
            if (task.id === id) res = task;
        })
    );

    return res;
};

export const findSectionByTask = (
    items: Sections,
    id: UniqueIdentifier
): (Section & { tasks: Task[] }) | null => {
    let res = null;
    items.forEach((item) => {
        if (item.tasks.some((task) => task.id === id)) res = item;
    });

    return res;
};

export const findSection = (
    items: Sections,
    sectionId: UniqueIdentifier
): (Section & { tasks: Task[] }) | null => items.find(({ id }) => id === sectionId) || null;

type ActiveSectionId = UniqueIdentifier;
type ActiveTaskId = UniqueIdentifier;
type OverSectionId = UniqueIdentifier;
type OverTaskId = UniqueIdentifier;

export const dragOverTaskSection = (
    items: Sections,
    active: ActiveTaskId, // task
    over: OverSectionId // section
): Task[] | null => {
    let activeTask = findTask(items, active);
    const activeSection = findSectionByTask(items, active);
    const overSection = findSection(items, over);

    if (activeSection && overSection && activeTask) {
        if (activeSection.id === overSection.id) {
            const targetIndex = 0;
            const activeTasks = activeSection.tasks;
            const activeIndex = activeTasks.findIndex(({ id }) => id === activeTask!.id);

            return arrayMove(activeTasks, activeIndex, targetIndex).map((task, index) => ({
                ...task,
                index,
            }));
        }
        if (activeSection.id !== overSection.id) {
            let activeTasks = activeSection.tasks;
            let overTasks = overSection.tasks;

            activeTask = { ...activeTask, section: overSection.id };
            activeTasks = activeTasks.filter(({ id }) => id === activeTask!.id);

            overTasks = arrayInsert(overTasks, 0, activeTask);

            return [
                ...activeTasks.map((task, index) => ({ ...task, index })),
                ...overTasks.map((task, index) => ({ ...task, index })),
            ];
        }
    }

    return null;
};

export const dragOverTaskTask = (
    items: Sections,
    active: ActiveTaskId,
    over: OverTaskId,
    e: DragOverEvent
): Task[] | null => {
    let activeTask = findTask(items, active);
    const activeSection = findSection(items, activeTask!.section!);
    let activeTasks = activeSection?.tasks;
    const activeIndex = activeTasks?.findIndex(({ id }) => id === active);

    let overTask = findTask(items, over);
    const overSection = findSection(items, overTask!.section!);
    let overTasks = overSection?.tasks;
    const overIndex = overTasks?.findIndex(({ id }) => id === over);

    if (activeTask && overTask) {
        const isBelowOverItem =
            e.active.rect.current.translated &&
            e.active.rect.current.translated.top > e.over!.rect.top + e.over!.rect.height;

        const modifier = isBelowOverItem ? 1 : 0;

        if (activeSection!.id !== overSection!.id) {
            activeTask = { ...activeTask, section: overSection!.id };
            const newIndex = overIndex! >= 0 ? overIndex! + modifier : overTasks!.length + 1;

            activeTasks = arrayRemove(activeTasks!, activeIndex!);
            overTasks = arrayInsert(overTasks!, newIndex, activeTask);

            return [
                ...activeTasks.map((task, index) => ({ ...task, index })),
                ...overTasks.map((task, index) => ({ ...task, index })),
            ];
        } else if (activeSection!.id === overSection!.id) {
            const newIndex = overIndex! >= 0 ? overIndex! + modifier : overTasks!.length + 1;

            activeTasks = arrayMove(activeTasks!, activeIndex!, newIndex);

            return [...activeTasks.map((task, index) => ({ ...task, index }))];
        }
    }

    return null;
};

export const dragEndSectionSection = (items: Sections, active: ActiveSectionId) => {};
