import { AddAPhoto, Delete, Edit } from '@mui/icons-material';
import { Box, Button, Collapse, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useUploadMutation } from '../../../store/api/upload';

import { useDeleteUserAvatarMutation, useUpdateUserProfileMutation } from '../../../store/api/user';
import { useTypedSelector } from '../../../store/hooks';

import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash-es';

import { UserAvatar } from '../../../components/UserAvatar';

type ProfileComponent = React.FC<{}>;

const validationSchema = Yup.object().shape({
    firstname: Yup.string()
        .min(1, 'Firstname is too short')
        .max(24, 'Firstname is too long')
        .required('Firstname is required'),
    lastname: Yup.string()
        .min(1, 'Lastname is too short')
        .max(24, 'Lastname is too long')
        .required('Lastname is required'),
});

export const Profile: ProfileComponent = ({ }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { state } = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const user = useTypedSelector((state) => state.user);
    const [updateUserProfile, { isLoading, isError, error }] = useUpdateUserProfileMutation();
    const [deleteAvatar, { isLoading: isLoadingDelete, isError: isErrorDelete, error: errorDelete }] =
        useDeleteUserAvatarMutation();

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            firstname: user.firstname,
            lastname: user.lastname,
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                const { firstname, lastname } = await updateUserProfile({
                    id: user.id!,
                    data: values,
                }).unwrap();
                resetForm({
                    values: {
                        firstname,
                        lastname,
                    },
                });
                enqueueSnackbar('Изменения профайла сохранены', { variant: 'success' });
            } catch {
                enqueueSnackbar('Ошибка обновления профайла', { variant: 'error' });
            }
        },
    });

    const [upload, { isLoading: isLoadingUpload, isError: isErrorUpload, error: errorUpload }] =
        useUploadMutation();

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.gif', '.png'],
        },
        onDrop: async (accepted) => {
            if (accepted) {
                let data = new FormData();
                accepted.forEach((file) => data.append('files', file));
                try {
                    const res = await upload(data).unwrap();

                    try {
                        console.log(res);
                        await updateUserProfile({
                            id: user.id!,
                            data: {
                                avatar: res[0],
                            },
                        });
                        enqueueSnackbar('Изображение профайла изменено', { variant: 'success' });
                    } catch {
                        enqueueSnackbar('Ошибка обновления профайла', { variant: 'error' });
                    }
                } catch {
                    enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' });
                }
            }
        },
    });

    const hasAvatar = useMemo(() => Boolean(user.avatar), [user]);

    const handleDeleteAvatar = async () => {
        if (user.avatar) {
            await deleteAvatar(user.avatar.id);
        }
    };

    const handlePassword = () => navigate({ pathname: '/dashboard/user/profile/password', search: searchParams.toString() }, { replace: true, state });

    return (
        <>
            <Stack spacing={2}>
                <Stack spacing={1} sx={{ mr: 'auto' }} justifyContent='center'>
                    <Typography fontWeight='bold'>Изображение</Typography>
                    <UserAvatar user={user} size={12} />
                    {hasAvatar ? (
                        <Stack spacing={1} direction='row'>
                            <IconButton {...getRootProps()}>
                                <Edit />
                            </IconButton>
                            <IconButton onClick={handleDeleteAvatar}>
                                <Delete />
                            </IconButton>
                        </Stack>
                    ) : (
                        <Stack justifyContent='center'>
                            <IconButton sx={{ mx: 'auto' }} {...getRootProps()}>
                                <AddAPhoto />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>

                <TextField value={`@${user.username}`} label='Имя пользователя' disabled />
                <TextField value={user.email} label='Адрес электронной почты' disabled />
                <TextField
                    name='firstname'
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Имя'
                    error={touched.firstname && Boolean(errors.firstname)}
                    helperText={touched.firstname && errors.firstname}
                />
                <TextField
                    name='lastname'
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Фамилия'
                    error={touched.lastname && Boolean(errors.lastname)}
                    helperText={touched.lastname && errors.lastname}
                />
                <Collapse in={!isEmpty(touched)}>
                    <LoadingButton
                        variant='contained'
                        onClick={() => handleSubmit()}
                        loading={isLoading}
                        disabled={!isEmpty(errors)}
                    >
                        Сохранить
                    </LoadingButton>
                </Collapse>
                <Typography fontWeight='bold'>Пароль</Typography>
                <Box>
                    <Button variant='outlined' onClick={handlePassword}>
                        Сменить пароль
                    </Button>
                </Box>
            </Stack>
            <Outlet />
        </>
    );
};
