import { Circle, Dashboard } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Modal,
    Stack,
    ToggleButton,
    Typography,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAcceptCollaborationMutation } from '../../../store/api/collaboration';
import { useGetProjectQuery } from '../../../store/api/project';
import { useTypedSelector } from '../../../store/hooks';

interface IAcceptProject { }

export const AcceptProject: React.FC<IAcceptProject> = ({ }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { projectId } = useParams();
    const [search] = useSearchParams();
    const collaborationToken = useMemo(() => search.get('collaborationToken'), [search]);

    const { data: project, isLoading: isProjectLoading } = useGetProjectQuery(projectId!);
    const [acceptCollaboration, { isLoading }] = useAcceptCollaborationMutation();

    const handleSubmit = async () => {
        await acceptCollaboration({
            collaborationToken,
            project: projectId,
        });
        navigate({
            pathname: `/dashboard/projects/${projectId}`,
            search: searchParams.toString(),
        });
    };
    const handleCancel = () => navigate(`/dashboard`);

    useEffect(() => {
        if (collaborationToken === null) handleCancel();
    }, [collaborationToken]);

    return (
        <>
            <Dialog open={!isProjectLoading} onClose={handleCancel} maxWidth='sm' fullWidth>
                <DialogTitle>Подтвердить участие в проекте</DialogTitle>
                <Divider />
                <DialogContent>
                    <Stack spacing={2}>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <Circle color={project?.color} />{' '}
                            <Typography variant='h4'>{project?.title}</Typography>
                        </Stack>
                        <Box>
                            <Stack justifyContent='center' alignItems='center' spacing={1}>
                                <Dashboard />
                                <Typography variant='caption'>{project?.display}</Typography>
                            </Stack>
                        </Box>
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={handleCancel}>Отменить</Button>
                    <LoadingButton variant='contained' onClick={handleSubmit} loading={isLoading}>
                        Подтвердить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Modal open={isProjectLoading}>
                <Stack justifyContent='center' alignItems='center' sx={{ width: '100%', height: '100%' }}>
                    <CircularProgress />
                </Stack>
            </Modal>
        </>
    );
};
