import { buildCreateApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';

import { api } from './api';
import { Task } from '../slices/tasks';
import { UniqueIdentifier } from '@dnd-kit/core';
import { providesKeys } from '../utills';
import { Assigment } from '../slices/assignments';
import { assignmentApi } from './assignment';

type AddTaskRequest = Partial<Task>;
export type UpdateTaskRequest = {
    data: Partial<Task>;
    assignments?: Array<Partial<Assigment>>;
};
type TasksResponse = Record<UniqueIdentifier, Task>;

type UpdateTasksRequest = Array<{
    id: UniqueIdentifier;
    section: UniqueIdentifier | null;
    index?: number;
}>;

type AddSubtaskRequest = {
    id: UniqueIdentifier;
    data: Partial<Task>;
};

export const taskApi = assignmentApi.enhanceEndpoints({ addTagTypes: ['task'] }).injectEndpoints({
    endpoints: (builder) => ({
        getTasks: builder.query<Record<UniqueIdentifier, Task>, void>({
            query: () => '/tasks',
            providesTags: (results) => [...providesKeys(Object.keys(results || {}), 'task')],
        }),
        addTask: builder.mutation<
            Task,
            Partial<Task & { assignments: UniqueIdentifier[]; subtasks: Partial<Task>[] }>
        >({
            query: ({ assignments, subtasks, ...data }) => ({
                url: `/tasks`,
                method: 'POST',
                body: {
                    data,
                    assignments,
                    subtasks,
                },
            }),
            invalidatesTags: ['task', 'assigment'],
        }),
        addSubtask: builder.mutation<Task, AddSubtaskRequest>({
            query: ({ id, data }) => ({
                url: `/tasks/${id}`,
                method: 'POST',
                body: {
                    data,
                },
            }),
            invalidatesTags: ['task'],
        }),
        updateTask: builder.mutation<
            Task,
            Partial<Task> & {
                id: UniqueIdentifier;
                assignments?: Partial<Assigment>[];
                subtasks?: Partial<Task>[];
            }
        >({
            query: ({ id, assignments, subtasks, ...data }) => ({
                url: `/tasks/${id}`,
                method: 'PUT',
                body: {
                    data,
                    assignments,
                    subtasks,
                },
            }),
            invalidatesTags: ['task', 'assigment'],
        }),

        deleteTask: builder.mutation<Task, UniqueIdentifier>({
            query: (data) => ({
                url: `/tasks/${data}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['task'],
        }),
        updateTasks: builder.mutation<Record<UniqueIdentifier, Task>, UpdateTasksRequest>({
            query: (data) => ({
                url: '/tasks',
                method: 'PUT',
                body: {
                    data,
                },
            }),
            invalidatesTags: ['task'],
        }),
    }),
});

export const {
    useGetTasksQuery,
    useAddTaskMutation,
    useAddSubtaskMutation,
    useUpdateTaskMutation,
    useDeleteTaskMutation,
    useUpdateTasksMutation,
} = taskApi;
