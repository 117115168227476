import { UniqueIdentifier } from '@dnd-kit/core';
import { createSelector, createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../.';
import { settingsApi } from '../api/settings';
import notifications from './notifications';

export type Themes = 'dark' | 'bright';

export type NotificationsSettings = {
    id: UniqueIdentifier;
    task_assigned: boolean;
    task_unassigned: boolean;
    task_completed: boolean;
    task_uncompleted: boolean;
    collaboration_sent: boolean;
    collaboration_accepted: boolean;
    collaboration_recived: boolean;
    collaboration_rejected: boolean;
    collaboration_removed: boolean;
    message_mention: boolean;
    message_replay: boolean;
    //project_deleted: boolean;
};

export type Settings = {
    theme: 'dark' | 'light';
    notifications: NotificationsSettings;
};

const slice = createSlice({
    name: 'settings',
    initialState: {
        theme: 'light',
    } as Settings,
    extraReducers: (builder) => {
        builder.addMatcher(
            settingsApi.endpoints.getUserSettings.matchFulfilled,
            (state, { payload }) => payload
        );
        builder.addMatcher(settingsApi.endpoints.updateUserSettings.matchPending, (state, { meta }) => {
            const args = meta.arg.originalArgs;
            return {
                ...state,
                ...args,
            };
        });
        builder.addMatcher(
            settingsApi.endpoints.updateNotificationsSettings.matchPending,
            (state, { meta }) => {
                state.notifications = { ...state.notifications, ...meta.arg.originalArgs };
            }
        );
    },
    reducers: {},
});

export default slice.reducer;
export const {} = slice.actions;

export const getThemeSelector = createSelector(
    (state: RootState) => state.settings,
    (settings) => settings.theme
);
