import type { Active, DragEndEvent, DragMoveEvent, DragOverEvent, DragStartEvent, Over } from '@dnd-kit/core';

//export const dndType = (e: Active | Over | null) => e?.data?.current?.type;

export const dndType = (
    e: DragMoveEvent | DragOverEvent | DragEndEvent,
    active: 'project' | 'section' | 'task',
    over: 'project' | 'section' | 'task' | undefined | string
) => e?.active?.data?.current?.type === active && e?.over?.data?.current?.type === over;

export const arrayInsert = <T>(array: T[], index: number, item: any): T[] => {
    let newArray = array;
    newArray.splice(index, 0, item);
    return newArray;
};

export const arrayRemove = <T>(array: T[], index: number): T[] => {
    let newArray = array;
    newArray.splice(index, 1);
    return newArray;
};


export const handleDnd = (e: DragMoveEvent | DragOverEvent | DragEndEvent) => {
    
}