const imageMimeTypes = [
    'image/aces',
    'image/avci',
    'image/avcs',
    'image/avif',
    'image/bmp',
    'image/cgm',
    'image/dicom-rle',
    'image/emf',
    'image/example',
    'image/fits',
    'image/g3fax',
    'image/gif',
    'image/heic',
    'image/heic-sequence',
    'image/heif',
    'image/heif-sequence',
    'image/hej2k',
    'image/hsj2',
    'image/jls',
    'image/jpeg',
    'image/jp2',
    'image/jph',
    'image/jphc',
    'image/jpm',
    'image/jpx',
    'image/jxr',
    'image/jxrA',
    'image/jxrS',
    'image/jxs',
    'image/jxsc',
    'image/jxsi',
    'image/jxss',
    'image/ktx',
    'image/ktx2',
    'image/naplps',
    'image/png',
    'image/prs.btif',
    'image/prs.pti',
    'image/pwg-raster',
    'image/svg+xml',
    'image/t38',
    'image/tiff',
    'image/tiff-fx',
    'image/wmf',
    'image/emf',
    'image/wmf',
];

export const isImage = (type: string) => imageMimeTypes.includes(type);
