import { LoadingButton } from '@mui/lab';
import {
    Alert,
    ClickAwayListener,
    Collapse,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageDialog } from '../../components/containers/DialogPage';
import { Logo } from '../../components/Logo';
import { TextLink } from '../../components/TextLink';
import { ValidationError } from '../../store/api';
import { RegisterRequest, useRegisterMutation } from '../../store/api';
import { Layout } from '../../components/containers/Layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTypedDispatch, useTypedSelector } from '../../store/hooks';
import { logout } from '../../store/slices/user';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { isEmpty } from 'lodash-es';

interface IRegister {}

interface IRegisterForm {
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    password: string;
    password2: string;
    avatar: null;
}

const validationSchema = Yup.object().shape({
    firstname: Yup.string()
        .min(1, 'Firstname is too short')
        .max(24, 'Firstname is too long')
        .required('Firstname is required'),
    lastname: Yup.string()
        .min(1, 'Lastname is too short')
        .max(24, 'Lastname is too long')
        .required('Lastname is required'),
    username: Yup.string()
        .min(2, 'Username is too short')
        .max(24, 'Username is too long')
        .required('Username is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
        .min(8, 'Password is too short')
        .max(24, 'Password is too long')
        .required('Password is required'),
    password2: Yup.string()
        .min(8, 'Password is too short')
        .max(24, 'Password is too long')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Password is required'),
    avatar: Yup.object().nullable(),
});

export const Register: React.FC<IRegister> = ({}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [register, { isLoading, isError, error }] = useRegisterMutation();

    const user = useTypedSelector((state) => state.user);
    const dispatch = useTypedDispatch();

    const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: {
            username: '',
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            password2: '',
            avatar: null,
        } as IRegisterForm,
        validationSchema,
        onSubmit: async (values) => {
            try {
                await register(values).unwrap();
                navigate(
                    location.state && (location.state as any).pathname
                        ? (location.state as any).pathname
                        : '/dashboard'
                );
            } catch (e) {
                console.log((e as ValidationError).data.error);
            }
        },
    });

    useEffect(() => {
        if (Boolean(user)) dispatch(logout());
    }, []);

    const handleTrySubmit = () => {
        if (isEmpty(touched) || !isEmpty(errors)) handleSubmit();
    };

    const [show, setShow] = useState({
        password: false,
        password2: false,
    });

    const handleShow = (key: 'password' | 'password2') =>
        setShow((state) => ({ ...state, [key]: !state[key] }));
    const handleHide = (key: 'password' | 'password2') => setShow((state) => ({ ...state, [key]: false }));

    return (
        <Layout title='Register'>
            <PageDialog>
                <Stack spacing={4}>
                    <Logo />
                    <Stack spacing={2}>
                        <Typography variant='h5'>Новый пользователь</Typography>
                        <TextField
                            label='Имя пользователя'
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='username'
                            error={touched.username && Boolean(errors.username)}
                            helperText={touched.username && errors.username}
                        />
                        <Stack spacing={2} direction='row'>
                            <TextField
                                label='Имя'
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name='firstname'
                                value={values.firstname}
                                error={touched.firstname && Boolean(errors.firstname)}
                                helperText={touched.firstname && errors.firstname}
                            />
                            <TextField
                                label='Фамилия'
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name='lastname'
                                value={values.lastname}
                                error={touched.lastname && Boolean(errors.lastname)}
                                helperText={touched.lastname && errors.lastname}
                            />
                        </Stack>
                        <TextField
                            label='Адрес электронной почты'
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='email'
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                        />
                        <ClickAwayListener onClickAway={() => handleHide('password')}>
                            <FormControl variant='outlined'>
                                <InputLabel htmlFor='password'>Новый пароль</InputLabel>
                                <OutlinedInput
                                    label='Новый пароль'
                                    id='password'
                                    type={show.password ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='password'
                                    error={touched.password && Boolean(errors.password)}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton onClick={() => handleShow('password')} tabIndex={-1}>
                                                {show.password ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    onSubmit={handleTrySubmit}
                                />
                                {touched.password && Boolean(errors.password) && (
                                    <FormHelperText error={touched.password && Boolean(errors.password)}>
                                        {touched.password && errors.password}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </ClickAwayListener>
                        <ClickAwayListener onClickAway={() => handleHide('password2')}>
                            <FormControl variant='outlined'>
                                <InputLabel htmlFor='password2'>Повторите пароль</InputLabel>
                                <OutlinedInput
                                    label='Повторите пароль'
                                    id='password2'
                                    type={show.password2 ? 'text' : 'password'}
                                    value={values.password2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='password2'
                                    error={touched.password2 && Boolean(errors.password2)}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton onClick={() => handleShow('password')} tabIndex={-1}>
                                                {show.password2 ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    onSubmit={handleTrySubmit}
                                />
                                {touched.password2 && Boolean(errors.password2) && (
                                    <FormHelperText error={touched.password2 && Boolean(errors.password2)}>
                                        {touched.password2 && errors.password2}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </ClickAwayListener>

                        <Collapse in={isError}>
                            <Alert title={(error as ValidationError)?.data.error.name}>
                                {(error as ValidationError)?.data.error.message}
                            </Alert>
                        </Collapse>

                        <LoadingButton
                            variant='contained'
                            size='large'
                            onClick={() => handleSubmit()}
                            loading={isLoading}
                            disabled={isEmpty(touched) || !isEmpty(errors)}
                        >
                            Регистрация
                        </LoadingButton>
                    </Stack>
                    <Divider />
                    <Stack direction='row' spacing={2} sx={{ py: 2, textAlign: 'center' }}>
                        <Typography>Уже зарегистрированы?</Typography>
                        <TextLink color='primary' href='/login'>
                            Войти
                        </TextLink>
                        <TextLink color='primary' href='/forgot-password'>
                            Восстановить пароль
                        </TextLink>
                    </Stack>
                </Stack>
            </PageDialog>
        </Layout>
    );
};
