import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
type SEOComponent = React.FC<{
    title?: string;
}>;

export const SEO: SEOComponent = ({ title }) => {
    return (
        <Helmet>
            <meta charSet='utf-8' />
            <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
            <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
            <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
            <link rel='manifest' href='/site.webmanifest' />
            <title>
                {title
                    ? `${process.env.REACT_APP_NAME.substring(0, 2).toUpperCase()} | ${title}`
                    : `${process.env.REACT_APP_NAME.toUpperCase()}`}
            </title>
        </Helmet>
    );
};
