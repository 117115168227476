import { UniqueIdentifier } from '@dnd-kit/core';
import {
    AnyAction,
    createSelector,
    createSlice,
    Dispatch,
    MiddlewareAPI,
    PayloadAction,
} from '@reduxjs/toolkit';

import { Middleware } from '@reduxjs/toolkit';
import type { RootState } from '..';

import { chatApi } from '../api/chat';
import { StrapiMedia } from '../api/upload';
import { getSocket, connect, disconnect } from './socket';

export type Message = {
    id: UniqueIdentifier;
    message: string;
    mentions: Array<UniqueIdentifier>;
    media: Array<StrapiMedia> | null;
    user: UniqueIdentifier;
    replay: UniqueIdentifier | null;
    chat: UniqueIdentifier;
    createdAt: string;
    updatedAt: string;
};

export type ChatState = {
    id: UniqueIdentifier | null;
    total: number | null;
    messages: Record<UniqueIdentifier, Message>;
};

const initialState: ChatState = {
    id: null,
    limit: null,
    offset: null,
    total: null,
    messages: {},
} as ChatState;

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        create: (state, { payload }: PayloadAction<Message>) => {
            state.messages = {
                ...state.messages,
                [payload.id]: payload,
            };
            state.total = state.total! + 1;
        },
        update: (state, { payload }: PayloadAction<Message>) => {
            state.messages[payload.id] = { ...state.messages[payload.id], ...payload };
        },
        delete: (state, { payload }: PayloadAction<Message>) => {
            delete state.messages[payload.id];
            state.total = state.total! - 1;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(chatApi.endpoints.getMessagesCount.matchPending, (state, { meta }) => ({
            ...state,
            id: meta.arg.originalArgs,
            total: null,
            messages: {},
        }));
        builder.addMatcher(chatApi.endpoints.getMessagesCount.matchFulfilled, (state, { payload }) => {
            state.total = payload;
        });
        builder.addMatcher(chatApi.endpoints.messages.matchPending, (state, { meta }) => {
            if (meta.arg.originalArgs.chat !== state.id) {
                return {
                    ...initialState,
                    id: meta.arg.originalArgs.chat,
                };
            }
        });
        builder.addMatcher(chatApi.endpoints.messages.matchFulfilled, (state, { payload }) => ({
            ...state,
            limit: payload.meta.limit,
            offset: payload.meta.offset,
            total: payload.meta.total,
            messages: {
                ...state.messages,
                ...payload.data,
            },
        }));
    },
});
export default slice.reducer;

export const chatMiddleware: Middleware = (store) => (next) => (action) => {
    const socket = getSocket();
    if (connect.match(action)) {
        socket?.on('message.create', (message: Message) => {
            const { chat } = store.getState() as RootState;
            if (message.chat === chat.id) {
                store.dispatch(slice.actions.create(message));
            }
        });
        socket?.on('message.update', (message: Message) => {
            const { chat } = store.getState() as RootState;
            if (message.chat === chat.id) {
                store.dispatch(slice.actions.update(message));
            }
        });
        socket?.on('message.delete', (message: Message) => {
            const { chat } = store.getState() as RootState;
            if (message.chat === chat.id) {
                store.dispatch(slice.actions.delete(message));
            }
        });
    }
    if (disconnect.match(action)) {
        socket?.off('message.create');
        socket?.off('message.update');
        socket?.off('message.delete');
    }

    return next(action);
};

export const messagesSelector = createSelector(
    (state: RootState) => state.chat.messages,
    (messages) => Object.values(messages) as Array<Message>
);

export const getMessageSelector = createSelector(
    (state: RootState) => state.chat.messages,
    (state: RootState, id: UniqueIdentifier | null | undefined) => (id ? id : null),
    (messages, id) => (id ? messages[id] : null)
);
