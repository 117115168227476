import { Delete, Edit, MoreHoriz, PersonAdd } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { Project } from '../../../store/slices/projects';

interface IProjectActions {
    project: Project;
}

export const ProjectActions: React.FC<IProjectActions> = ({ project }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const handleEdit = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        navigate({
            pathname: `/dashboard/projects/${project.id}/edit`,
            search: searchParams.toString()
        });
        setAnchorEl(null);
    };
    const handleShare = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        navigate({
            pathname: `/dashboard/projects/${project.id}/share`,
            search: searchParams.toString()
        });
        setAnchorEl(null);
    };

    const handleDelete = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        navigate({
            pathname: `/dashboard/projects/${project.id}/delete`,
            search: searchParams.toString()
        });
        setAnchorEl(null);
    };

    return (
        <>
            <Box>
                <IconButton onClick={handleOpen}>
                    <MoreHoriz />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                //transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                //anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiSvgIcon-root': {
                            width: 24,
                            height: 24,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                <MenuItem onClick={handleEdit}>
                    <Edit /> Редактировать проект
                </MenuItem>
                <MenuItem onClick={handleShare}>
                    <PersonAdd /> Добавить участника
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <Delete /> Удалить проект
                </MenuItem>
            </Menu>
        </>
    );
};
