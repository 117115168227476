import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LoadingDialog } from '../../../components/containers/LoadingDialog';
import { useTypedSelector } from '../../../store/hooks';
import { Section } from '../../../store/slices/sections';
import { LoadingButton } from '@mui/lab';
import { useEditSectionMutation } from '../../../store/api/section';

type EditSectionComponent = React.FC<{}>;

export const EditSection: EditSectionComponent = ({ }) => {
    const { projectId, sectionId } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const handleCancel = () => navigate({
        pathname: `/dashboard/projects/${projectId}`,
        search: searchParams.toString(),
    });
    const sections = useTypedSelector((state) => state.sections);
    const section = useMemo(() => (sectionId ? sections[sectionId] : null), [sections, sectionId]);

    return (
        <LoadingDialog loading={!Boolean(section)} onClose={handleCancel}>
            {section && <EditSectionDialog section={section} onCancel={handleCancel} />}
        </LoadingDialog>
    );
};

type EditSectionDialogComponent = React.FC<{ section: Section; onCancel: () => void }>;

const validationSchema = Yup.object().shape({
    title: Yup.string().max(120, 'Название слишком длинное'),
    type: Yup.string().oneOf(['none', 'todo', 'done', 'work']),
});

const EditSectionDialog: EditSectionDialogComponent = ({ section, onCancel }) => {
    const [editSection, { isLoading, isError, error }] = useEditSectionMutation();
    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue } =
        useFormik({
            initialValues: {
                ...section,
            },
            validationSchema,
            onSubmit: async (values) => {
                await editSection({ id: section.id, data: values });
                onCancel();
            },
        });

    const handleSelect = (e: SelectChangeEvent<'none' | 'todo' | 'done' | 'work'>) =>
        setFieldValue('type', e.target.value);

    return (
        <>
            <DialogTitle>Редактировать раздел</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack spacing={1}>
                    <TextField
                        //size='small'
                        label='Название раздела'
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='title'
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                    />
                    <FormControl variant='outlined' fullWidth>
                        <InputLabel id='section-type-label'>Тип секции</InputLabel>
                        <Select
                            labelId='section-type-label'
                            label='Тип секции'
                            value={values.type}
                            onChange={handleSelect}
                        >
                            <MenuItem value='none'>Нет</MenuItem>
                            <MenuItem value='todo'>Задачи</MenuItem>
                            <MenuItem value='done'>Сделано</MenuItem>
                            <MenuItem value='work'>В работе</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={onCancel}>Отменить</Button>
                <LoadingButton variant='contained' onClick={() => handleSubmit()} loading={isLoading}>
                    Редактировать
                </LoadingButton>
            </DialogActions>
        </>
    );
};
