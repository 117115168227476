import React from 'react';
import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { Box, Grid, Paper } from '@mui/material';
import { Task } from '../../../../store/slices/tasks';
import { UniqueIdentifier } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

type SectionWrapperComponent = React.FC<{
    id?: UniqueIdentifier;
    children?: React.ReactNode;
    type?: 'section' | 'new-section';
    disabled?: boolean;
}>;

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
    args.isSorting || args.wasDragging ? defaultAnimateLayoutChanges(args) : true;

export const SectionWrapper: SectionWrapperComponent = ({ id, children, type = 'section', disabled }) => {
    const { active, attributes, isDragging, listeners, setNodeRef, transition, transform } = useSortable({
        id: id ? `section-${id}` : type,
        data: {
            type: type,
            id: id,
        },
        disabled: disabled,
        animateLayoutChanges,
    });
    return (
        <Grid
            item
            ref={setNodeRef}
            style={{
                transition,
                transform: CSS.Translate.toString(transform),
                opacity: isDragging ? 0.5 : undefined,
            }}
            sx={{ p: 1 }}
            {...attributes}
            {...listeners}
        >
            <Paper sx={{ width: 350, p: 1 }} variant='outlined'>
                <Box sx={{ opacity: isDragging ? 0.0 : 1.0 }}>{children}</Box>
            </Paper>
        </Grid>
    );
};
