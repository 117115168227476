import { UniqueIdentifier } from '@dnd-kit/core';
import { ArrowBack, ArrowForward, Download } from '@mui/icons-material';
import { CircularProgress, Dialog, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import Image from 'mui-image';
import React from 'react';
import { StrapiMedia } from '../../store/api/upload';
import { StrapiMediaItem } from '../strapi/StrapiMediaItem';
import { saveAs } from 'file-saver';

type LightboxComponent = React.FC<{
    media: StrapiMedia[];
    current: StrapiMedia | null;
    next: StrapiMedia | null;
    previous: StrapiMedia | null;
    onNext: () => void;
    onPrevious: () => void;
    onClose: () => void;
    onClick: (id: UniqueIdentifier) => void;
    currentIndex: number;
}>;

export const Lightbox: LightboxComponent = ({
    media,
    current,
    next,
    previous,
    onNext,
    onPrevious,
    onClose,
    onClick,
    currentIndex,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Dialog
                open
                fullWidth
                maxWidth={false}
                scroll='body'
                onClose={onClose}
                PaperProps={{
                    sx: {
                        maxWidth: isMobile ? '96vw' : '90vw',
                        maxHeight: isMobile ? '96vh' : '90vh',
                        //position: 'relative',
                        touchAction: 'none',
                        background: 'transparent',
                        m: 0,
                        p: 0,
                    },
                    elevation: 0,
                    onClick: () => onClose(),
                }}
            >
                <Stack justifyContent='stretch' alignItems='stretch'>
                    {current ? (
                        <Image
                            src={`${process.env.REACT_APP_API_URL}${current?.url}`}
                            alt={current?.alternativeText || ''}
                            fit='contain'
                            style={{
                                maxWidth: isMobile ? '96vw' : '90vw',
                                maxHeight: isMobile ? '96vh' : '90vh',
                            }}
                        />
                    ) : (
                        <CircularProgress />
                    )}

                    {previous && (
                        <IconButton
                            sx={{
                                position: 'fixed',
                                left: (theme) => theme.spacing(isMobile ? 0 : 8),
                                top: '50%',
                                transform: 'translate(0%, -50%)',
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onPrevious();
                            }}
                        >
                            <ArrowBack />
                        </IconButton>
                    )}
                    {next && (
                        <IconButton
                            sx={{
                                position: 'fixed',
                                right: (theme) => theme.spacing(isMobile ? 0 : 8),
                                top: '50%',
                                transform: 'translate(0%, -50%)',
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onNext();
                            }}
                        >
                            <ArrowForward />
                        </IconButton>
                    )}
                </Stack>
                {(previous || next) && (
                    <Stack
                        direction='row'
                        spacing={0}
                        flexWrap='wrap'
                        justifyContent='center'
                        sx={{
                            position: 'fixed',
                            bottom: (theme) => theme.spacing(1),
                            left: 0,
                            right: 0,
                            //transform: 'translate(-50%, 0)',
                            //bgcolor: 'red',
                        }}
                    >
                        {media.map((item, i) => (
                            <StrapiMediaItem
                                key={item.id}
                                item={item}
                                onClick={onClick}
                                selected={currentIndex === i}
                            />
                        ))}
                    </Stack>
                )}
                {current && (
                    <Stack sx={{ top: 0, right: 0, position: 'absolute' }}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                saveAs(`${process.env.REACT_APP_API_URL}${current.url}`, current.name);
                            }}
                        >
                            <Download />
                        </IconButton>
                    </Stack>
                )}
            </Dialog>
        </>
    );
};
