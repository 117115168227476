import { UniqueIdentifier } from '@dnd-kit/core';
import { CircularProgress, Dialog, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Lightbox } from '../../../../../components/lightbox/Lightbox';
import { StrapiMedia, useGetMessageQuery } from '../../../../../store/api';

type ChatLightboxComponent = React.FC<{}>;

export const ChatLightbox: ChatLightboxComponent = ({ }) => {
    const { projectId, taskId, messageId, mediaId } = useParams();
    const { state } = useLocation();

    return (
        <>
            {projectId && taskId && messageId && mediaId ? (
                <>
                    {Boolean(state) ? (
                        <ChatLigtboxEngine
                            projectId={projectId}
                            taskId={taskId}
                            messageId={messageId}
                            mediaId={mediaId}
                            media={state}
                        />
                    ) : (
                        <ChatLightboxQuery
                            projectId={projectId}
                            taskId={taskId}
                            messageId={messageId}
                            mediaId={mediaId}
                        />
                    )}
                </>
            ) : (
                <></>
            )}
        </>
    );
};

const ChatLightboxQuery: React.FC<{
    projectId: string;
    taskId: string;
    messageId: string;
    mediaId: string;
}> = ({ projectId, taskId, messageId, mediaId }) => {
    const { data, isLoading, isError, error } = useGetMessageQuery(messageId);

    return (
        <>
            {!isLoading && data && data.media ? (
                <ChatLigtboxEngine
                    projectId={projectId}
                    taskId={taskId}
                    messageId={messageId}
                    mediaId={mediaId}
                    media={data.media}
                />
            ) : (
                <Dialog
                    open
                    fullScreen
                    fullWidth
                // PaperProps={{
                //     sx: {
                //         background: 'transparent',
                //         touchAction: 'none',
                //     },
                // }}
                >
                    <Stack
                        sx={{ position: 'absolute', width: '100%', heigth: '100%', zIndex: 100 }}
                        alignItems='center'
                        justifyContent='center'
                    >
                        <CircularProgress />
                    </Stack>
                </Dialog>
            )}
        </>
    );
};

type ChatLightboxEngineComponent = React.FC<{
    projectId: string;
    taskId: string;
    messageId: string;
    mediaId: string;
    media: StrapiMedia[];
}>;

const ChatLigtboxEngine: ChatLightboxEngineComponent = ({ projectId, taskId, messageId, mediaId, media }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const currentIndex = useMemo(
        () => media.findIndex(({ id }) => id === Number.parseInt(mediaId)),
        [media, mediaId]
    );
    const current = useMemo(() => (media ? media[currentIndex] : null), [mediaId]);
    const next = useMemo(
        () => (media && media.length > 1 ? media[(currentIndex + 1) % media.length] : null),
        [media, currentIndex]
    );
    const previous = useMemo(
        () => (media && media.length > 1 ? media[(currentIndex + media.length - 1) % media.length] : null),
        [media, currentIndex]
    );

    const handleNext = () =>
        navigate(
            {
                pathname: `/dashboard/projects/${projectId}/tasks/${taskId}/edit/messages/${messageId}/media/${next?.id}`,
                search: searchParams.toString()
            },
            { state: media }
        );

    const handlePrevious = () =>
        navigate(
            {
                pathname: `/dashboard/projects/${projectId}/tasks/${taskId}/edit/messages/${messageId}/media/${previous?.id}`,
                search: searchParams.toString()
            },
            { state: media }
        );

    const handleClick = (id: UniqueIdentifier) =>
        navigate({
            pathname: `/dashboard/projects/${projectId}/tasks/${taskId}/edit/messages/${messageId}/media/${id}`,
            search: searchParams.toString()
        }, {
            state: media,
        });

    const handleClose = () => navigate({
        pathname: `/dashboard/projects/${projectId}/tasks/${taskId}/edit`,
        search: searchParams.toString()
    });

    return (
        <Lightbox
            media={media}
            current={current}
            next={next}
            previous={previous}
            onNext={handleNext}
            onPrevious={handlePrevious}
            onClick={handleClick}
            onClose={handleClose}
            currentIndex={currentIndex}
        />
    );
};
