import { AttachFile } from '@mui/icons-material';
import {
    Avatar,
    Box,
    FormControl,
    FormControlLabel,
    Input,
    InputBase,
    InputLabel,
    Paper,
    Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { StrapiMedia, useUploadMutation } from '../../store/api';
import { UploadItemFile, UploadItemStrapi } from './UploadItems';

interface UploadComponent {
    size?: 'small' | 'medium' | 'large';
    media?: StrapiMedia[] | null;
    onUploaded: (media: StrapiMedia[]) => void;
    onDeleted: (item: StrapiMedia) => void;
    onClick?: (item: StrapiMedia) => void;
}

interface UploadHandle {
    reset: () => void;
}

export type UploadItem = {
    file: File;
    preview?: string;
    status: 'added' | 'loading' | 'success' | 'error';
};

export const Upload = React.forwardRef<UploadHandle, UploadComponent>(
    ({ media = [], onUploaded, onDeleted, onClick, size = 'small' }, ref) => {
        const [upload, { isLoading, isError, error }] = useUploadMutation();

        const [items, setItems] = useState<UploadItem[]>([]);

        const handleDrop = (files: File[]) => {
            setItems([
                ...items,
                ...(files
                    .filter(
                        (f) =>
                            !(
                                media?.some(({ name }) => f.name === name) ||
                                items?.some(({ file, status }) => f.name === file.name && status !== 'error')
                            )
                    )
                    .map((file) => ({
                        file,
                        preview: URL.createObjectURL(file),
                        status: 'added' as 'added',
                    })) as UploadItem[]),
            ]);
        };

        const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, isFocused } =
            useDropzone({
                onDrop: handleDrop,
            });

        const handleFileClick = ({ file, status }: UploadItem) => {
            if (status === 'error') {
                setItems((items) =>
                    items.map((item) =>
                        item.file.name === file.name ? { ...item, status: 'added' as 'added' } : item
                    )
                );
            }
        };

        const handleStrapiClick = (item: StrapiMedia) => onClick && onClick(item);
        const handleStrapiDelete = (item: StrapiMedia) => onDeleted(item);

        const handleUpload = async () => {
            let indices: number[] = [];
            const itemsToUpload = items.filter(({ status }, i) => {
                if (status === 'added') {
                    indices.push(i);
                    return true;
                }
                return false;
            });

            if (itemsToUpload.length > 0)
                try {
                    let data = new FormData();
                    itemsToUpload.forEach(({ file }) => data.append('files', file));

                    setItems(
                        (items) =>
                            items.map((item, i) =>
                                indices.includes(i) ? { ...item, status: 'loading' as 'loading' } : item
                            ) as UploadItem[]
                    );

                    let res = await upload(data).unwrap();

                    //console.log('res', res);
                    //console.log('items', items);

                    setItems((items) =>
                        items.filter(({ file }) => !res.some(({ name }) => file.name === name))
                    );

                    onUploaded(res);
                } catch (e) {
                    setItems(
                        (items) =>
                            items.map((item, i) =>
                                indices.includes(i) ? { ...item, status: 'error' as 'error' } : item
                            ) as UploadItem[]
                    );
                }
        };

        useEffect(() => {
            if (!isLoading) {
                handleUpload();
            }
        }, [items, media, isLoading]);

        return (
            <FormControl variant='outlined'>
                <Paper
                    {...getRootProps()}
                    variant='outlined'
                    sx={{ position: 'relative', minHeight: 60, background: 'none' }}
                >
                    <Stack
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            color: (theme) => theme.palette.text.disabled,
                        }}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <AttachFile />
                    </Stack>
                    <InputBase inputProps={{ ...getInputProps() }} sx={{ display: 'none' }} />
                    <Stack
                        direction='row'
                        flexWrap='wrap'
                        justifyContent='center'
                        alignItems='center'
                        sx={{ minHeight: 60 }}
                    >
                        {media?.map((item, i) => (
                            <UploadItemStrapi
                                item={item}
                                key={`media-${item.id}-i`}
                                onClick={handleStrapiClick}
                                onDelete={handleStrapiDelete}
                                size={size}
                            />
                        ))}
                        {items.map((item, i) => (
                            <UploadItemFile
                                item={item}
                                key={`uploading-${i}`}
                                onClick={handleFileClick}
                                size={size}
                            />
                        ))}
                    </Stack>
                </Paper>
            </FormControl>
        );
    }
);
