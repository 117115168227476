import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

type ConfirmDialogComponent = React.FC<{
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    title?: string;
    message?: string;
    cancel?: string;
    confirm?: string;
}>;

export const ConfirmDialog: ConfirmDialogComponent = ({
    open,
    onCancel,
    onConfirm,
    title = 'Отменить изменения',
    message = 'Изменения которые вы совершили не будут сохранены, продолжить?',
    cancel = 'Отментиь',
    confirm = 'Продолжить',
}) => {
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color='secondary'>
                    {cancel}
                </Button>
                <Button onClick={onConfirm} variant='contained' color='primary'>
                    {confirm}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
