import { Data, UniqueIdentifier } from '@dnd-kit/core';
import { createSelector, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../.';
import { taskApi } from '../api/task';
import { StrapiMedia } from '../api/upload';
import { Assigment } from './assignments';

import { Middleware } from 'redux';
import { getSocket, connect, disconnect } from './socket';

type SectionIdentifier = UniqueIdentifier;

export type Task = {
    id: UniqueIdentifier;
    project: UniqueIdentifier;
    index: number;
    title: string;
    description: string;
    completed: boolean;
    media: Array<StrapiMedia> | null;
    //subtasks: Array<UniqueIdentifier> | null;
    due: Date | null;
    section: UniqueIdentifier | null;
    task: UniqueIdentifier | null;
    //assignments: Array<UniqueIdentifier> | null;
    chat: UniqueIdentifier;

    tasks?: number;
    tasksCompleted?: number;
    messages?: number;
    user?: UniqueIdentifier;

    color: 'disabled' | 'action' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' | null;

    createdAt: string;
    updatedAt: string;
};

// export type TaskWithAssignments = Omit<Task, 'assignments'> & {
//     assignments: Array<Assigment> | null;
// };

const slice = createSlice({
    name: 'tasks',
    initialState: {} as Record<UniqueIdentifier, Task>,
    extraReducers: (builder) => {
        builder.addMatcher(taskApi.endpoints.getTasks.matchFulfilled, (state, { payload }) => {
            return payload;
        });
        builder.addMatcher(taskApi.endpoints.updateTask.matchPending, (state, { meta }) => {
            const { id, assignments, subtasks, ...rest } = meta.arg.originalArgs;
            if (id) {
                state[id] = { ...state[id], ...rest };
            }
        });
    },
    reducers: {
        create: (state, { payload }: PayloadAction<Task>) => ({ ...state, [payload.id]: payload }),
        update: (state, { payload }: PayloadAction<Task>) => {
            state[payload.id] = { ...state[payload.id], ...payload };
        },
        delete: (state, { payload }: PayloadAction<Task>) => {
            delete state[payload.id];
        },
        moveTasks: (state, { payload }: PayloadAction<Task[]>) => {
            payload.forEach((task) => {
                state[task.id] = task;
            });
        },
    },
});

export const { moveTasks } = slice.actions;
export default slice.reducer;

export const tasksMiddleware: Middleware = (store) => (next) => (action) => {
    const socket = getSocket();
    if (connect.match(action)) {
        socket?.on('task.create', (task: Task) => {
            const projects = Object.keys((store.getState() as RootState).projects);
            if (projects.includes(task.project.toString())) {
                store.dispatch(slice.actions.create(task));
            }
        });
        socket?.on('task.update', (task: Task) => {
            const projects = Object.keys((store.getState() as RootState).projects);
            if (projects.includes(task.project.toString())) {
                store.dispatch(slice.actions.update(task));
            }
        });
        socket?.on('task.delete', (task: Task) => {
            const projects = Object.keys((store.getState() as RootState).projects);
            if (projects.includes(task.project.toString())) {
                store.dispatch(slice.actions.delete(task));
            }
        });
    }
    if (disconnect.match(action)) {
        socket?.off('task.create');
        socket?.off('task.update');
        socket?.off('task.delete');
    }

    return next(action);
};

export const taskSelector = createSelector(
    (state: RootState) => state.tasks,
    (state: RootState, taskId: UniqueIdentifier | undefined | null) => taskId,
    (tasks, taskId) => (taskId ? tasks[taskId] : null)
);

export const subtasksSelector = createSelector(
    (state: RootState) => state.tasks,
    (state: RootState, taskId: UniqueIdentifier | undefined | null) => taskId,
    (tasks, taskId) =>
        taskId
            ? Object.values(tasks)
                  .filter(({ task }) => task == taskId)
                  .sort((a, b) => (a.id as number) - (b.id as number))
            : ([] as Task[])
);
