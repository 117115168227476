import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash-es';
import { useInviteUserMutation } from '../../../store/api/user';
import { useSnackbar } from 'notistack';

type InvitesComponent = React.FC<{}>;

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Некорректный адрес электронной почты')
        .required('Адрес электронной почты должен быть указан'),
});

export const Invites: InvitesComponent = ({}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [inviteUser, { isLoading }] = useInviteUserMutation();
    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            email: '',
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema,
        onSubmit: async (values) => {
            try {
                const { success } = await inviteUser(values).unwrap();
                enqueueSnackbar(success, { variant: 'success' });
                resetForm();
            } catch (e: any) {
                enqueueSnackbar(e.data.error.message ? e.data.error.message : `Ошибка создания приглашения`, {
                    variant: 'error',
                });
            }
        },
    });

    return (
        <Stack spacing={2}>
            <Typography fontWeight='bold'>Пригласить пользователя</Typography>
            <TextField
                label='Адрес электронной почты'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                name='email'
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
            />
            <Box>
                <LoadingButton
                    variant='contained'
                    onClick={() => handleSubmit()}
                    disabled={!(values.email.length > 0) || !isEmpty(errors)}
                    loading={isLoading}
                >
                    Пригласить
                </LoadingButton>
            </Box>
        </Stack>
    );
};
