import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { Badge, Box, CircularProgress, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';

import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import useMeasure from 'react-use-measure';
import { Virtuoso } from 'react-virtuoso';
import { UserAvatar } from '../../../components/UserAvatar';
import {
    useNotificationsQuery,
    useReadAllNotificationsMutation,
    useUpdateNotificationMutation,
} from '../../../store/api';
import { useTypedDispatch, useTypedSelector } from '../../../store/hooks';
import { allCollaboratorsSelector } from '../../../store/slices/collaborators';
import { INotification } from '../../../store/slices/notifications';

import { VariableSizeList as List } from 'react-window';

interface NotificationsProps {}

const limit = 20;
export const Notifications: React.FC<NotificationsProps> = ({}) => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const [offset, setOffset] = useState(0);

    const current = useNotificationsQuery(
        { offset: offset, limit },
        { refetchOnFocus: true, refetchOnMountOrArgChange: true, refetchOnReconnect: true }
    );
    //const next = useNotificationsQuery({ offset: offset + limit, limit });

    const data = useTypedSelector((state) => Object.values(state.notifications.data).reverse());
    const total = useTypedSelector((state) => state.notifications.total);
    const unread = useTypedSelector((state) => state.notifications.unread);

    const collaborators = useTypedSelector((state) => allCollaboratorsSelector(state));

    const [updateNotification] = useUpdateNotificationMutation();
    const [readAllNotifications] = useReadAllNotificationsMutation();

    //console.log(collaborators);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [ref, { height }] = useMeasure();

    const handleOpen = (e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);
    const handleClose = async () => {
        setAnchorEl(null);
        await readAllNotifications();
    };

    const handleIncrement = () => setOffset(Math.min(total, data.length));

    const Row = useMemo(
        () => (index: number, notification: INotification) => {
            const handleClick = () => {
                if (unread) updateNotification({ id: notification.id, unread: false });
                notification.action && navigate(notification.action);
            };

            return (
                <MenuItem sx={{ px: 1, m: 0 }} onClick={handleClick} selected={notification.unread}>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <UserAvatar user={collaborators[notification.from]} />
                        <Stack>
                            <Typography
                                variant='caption'
                                sx={{ whiteSpace: 'pre-wrap', wordBreak: 'normal' }}
                            >
                                {notification.message}
                            </Typography>
                        </Stack>
                    </Stack>
                </MenuItem>
            );
        },
        [data]
    );

    const Footer = useMemo(
        () => () =>
            (
                <Stack justifyContent='center' alignItems='center' sx={{ py: 2 }}>
                    {total !== data.length && <CircularProgress />}
                </Stack>
            ),
        [total, data]
    );

    return (
        <>
            <IconButton onClick={handleOpen}>
                <Badge badgeContent={unread} color='primary'>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                ref={ref}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                id='notifications-menu'
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                keepMounted
                transitionDuration={0}
            >
                <Box sx={{ height: 400, width: 400, m: 0, p: 0, position: 'relative' }}>
                    <Virtuoso
                        data={data}
                        style={{ height: 400, width: 400 }}
                        itemContent={Row}
                        totalCount={total}
                        initialTopMostItemIndex={0}
                        initialItemCount={data.length}
                        atBottomStateChange={handleIncrement}
                        overscan={100}
                        components={{ Footer }}
                    />
                </Box>
            </Menu>
        </>
    );
};
