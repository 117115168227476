import { CircularProgress, Stack } from '@mui/material';

import React from 'react';

interface ILoading {}

export const Loading: React.FC<ILoading> = ({}) => {
    return (
        <Stack sx={{ height: '100%', background: 'red' }} justifyContent='center' alignItems='center'>
            <CircularProgress />
        </Stack>
    );
};
