import { UniqueIdentifier } from '@dnd-kit/core';
import { createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../.';
import { sectionApi } from '../api/section';
import settings from './settings';
import { getSocket, connect, disconnect } from './socket';

export type SectionType = 'none' | 'todo' | 'done' | 'work';

export type Section = {
    id: UniqueIdentifier;
    project: UniqueIdentifier;
    index: number;
    title: string;
    type: SectionType;
};

const slice = createSlice({
    name: 'sections',
    initialState: {} as Record<UniqueIdentifier, Section>,
    extraReducers: (builder) => {
        builder.addMatcher(sectionApi.endpoints.getSections.matchFulfilled, (state, { payload }) => {
            return payload;
        });
        builder.addMatcher(sectionApi.endpoints.updateSections.matchPending, (state, action) => {
            (action.meta.arg.originalArgs as Array<{ id: UniqueIdentifier; index: number }>).forEach(
                ({ id, index }) => {
                    state[id] = { ...state[id], index };
                }
            );
        });
    },
    reducers: {
        create: (state, { payload }: PayloadAction<Section>) => ({ ...state, [payload.id]: payload }),
        update: (state, { payload }: PayloadAction<Section>) => {
            state[payload.id] = { ...state[payload.id], ...payload };
        },
        delete: (state, { payload }: PayloadAction<Section>) => {
            delete state[payload.id];
        },
        setSections: (state, { payload }: PayloadAction<Section[]>) => {
            return payload.forEach((section) => (state[section.id] = section));
        },
    },
});

export const { setSections } = slice.actions;
export default slice.reducer;

export const sectionsMiddleware: Middleware = (store) => (next) => (action) => {
    const socket = getSocket();
    if (connect.match(action)) {
        socket?.on('section.create', (section: Section) => {
            const projects = Object.keys((store.getState() as RootState).projects);
            if (projects.includes(section.project.toString())) {
                store.dispatch(slice.actions.create(section));
            }
        });
        socket?.on('section.update', (section: Section) => {
            const projects = Object.keys((store.getState() as RootState).projects);
            if (projects.includes(section.project.toString())) {
                store.dispatch(slice.actions.update(section));
            }
        });
        socket?.on('section.delete', (section: Section) => {
            const projects = Object.keys((store.getState() as RootState).projects);
            if (projects.includes(section.project.toString())) {
                store.dispatch(slice.actions.delete(section));
            }
        });
    }
    if (disconnect.match(action)) {
        socket?.off('section.create');
        socket?.off('section.update');
        socket?.off('section.delete');
    }

    return next(action);
};
