import { Stack, Typography } from '@mui/material';
import React from 'react';

interface WelcomeProps {}

export const Welcome: React.FC<WelcomeProps> = ({}) => {
    return (
        <Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
            <Typography variant='h2'>Welcome</Typography>
        </Stack>
    );
};
