import { Button, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { Wave } from '../../components/wave/Wave';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../../store/hooks';

interface HomeProps {}

export const Home: React.FC<HomeProps> = ({}) => {
    const navigate = useNavigate();
    
    const user = useTypedSelector((state) => state.user);
    
    if (user.jwt) return <Navigate to='/dashboard' />;

    return (
        <Stack
            sx={{
                height: '100%',
                //background: ({ palette }) => palette.primary.main
                background: 'url(wave.svg)',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative',
            }}
            justifyContent='center'
            alignItems='center'
        >
            <Wave />
            <Typography variant='h1' sx={{ color: 'white', fontWeight: 'bold' }}>
                SISTEMA
            </Typography>
            <Stack direction='row' spacing={2} justifyContent='center'>
                <Button color='secondary' variant='contained' onClick={() => navigate('/login')}>
                    LOGIN
                </Button>
                <Button color='secondary' variant='contained' onClick={() => navigate('/register')}>
                    REGISTER
                </Button>
            </Stack>
        </Stack>
    );
};
