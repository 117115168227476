import { Avatar, SxProps, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { Collaborator } from '../store/slices/collaborators';

type UserAvatarComponent = React.FC<{
    sx?: SxProps;
    user: Partial<Collaborator>;
    size?: number;
    tooltip?: Boolean;
}>;

export const UserAvatar: UserAvatarComponent = ({ user, size = 5, sx, tooltip }) => {
    const { avatar, hasAvatar } = useMemo(() => {
        let hasAvatar = true;
        let avatar = user.avatar?.thumbnail
            ? user.avatar?.thumbnail
            : user.avatar?.small
            ? user.avatar?.small
            : user.avatar?.medium
            ? user.avatar?.medium
            : user.avatar?.large
            ? user.avatar?.large
            : user.avatar?.url
            ? user.avatar?.url
            : null;

        if (!avatar) {
            avatar = `${user.firstname?.substring(0, 1).toUpperCase()}${user.lastname
                ?.substring(0, 1)
                .toUpperCase()}`;
            hasAvatar = false;
        }

        return {
            avatar,
            hasAvatar,
        };
    }, [user]);

    const AvatarComponent = useMemo(
        () =>
            hasAvatar ? (
                <Avatar
                    alt={`${user.firstname} ${user.lastname}`}
                    src={`${process.env.REACT_APP_API_URL}${avatar}`}
                    sx={{
                        height: (theme) => theme.spacing(size),
                        width: (theme) => theme.spacing(size),
                        ...sx,
                    }}
                />
            ) : (
                <Avatar
                    sx={{
                        height: (theme) => theme.spacing(size),
                        width: (theme) => theme.spacing(size),
                        fontSize: (20 / 5) * size,
                        ...sx,
                    }}
                >
                    {avatar}
                </Avatar>
            ),
        [user, size, sx, avatar, hasAvatar]
    );

    if (tooltip) return <Tooltip title={`@${user.username}`}>{AvatarComponent}</Tooltip>;

    return AvatarComponent;
};
