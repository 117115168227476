import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { Box, Collapse, Paper, Theme } from '@mui/material';
import Color from 'color';
import React, { useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DashboardContext, dashboardContext } from '../../dashboardContext';



type TaskWrapperComponent = React.FC<{
    id?: UniqueIdentifier;
    children?: React.ReactNode;
    clone?: boolean;
    disabled?: boolean;
    type?: 'task' | 'new-task';
    project?: UniqueIdentifier;
    section?: UniqueIdentifier;
    over?: Boolean;
    assigned?: Boolean;
    completed?: Boolean;
    color: 'disabled' | 'action' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' | null;
    visibility?: null | string;
}>;

export const TaskWrapper: TaskWrapperComponent = ({
    id,
    children,
    clone,
    disabled,
    type = 'task',
    project,
    section,
    over,
    assigned,
    completed,
    color,
    visibility
}) => {
    const [searchParams] = useSearchParams()
    const { colors } = useContext(dashboardContext) as DashboardContext;
    const navigate = useNavigate();

    const visible = Boolean(!(!assigned && visibility));

    const { setNodeRef, listeners, isDragging, isSorting, overIndex, transform, transition } = useSortable({
        id: id ? `task-${id}` : type,
        data: {
            id: id,
            type: type,
        },
        disabled: disabled || !visible,
    });

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (project && section && id && visible) navigate({ pathname: `/dashboard/projects/${project}/tasks/${id}/edit`, search: searchParams.toString() });
    };

    const borderColor = (theme: Theme) => {
        if (assigned && !completed) return color ? colors[color] : theme.palette.text.disabled;
        else return color && color !== 'disabled' ? Color(colors[color]).alpha(0.4).hexa() : undefined;
    };

    return (
        <Paper
            ref={setNodeRef}
            sx={{
                display: 'block',
                p: 1,
                backgroundColor: !clone && (isDragging || over) ? 'rgba(128,128,128,0.1)' : undefined,

                borderColor,

                '&:hover': {
                    backgroundColor: !clone && visible ? 'rgba(128,128,128,0.1)' : undefined,
                },
                '&:active': {
                    bgcolor: visible ? 'rgba(182,182,182,0.1)' : undefined,
                },
                touchAction: 'manipulation',
                position: 'relative',
            }}
            variant='outlined'
            {...listeners}
            onClick={handleClick}
        >
            <Box sx={{ opacity: isDragging ? 0 : 1 }}>
                <Collapse in={visible}>{children}</Collapse>
            </Box>
        </Paper>
    );
};
