import { configureStore } from '@reduxjs/toolkit';
import { api } from './api';
import { persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import assignmentsReducer, { assignmentsMiddleware } from './slices/assignments';
import chatReducer, { chatMiddleware } from './slices/chat';
import collaboratorsReducer from './slices/collaborators';
import projectsReducer, { projectsMiddleware } from './slices/projects';
import sectionsReducer, { sectionsMiddleware } from './slices/sections';
import settingsReducer from './slices/settings';
import socketReducer, { socketMiddleware } from './slices/socket';
import tasksReducer, { tasksMiddleware } from './slices/tasks';
import treeReducer, { treeMiddleware } from './slices/tree';
import userReducer from './slices/user';
import notificationsReducer, { notificationsMiddleware } from './slices/notifications';

const userPersistReducer = persistReducer({ key: 'user', storage, whitelist: ['jwt'] }, userReducer);
const settingsPersistedReducer = persistReducer({ key: 'settings', storage }, settingsReducer);

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        socket: socketReducer,
        user: userPersistReducer,
        tree: treeReducer,
        projects: projectsReducer,
        sections: sectionsReducer,
        tasks: tasksReducer,
        collaborators: collaboratorsReducer,
        assignments: assignmentsReducer,
        chat: chatReducer,
        notifications: notificationsReducer,
        settings: settingsPersistedReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(
            api.middleware,
            socketMiddleware,
            chatMiddleware,
            tasksMiddleware,
            sectionsMiddleware,
            projectsMiddleware,
            treeMiddleware,
            assignmentsMiddleware,
            notificationsMiddleware
        ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
