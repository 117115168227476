import { RadioButtonUnchecked, TaskAlt } from '@mui/icons-material';
import { Checkbox, CheckboxProps, CircularProgress, Tooltip } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import { useUpdateTaskMutation } from '../../../../../store/api';
import { Task } from '../../../../../store/slices';
import { EditTaskTask } from '../EditTaskDialog';

type EditTaskSubtaskCompletedComponent = React.FC<
    CheckboxProps & {
        subtask: Partial<Task>;
        formik: FormikProps<EditTaskTask>;
        index: number;
    }
>;

export const EditTaskSubtaskCompleted: EditTaskSubtaskCompletedComponent = ({
    subtask,
    formik,
    index,
    ...props
}) => {
    const [updateTask, { isLoading, isError, error }] = useUpdateTaskMutation();

    const handleTaskCompleted = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (subtask.id) await updateTask({ id: subtask.id!, ...subtask, completed: !subtask.completed });
        else
            formik.setFieldValue(
                'subtasks',
                formik.values.subtasks.map((subtask, i) =>
                    i === index ? { ...subtask, completed: !subtask.completed } : subtask
                )
            );
    };

    return (
        <Tooltip title={`Задача ${subtask.completed ? `не выполнена` : `выполнена`}`}>
            <Checkbox
                onClick={handleTaskCompleted}
                checked={subtask.completed}
                icon={isLoading ? <CircularProgress size={24} /> : <RadioButtonUnchecked />}
                checkedIcon={isLoading ? <CircularProgress size={24} /> : <TaskAlt />}
                {...props}
            />
        </Tooltip>
    );
};
