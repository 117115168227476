import React, { useEffect, useState } from 'react';
import { a, config, useSpring } from '@react-spring/web';
import { Box } from '@mui/material';

interface IWave {}

//https://www.softr.io/tools/svg-wave-generator
const ds = [
    'M0,441L60,392C120,343,240,245,360,212.3C480,180,600,212,720,261.3C840,310,960,376,1080,343C1200,310,1320,180,1440,106.2C1560,33,1680,16,1800,81.7C1920,147,2040,294,2160,326.7C2280,359,2400,278,2520,212.3C2640,147,2760,98,2880,89.8C3000,82,3120,114,3240,163.3C3360,212,3480,278,3600,294C3720,310,3840,278,3960,236.8C4080,196,4200,147,4320,106.2C4440,65,4560,33,4680,40.8C4800,49,4920,98,5040,98C5160,98,5280,49,5400,98C5520,147,5640,294,5760,351.2C5880,408,6000,376,6120,318.5C6240,261,6360,180,6480,147C6600,114,6720,131,6840,179.7C6960,229,7080,310,7200,351.2C7320,392,7440,392,7560,367.5C7680,343,7800,294,7920,261.3C8040,229,8160,212,8280,212.3C8400,212,8520,229,8580,236.8L8640,245L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z',
    'M0,392L60,343C120,294,240,196,360,147C480,98,600,98,720,122.5C840,147,960,196,1080,220.5C1200,245,1320,245,1440,277.7C1560,310,1680,376,1800,351.2C1920,327,2040,212,2160,196C2280,180,2400,261,2520,318.5C2640,376,2760,408,2880,351.2C3000,294,3120,147,3240,98C3360,49,3480,98,3600,163.3C3720,229,3840,310,3960,351.2C4080,392,4200,392,4320,383.8C4440,376,4560,359,4680,351.2C4800,343,4920,343,5040,351.2C5160,359,5280,376,5400,359.3C5520,343,5640,294,5760,245C5880,196,6000,147,6120,122.5C6240,98,6360,98,6480,106.2C6600,114,6720,131,6840,187.8C6960,245,7080,343,7200,334.8C7320,327,7440,212,7560,196C7680,180,7800,261,7920,294C8040,327,8160,310,8280,261.3C8400,212,8520,131,8580,89.8L8640,49L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z',
    'M0,392L60,326.7C120,261,240,131,360,81.7C480,33,600,65,720,81.7C840,98,960,98,1080,155.2C1200,212,1320,327,1440,318.5C1560,310,1680,180,1800,138.8C1920,98,2040,147,2160,196C2280,245,2400,294,2520,285.8C2640,278,2760,212,2880,155.2C3000,98,3120,49,3240,73.5C3360,98,3480,196,3600,261.3C3720,327,3840,359,3960,367.5C4080,376,4200,359,4320,302.2C4440,245,4560,147,4680,163.3C4800,180,4920,310,5040,375.7C5160,441,5280,441,5400,375.7C5520,310,5640,180,5760,138.8C5880,98,6000,147,6120,138.8C6240,131,6360,65,6480,73.5C6600,82,6720,163,6840,196C6960,229,7080,212,7200,196C7320,180,7440,163,7560,130.7C7680,98,7800,49,7920,81.7C8040,114,8160,229,8280,245C8400,261,8520,180,8580,138.8L8640,98L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z',
    'M0,98L60,155.2C120,212,240,327,360,318.5C480,310,600,180,720,155.2C840,131,960,212,1080,269.5C1200,327,1320,359,1440,359.3C1560,359,1680,327,1800,269.5C1920,212,2040,131,2160,138.8C2280,147,2400,245,2520,294C2640,343,2760,343,2880,294C3000,245,3120,147,3240,98C3360,49,3480,49,3600,106.2C3720,163,3840,278,3960,294C4080,310,4200,229,4320,220.5C4440,212,4560,278,4680,253.2C4800,229,4920,114,5040,65.3C5160,16,5280,33,5400,98C5520,163,5640,278,5760,310.3C5880,343,6000,294,6120,253.2C6240,212,6360,180,6480,179.7C6600,180,6720,212,6840,228.7C6960,245,7080,245,7200,277.7C7320,310,7440,376,7560,367.5C7680,359,7800,278,7920,245C8040,212,8160,229,8280,228.7C8400,229,8520,212,8580,204.2L8640,196L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z',
    'M0,441L60,424.7C120,408,240,376,360,367.5C480,359,600,376,720,383.8C840,392,960,392,1080,375.7C1200,359,1320,327,1440,285.8C1560,245,1680,196,1800,187.8C1920,180,2040,212,2160,187.8C2280,163,2400,82,2520,81.7C2640,82,2760,163,2880,204.2C3000,245,3120,245,3240,220.5C3360,196,3480,147,3600,114.3C3720,82,3840,65,3960,49C4080,33,4200,16,4320,16.3C4440,16,4560,33,4680,57.2C4800,82,4920,114,5040,114.3C5160,114,5280,82,5400,89.8C5520,98,5640,147,5760,138.8C5880,131,6000,65,6120,98C6240,131,6360,261,6480,326.7C6600,392,6720,392,6840,359.3C6960,327,7080,261,7200,220.5C7320,180,7440,163,7560,163.3C7680,163,7800,180,7920,163.3C8040,147,8160,98,8280,89.8C8400,82,8520,114,8580,130.7L8640,147L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z',
    'M0,98L60,98C120,98,240,98,360,155.2C480,212,600,327,720,334.8C840,343,960,245,1080,212.3C1200,180,1320,212,1440,212.3C1560,212,1680,180,1800,147C1920,114,2040,82,2160,106.2C2280,131,2400,212,2520,269.5C2640,327,2760,359,2880,367.5C3000,376,3120,359,3240,326.7C3360,294,3480,245,3600,228.7C3720,212,3840,229,3960,220.5C4080,212,4200,180,4320,187.8C4440,196,4560,245,4680,294C4800,343,4920,392,5040,343C5160,294,5280,147,5400,73.5C5520,0,5640,0,5760,49C5880,98,6000,196,6120,212.3C6240,229,6360,163,6480,163.3C6600,163,6720,229,6840,277.7C6960,327,7080,359,7200,310.3C7320,261,7440,131,7560,130.7C7680,131,7800,261,7920,294C8040,327,8160,261,8280,220.5C8400,180,8520,163,8580,155.2L8640,147L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490',
    'M0,98L60,130.7C120,163,240,229,360,212.3C480,196,600,98,720,89.8C840,82,960,163,1080,228.7C1200,294,1320,343,1440,318.5C1560,294,1680,196,1800,130.7C1920,65,2040,33,2160,65.3C2280,98,2400,196,2520,228.7C2640,261,2760,229,2880,212.3C3000,196,3120,196,3240,228.7C3360,261,3480,327,3600,326.7C3720,327,3840,261,3960,228.7C4080,196,4200,196,4320,236.8C4440,278,4560,359,4680,383.8C4800,408,4920,376,5040,326.7C5160,278,5280,212,5400,187.8C5520,163,5640,180,5760,155.2C5880,131,6000,65,6120,73.5C6240,82,6360,163,6480,187.8C6600,212,6720,180,6840,163.3C6960,147,7080,147,7200,179.7C7320,212,7440,278,7560,277.7C7680,278,7800,212,7920,171.5C8040,131,8160,114,8280,114.3C8400,114,8520,131,8580,138.8L8640,147L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z',
    'M0,343L60,359.3C120,376,240,408,360,375.7C480,343,600,245,720,196C840,147,960,147,1080,179.7C1200,212,1320,278,1440,261.3C1560,245,1680,147,1800,130.7C1920,114,2040,180,2160,204.2C2280,229,2400,212,2520,171.5C2640,131,2760,65,2880,32.7C3000,0,3120,0,3240,8.2C3360,16,3480,33,3600,40.8C3720,49,3840,49,3960,57.2C4080,65,4200,82,4320,114.3C4440,147,4560,196,4680,245C4800,294,4920,343,5040,351.2C5160,359,5280,327,5400,318.5C5520,310,5640,327,5760,343C5880,359,6000,376,6120,359.3C6240,343,6360,294,6480,228.7C6600,163,6720,82,6840,89.8C6960,98,7080,196,7200,212.3C7320,229,7440,163,7560,163.3C7680,163,7800,229,7920,236.8C8040,245,8160,196,8280,204.2C8400,212,8520,278,8580,310.3L8640,343L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z',
    'M0,0L60,16.3C120,33,240,65,360,89.8C480,114,600,131,720,130.7C840,131,960,114,1080,155.2C1200,196,1320,294,1440,302.2C1560,310,1680,229,1800,228.7C1920,229,2040,310,2160,359.3C2280,408,2400,425,2520,424.7C2640,425,2760,408,2880,359.3C3000,310,3120,229,3240,236.8C3360,245,3480,343,3600,326.7C3720,310,3840,180,3960,163.3C4080,147,4200,245,4320,253.2C4440,261,4560,180,4680,163.3C4800,147,4920,196,5040,236.8C5160,278,5280,310,5400,285.8C5520,261,5640,180,5760,130.7C5880,82,6000,65,6120,106.2C6240,147,6360,245,6480,285.8C6600,327,6720,310,6840,302.2C6960,294,7080,294,7200,253.2C7320,212,7440,131,7560,114.3C7680,98,7800,147,7920,163.3C8040,180,8160,163,8280,163.3C8400,163,8520,180,8580,187.8L8640,196L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z',
];

export const Wave: React.FC<IWave> = ({}) => {
    const [index, setIndex] = useState(0);

    const [d, setD] = useState(ds[index]);

    const spring = useSpring({
        d: d,
        config: config.molasses,
    });

    useEffect(() => {
        let timer = setInterval(() => setIndex((i) => Math.round(Math.random() * (ds.length - 1))), 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => setD(ds[index]), [index]);

    return (
        <Box
            sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                lineHeight: 0,
                zIndex: -1,
                overflow: 'clip',
            }}
        >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 490'>
                <a.path fill='#de4c4a' fillOpacity={1.0} d={spring.d} />
            </svg>
            <Box sx={{ height: '100%', backgroundColor: '#de4c4a', p: 0, m: 0 }} />
        </Box>
    );
};
