import { AnyAction, createSlice, Dispatch, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit';
import { Middleware } from 'redux';
import type { RootState } from '../.';
import { io, Socket } from 'socket.io-client';

type SocketState = {
    connected: boolean;
};

let socket: Socket;

const slice = createSlice({
    name: 'socket',
    initialState: {
        connected: false,
    } as SocketState,
    reducers: {
        connect: (state, { payload }: PayloadAction<string>) => {},
        disconnect: () => {
            socket.disconnect();
        },
        connection: (state, { payload }: PayloadAction<boolean>) => {
            state.connected = payload;
        },
    },
});

export const getSocket = (store?: MiddlewareAPI<Dispatch<AnyAction>, any> | undefined) => {
    if (socket) return socket;
    else if (store) {
        const token = (store.getState() as RootState).user.jwt;
        if (token) {
            socket = io(process.env.REACT_APP_API_URL, {
                auth: { token },
                transports: ['websocket'],
            });

            return socket;
        }
    }
    return null;
};

export const socketMiddleware: Middleware = (store) => {
    return (next) => (action) => {
        const socket = getSocket(store);
        if (slice.actions.connect.match(action)) {
            socket?.on('connect', () => {
                console.log('[io]: connect', socket.id);
                store.dispatch(slice.actions.connection(socket?.connected));
            });
            socket?.on('disconnect', () => {
                console.log('[io]: disconnect', socket.id);
                store.dispatch(slice.actions.connection(socket?.connected));
            });
            socket?.on('error', (error) => {
                console.log('[io]: error', error.message);
                store.dispatch(slice.actions.connection(socket?.connected));
            });
            socket?.on('connect_error', (error) => {
                console.log('[io]: connect_error', error.message);
                store.dispatch(slice.actions.connection(socket?.connected));
            });
            socket?.on('reconnect', () => {
                console.log('[io]: reconnect', socket.id);
                store.dispatch(slice.actions.connection(socket?.connected));
            });
            socket?.on('reconnect_attempt', () => console.log('[io]: reconnect_attempt'));
            socket?.on('reconnect_error', (error) => console.log('[io]: reconnect_error', error.message));
            socket?.on('reconnect_failed', () => console.log('[io]: reconnect_failed'));

            socket?.on('ping', () => console.log('[io]: ping'));

            store.dispatch(slice.actions.connection(socket?.connected || false));
        }
        if (slice.actions.disconnect.match(action)) {
            //socket?.offAny();
            socket?.off('connect');
            socket?.off('disconnect');
            socket?.off('error');
            socket?.off('connect_error');
            socket?.off('reconnect');
            socket?.off('reconnect_attempt');
            socket?.off('reconnect_error');
            socket?.off('reconnect_failed');
            socket?.off('ping');

            store.dispatch(slice.actions.connection(socket?.connected || false));
        }
        return next(action);
    };
};

export default slice.reducer;
export const { connect, disconnect } = slice.actions;
