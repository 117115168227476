import {
    Button,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, NavigateOptions, useLocation, useSearchParams } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { isEmpty } from 'lodash-es';
import { useChangePasswordMutation } from '../../../store/api/auth';
import { useSnackbar } from 'notistack';

type PasswordComponent = React.FC<{}>;

const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .min(8, 'Password is too short')
        .max(24, 'Password is too long')
        .required('Password is required'),
    password: Yup.string()
        .min(8, 'Password is too short')
        .max(24, 'Password is too long')
        .required('Password is required'),
    passwordConfirmation: Yup.string()
        .min(8, 'Password is too short')
        .max(24, 'Password is too long')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Password is required'),
});

export const Password: PasswordComponent = ({ }) => {
    const [searchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { state } = useLocation();

    const handleCancel = () =>
        navigate(
            {
                pathname: '/dashboard/user/profile',
                search: searchParams.toString(),
            },
            { replace: true, state }
        );

    const [changePassword, { isLoading }] = useChangePasswordMutation();

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            currentPassword: '',
            password: '',
            passwordConfirmation: '',
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            try {
                await changePassword(values).unwrap();
                enqueueSnackbar('Праоль обновлен', { variant: 'success' });
                resetForm();
            } catch (e: any) {
                enqueueSnackbar(e.data.error.message ? e.data.error.message : `Ошибка обновления пароля`, {
                    variant: 'error',
                });
            }
        },
    });

    const handleTrySubmit = () => {
        if (isEmpty(touched) || !isEmpty(errors)) handleSubmit();
    };

    const [show, setShow] = useState({
        currentPassword: false,
        password: false,
        passwordConfirmation: false,
    });

    const handleShow = (key: 'currentPassword' | 'password' | 'passwordConfirmation') =>
        setShow((state) => ({ ...state, [key]: !state[key] }));
    const handleHide = (key: 'currentPassword' | 'password' | 'passwordConfirmation') =>
        setShow((state) => ({ ...state, [key]: false }));

    return (
        <Dialog open fullWidth maxWidth='sm' scroll='body' onClose={handleCancel}>
            <DialogTitle>Сменить пароль</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack spacing={2}>
                    <ClickAwayListener onClickAway={() => handleHide('currentPassword')}>
                        <FormControl variant='outlined'>
                            <InputLabel htmlFor='current-password'>Текущий пароль</InputLabel>
                            <OutlinedInput
                                label='Текущий пароль'
                                id='current-password'
                                type={show.currentPassword ? 'text' : 'password'}
                                value={values.currentPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name='currentPassword'
                                error={touched.currentPassword && Boolean(errors.currentPassword)}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={() => handleShow('currentPassword')}
                                            tabIndex={-1}
                                        >
                                            {show.currentPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onSubmit={handleTrySubmit}
                            />
                            {touched.currentPassword && Boolean(errors.currentPassword) && (
                                <FormHelperText
                                    error={touched.currentPassword && Boolean(errors.currentPassword)}
                                >
                                    {touched.currentPassword && errors.currentPassword}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={() => handleHide('password')}>
                        <FormControl variant='outlined'>
                            <InputLabel htmlFor='password'>Новый пароль</InputLabel>
                            <OutlinedInput
                                label='Новый пароль'
                                id='password'
                                type={show.password ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name='password'
                                error={touched.password && Boolean(errors.password)}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => handleShow('password')} tabIndex={-1}>
                                            {show.password ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onSubmit={handleTrySubmit}
                            />
                            {touched.password && Boolean(errors.password) && (
                                <FormHelperText error={touched.password && Boolean(errors.password)}>
                                    {touched.password && errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={() => handleHide('passwordConfirmation')}>
                        <FormControl variant='outlined'>
                            <InputLabel htmlFor='password-сonfirmation'>Повторите пароль</InputLabel>
                            <OutlinedInput
                                label='Повторите пароль'
                                id='password-сonfirmation'
                                type={show.passwordConfirmation ? 'text' : 'password'}
                                value={values.passwordConfirmation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name='passwordConfirmation'
                                error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={() => handleShow('passwordConfirmation')}
                                            tabIndex={-1}
                                        >
                                            {show.passwordConfirmation ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onSubmit={handleTrySubmit}
                            />
                            {touched.passwordConfirmation && Boolean(errors.passwordConfirmation) && (
                                <FormHelperText
                                    error={
                                        touched.passwordConfirmation && Boolean(errors.passwordConfirmation)
                                    }
                                >
                                    {touched.passwordConfirmation && errors.passwordConfirmation}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </ClickAwayListener>
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleCancel}>Отменить</Button>
                <LoadingButton
                    variant='contained'
                    onClick={() => handleSubmit()}
                    loading={isLoading}
                    disabled={isEmpty(touched) || !isEmpty(errors)}
                >
                    Редактировать
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
