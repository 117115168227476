import { RootState } from '..';
import { api } from './api';
import { providesKeys } from '../utills';
import { UniqueIdentifier } from '@dnd-kit/core';
import { Assigment } from '../slices/assignments';
import { AutocompleteChangeReason } from '@mui/material';

type UpdateAssignmentsRequest = {
    task: UniqueIdentifier;
    values: Array<Omit<Assigment, 'id'>>;
    reason: AutocompleteChangeReason;
};

export const assignmentApi = api
    .enhanceEndpoints({
        addTagTypes: ['assigment'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getAssignments: builder.query<Record<UniqueIdentifier, Assigment>, void>({
                query: () => '/assignments',
                providesTags: (results) => [...providesKeys(Object.keys(results || {}), 'assigment')],
            }),
            updateAssignments: builder.mutation<
                Record<UniqueIdentifier, Assigment>,
                UpdateAssignmentsRequest
            >({
                query: (data) => ({
                    url: '/assignments',
                    method: 'PUT',
                    body: {
                        data,
                    },
                }),
                invalidatesTags: ['assigment'],
            }),
        }),
    });

export const { useGetAssignmentsQuery, useUpdateAssignmentsMutation } = assignmentApi;
