import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    closestCenter,
    DndContext,
    MeasuringStrategy,
    DragOverlay,
    defaultDropAnimation,
    SensorContext,
    useSensors,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
} from '@dnd-kit/core';
import { createPortal } from 'react-dom';
import type {
    DragStartEvent,
    DragMoveEvent,
    DragOverEvent,
    DragEndEvent,
    DragCancelEvent,
    DropAnimation,
    Modifier,
} from '@dnd-kit/core';

import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
    buildTree,
    flattenTree,
    getProjectChildCount,
    getProjection,
    ProjectFlatten,
    removeChildrenOf,
    removeProject,
    setProperty,
} from './utilites';
import { SortableTreeItem } from './SortableTreeItem';
import { List } from '@mui/material';
import { sortableTreeKeyboardCoordinates } from './keyboardCoordinates';

interface ISortableTree {
    indentationWidth?: number;
}

const intialProjects = [
    {
        id: 19,
        title: 'Project 0',
        index: 0,
        name: 'project-19',
        parent: null,
        collapsed: true,
    },
    {
        id: 20,
        title: 'Project 1',
        index: 1,
        name: 'project-20',
        parent: null,
        collapsed: true,
    },
    {
        id: 21,
        title: 'Project 3',
        index: 2,
        name: 'project-21',
        parent: null,
        collapsed: true,
    },
    {
        id: 22,
        title: 'Project 4',
        index: 0,
        name: 'project-22',
        parent: 'project-19',
        collapsed: true,
    },
    {
        id: 23,
        title: 'Project 5',
        index: 0,
        name: 'project-23',
        parent: 'project-22',
        collapsed: true,
    },
];

const measuring = {
    droppable: {
        strategy: MeasuringStrategy.Always,
    },
};

const adjustTranslate: Modifier = ({ transform }) => {
    return {
        ...transform,
        y: transform.y - 25,
        x: transform.x - 100,
    };
};

export const SortableTree: React.FC<ISortableTree> = ({ indentationWidth = 50 }) => {
    const [projects, setProjects] = useState(buildTree(intialProjects));
    const [activeId, setActiveId] = useState<string | null>(null);
    const [overId, setOverId] = useState<string | null>(null);

    const [offsetLeft, setOffsetLeft] = useState(0);
    const [currentPosition, setCurrentPosition] = useState<{
        parentId: string | null;
        overId: string | null;
    } | null>(null);

    const flattenedProjects = useMemo(() => {
        const flattened = flattenTree(projects);
        const collapsed = flattened.reduce<string[]>(
            (acc, { children, collapsed, name }) => (collapsed && children.length ? [...acc, name] : acc),
            []
        );
        return removeChildrenOf(flattened, activeId ? [activeId, ...collapsed] : collapsed);
    }, [projects, activeId]);

    const projectIds = useMemo(() => flattenedProjects.map(({ name }) => name), [flattenedProjects]);
    const activeProject = useMemo(
        () => (activeId ? flattenedProjects.find(({ name }) => name === activeId) : null),
        [activeId, flattenedProjects]
    );

    const projected =
        activeId && overId
            ? getProjection(flattenedProjects, activeId, overId, offsetLeft, indentationWidth)
            : null;

    const resetState = () => {
        setOverId(null);
        setActiveId(null);
        setOffsetLeft(0);
        setCurrentPosition(null);

        document.body.style.setProperty('cursor', '');
    };

    const handleDragStart = (e: DragStartEvent) => {
        setActiveId(e.active.id as string);
        setOverId(e.active.id as string);

        const activeItem = flattenedProjects.find(({ name }) => name === activeId);

        if (activeItem) {
            setCurrentPosition({
                parentId: activeItem.parent,
                overId: activeId,
            });
        }

        document.body.style.setProperty('cursor', 'grabbing');
    };
    const handleDragMove = (e: DragMoveEvent) => setOffsetLeft(e.delta.x);
    const handleDragOver = (e: DragOverEvent) => setOverId((e.over?.id as string) ?? null);
    const handleDragEnd = (e: DragEndEvent) => {
        resetState();
        if (projected && e.over) {
            const { depth, parent } = projected;
            const clonedProjects: ProjectFlatten[] = JSON.parse(JSON.stringify(flattenTree(projects)));

            const overIndex = clonedProjects.findIndex(({ name }) => name === e?.over?.id);
            const activeIndex = clonedProjects.findIndex(({ name }) => name === e.active.id);

            const activeTreeItem = clonedProjects[activeIndex];
            clonedProjects[activeIndex] = { ...activeTreeItem, depth, parent };

            const sortedProjects = arrayMove(clonedProjects, activeIndex, overIndex);
            const newProjects = buildTree(sortedProjects);

            setProjects(newProjects);
        }
    };
    const handleDragCancel = (e: DragCancelEvent) => resetState();

    const handleCollapse = (id: string) =>
        setProjects((projects) => setProperty(projects, id, 'collapsed', (value) => !value));

    const handleRemove = (id: string) => setProjects((projects) => removeProject(projects, id));
    //console.log(Object.keys(intialProjects));

    const sensorContext = useRef({
        items: flattenedProjects,
        offset: offsetLeft,
    });
    const [coordinateGetter] = useState(() =>
        sortableTreeKeyboardCoordinates(sensorContext, indentationWidth)
    );
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter,
        })
    );

    useEffect(() => setActiveId('project-21'), []);

    return (
        <DndContext
            //announcements={announcements}
            sensors={sensors}
            collisionDetection={closestCenter}
            measuring={measuring}
            onDragStart={handleDragStart}
            onDragMove={handleDragMove}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={projectIds} strategy={verticalListSortingStrategy}>
                <List>
                    {flattenedProjects.map(({ name, title, children, collapsed, depth }) => (
                        <SortableTreeItem
                            key={name}
                            id={name}
                            value={title}
                            depth={name === activeId && projected ? projected.depth : depth}
                            collapsed={Boolean(collapsed && children.length)}
                            onCollapse={children.length ? () => handleCollapse(name) : undefined}
                            collapsible={children.length > 0}
                            indentationWidth={indentationWidth}
                            onRemove={() => handleRemove(name)}
                        />
                    ))}
                </List>
                {createPortal(
                    <DragOverlay dropAnimation={defaultDropAnimation} modifiers={[adjustTranslate]}>
                        {activeId && activeProject && (
                            <SortableTreeItem
                                id={activeId}
                                depth={activeProject.depth}
                                clone
                                childCount={getProjectChildCount(projects, activeId)}
                                value={activeProject.title}
                                indentationWidth={indentationWidth}
                            />
                        )}
                    </DragOverlay>,
                    document.body
                )}
            </SortableContext>
        </DndContext>
    );
};
