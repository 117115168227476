import { UniqueIdentifier } from '@dnd-kit/core';
import { Delete, PersonAdd } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Autocomplete,
    Avatar,
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UserAvatar } from '../../../components/UserAvatar';
import { ValidationError } from '../../../store/api';
import {
    useCreateCollaboratorMutation,
    useRemoveCollaborationMutation,
} from '../../../store/api/collaboration';
import { useTypedSelector } from '../../../store/hooks';
import { Collaborator, allCollaboratorsSelector } from '../../../store/slices/collaborators';
import { DashboardContext, dashboardContext } from '../dashboardContext';

interface IShareProject { }

export const ShareProject: React.FC<IShareProject> = ({ }) => {
    const { isMobile } = useContext(dashboardContext) as DashboardContext;
    const { enqueueSnackbar } = useSnackbar();
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [createCollaborator, { isLoading, isError, error, data }] = useCreateCollaboratorMutation();
    const [removeCollaborator] = useRemoveCollaborationMutation();
    const collaborators = useTypedSelector(allCollaboratorsSelector);
    const projects = useTypedSelector((state) => state.projects);
    const user = useTypedSelector((state) => state.user);
    const project = useMemo(() => projects[projectId!], [projects, projectId]);

    const users = useMemo(() => {
        const me = {
            id: user.id!,
            username: user.username!,
            firstname: user.firstname!,
            lastname: user.lastname!,
            email: user.email!,
            avatar: user.avatar!,
        };
        const collabers = Object.values(collaborators);

        const all = [me, ...collabers];

        const table = all.reduce(
            (acc, item) => ({ ...acc, [item.id]: item }),
            {} as Record<UniqueIdentifier, Collaborator>
        );

        const search = [project.user, ...project.users];

        return search.map((id) => table[id]);
    }, [user, project, collaborators]);

    const options = useMemo(
        () =>
            Object.values(collaborators).filter(
                (c) => !(project.users.includes(c.id) || project.user === c.id)
            ),
        [collaborators, project]
    );

    const [value, setValue] = useState<null | Collaborator>(null);
    const [inputValue, setInputValue] = useState<string>('');

    const handleValueChange = (e: React.SyntheticEvent<Element, Event>, value: Collaborator | null) =>
        setValue(value);
    const handleInputChange = (e: React.SyntheticEvent<Element, Event>, value: string) =>
        setInputValue(value);

    const handleCreateCollaborator = async () => {
        if (value && project)
            try {
                const res = await createCollaborator({
                    collaborator: value.id,
                    project: project.id,
                }).unwrap();

                enqueueSnackbar(res.success, { variant: 'success' });
            } catch (e: any) {
                enqueueSnackbar(e.data?.error?.message || 'Ошибка', { variant: 'error' });
            }
    };

    const handleDeleteCollaborator = async (id: UniqueIdentifier) => {
        if (project)
            try {
                const res = await removeCollaborator({
                    collaborator: id,
                    project: project.id,
                }).unwrap();

                enqueueSnackbar(res.success, { variant: 'success' });
            } catch (e: any) {
                console.log(e);
                enqueueSnackbar(e.data?.error?.message || 'Ошибка', { variant: 'error' });
            }
    };

    const handleCancel = () =>
        navigate({
            pathname: `/dashboard/projects/${projectId}`,
            search: searchParams.toString(),
        });

    return (
        <Dialog open onClose={handleCancel} maxWidth='sm' fullWidth>
            <DialogTitle>Добавить участников</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                    <Autocomplete
                        sx={{ flexGrow: 1 }}
                        value={value}
                        onChange={handleValueChange}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        options={options}
                        autoHighlight
                        getOptionLabel={(options) => options.username}
                        filterOptions={(options, { inputValue }) =>
                            options.filter(
                                ({ username, firstname, lastname, email }) =>
                                    username.startsWith(inputValue) ||
                                    firstname.startsWith(inputValue) ||
                                    lastname.startsWith(inputValue) ||
                                    email.startsWith(inputValue)
                            )
                        }
                        renderOption={(props, { firstname, lastname, username, email }) => (
                            <MenuItem
                                sx={{
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                }}
                                {...props}
                            >
                                <Avatar>{`${lastname?.substring(0, 1).toUpperCase()}${firstname
                                    ?.substring(0, 1)
                                    .toUpperCase()}`}</Avatar>
                                <Stack>
                                    <Typography variant='h6' sx={{ wordWrap: 'normal' }}>
                                        {lastname} {firstname}
                                    </Typography>
                                    <Typography variant='caption' sx={{ wordWrap: 'normal' }}>
                                        {`@${username}`} {email}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Выберите пользователя'
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <LoadingButton
                        variant='contained'
                        onClick={handleCreateCollaborator}
                        disabled={!Boolean(value)}
                        loading={isLoading}
                    >
                        <PersonAdd />
                    </LoadingButton>
                </Stack>
                <Stack sx={{ py: 2 }} spacing={1}>
                    <Typography>Автор</Typography>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <UserAvatar user={collaborators[project.user]} />
                        <Stack>
                            <Typography variant='h6'>
                                {collaborators[project.user].lastname} {collaborators[project.user].firstname}
                            </Typography>
                            <Typography variant='caption'>
                                {collaborators[project.user].username} {collaborators[project.user].email}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Typography>Участники</Typography>
                    {users.map((u) => (
                        <Stack key={u.username} direction='row' spacing={1} alignItems='center'>
                            <UserAvatar user={u} />
                            <Stack>
                                <Typography variant='h6'>
                                    {u.lastname} {u.firstname}
                                </Typography>
                                <Typography variant='caption'>
                                    {u.username} {u.email}
                                </Typography>
                            </Stack>
                            {project.user === user.id && user.id !== u.id && (
                                <Tooltip title='Удалить пользователя'>
                                    <IconButton onClick={() => handleDeleteCollaborator(u.id)}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    ))}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleCancel}>Отменить</Button>
                <LoadingButton variant='contained' onClick={handleCancel}>
                    OK
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
