import { Container, Paper, Stack } from '@mui/material';
import React from 'react';

interface IPageDialog {
    children: React.ReactNode;
    elevation?: number;
}

export const PageDialog: React.FC<IPageDialog> = ({ children, elevation = 15 }) => {
    return (
        <Stack sx={{ height: '100%' }} justifyContent='center' alignItems='center'>
            <Container maxWidth='sm'>
                <Paper sx={{ p: (theme) => theme.spacing(4) }} elevation={elevation}>
                    {children}
                </Paper>
            </Container>
        </Stack>
    );
};
