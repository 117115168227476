import { UniqueIdentifier, useDndMonitor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Box, Fab, Stack, Typography } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { useSetTreeMutation } from '../../../store/api/tree';
import { useTypedDispatch, useTypedSelector } from '../../../store/hooks';
import { setTree } from '../../../store/slices/tree';
import { DashboardContext, dashboardContext, IDndEvent } from '../dashboardContext';
import { dndType } from '../utils';
import { CreateProject } from './components/CreateProject';
import { Project } from './components/Project';
import { buildTree, filter, flatten, getProjection, moveProjects, setProperty } from './utils';

interface ITree {}

export const Tree: React.FC<ITree> = ({}) => {
    const dispatch = useTypedDispatch();
    const [treeMutation, { isLoading, isError }] = useSetTreeMutation();
    const { active, over, reset } = useContext(dashboardContext) as DashboardContext;
    const [offset, setOffset] = useState(0);
    const leaves = useTypedSelector((state) => state.tree);
    const projects = useTypedSelector((state) => state.projects);

    const tree = useMemo(() => buildTree(leaves), [leaves]);
    const flat = useMemo(() => flatten(tree), [tree]);
    const filtered = useMemo(
        () => filter(flat, active && active.type === 'project' ? active.id : null),
        [flat, active]
    );

    const projected = useMemo(
        () =>
            active && over && active.type === 'project' && over.type === 'project'
                ? getProjection(filtered, active, over, offset, 40)
                : null,
        [active, over, filtered, offset]
    );

    const handleCollapse = async (e: UniqueIdentifier) => {
        const collapsed = setProperty(tree, e, 'collapsed', (value) => !value);
        const flaten = flatten(collapsed);
        await treeMutation(flaten);
    };

    useDndMonitor({
        onDragMove: (e) => setOffset(e.delta.x),
        onDragEnd: async (e) => {
            setOffset(0);
            if (dndType(e, 'project', 'project')) {
                if (projected) {
                    const moved = moveProjects(
                        flat,
                        e.over!.data.current!.id,
                        e.active!.data.current!.id,
                        projected.depth,
                        projected.parent
                    );
                    //dispatch(setTree(moved));
                    await treeMutation(moved);
                }
            }
        },
        onDragCancel: () => setOffset(0),
    });

    return (
        <Stack spacing={1} sx={{ width: (theme) => theme.spacing(40), pt: 1, px: 1, postion: 'relative' }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='h5'>Проекты</Typography>
                <CreateProject />
            </Stack>
            <SortableContext
                items={filtered.map(({ id }) => `project-${id}`)}
                strategy={verticalListSortingStrategy}
            >
                {filtered.map(({ id, depth, project, collapsed, children }) => (
                    <Project
                        key={`leaf-${id}`}
                        id={id}
                        depth={
                            active?.type === 'project' && active?.id === id && projected
                                ? projected.depth
                                : depth
                        }
                        project={projects[project]}
                        collapsed={Boolean(collapsed && children.length)}
                        children={children}
                        onCollapse={handleCollapse}
                        over={over?.type === 'project' && over?.id === id}
                    />
                ))}
            </SortableContext>
            <Box sx={{ height: 160 }} />
        </Stack>
    );
};
