import { Box, CircularProgress, FormControlLabel, Switch, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useMemo, useState } from 'react';
import { useUpdateUserSettingsMutation } from '../../../store/api/settings';
import { useTypedSelector } from '../../../store/hooks';

type SettingsComponent = React.FC<{}>;

export const Settings: SettingsComponent = ({}) => {
    const theme = useTheme();
    const settings = useTypedSelector((state) => state.settings);
    const [updateSettings, { isLoading }] = useUpdateUserSettingsMutation();

    const isDark = useMemo(() => (settings.theme ? settings.theme === 'dark' : 'dark'), [settings]);

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isDark) await updateSettings({ theme: 'light' });
        else await updateSettings({ theme: 'dark' });
    };

    return (
        <Stack spacing={2}>
            <Typography variant='h4'>НАСТРОЙКИ</Typography>
            <FormControlLabel
                control={<Switch value={isDark} onChange={handleChange} disabled={isLoading} />}
                label={settings.theme.toLocaleUpperCase()}
            />
            {isLoading && (
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        right: (theme) => theme.spacing(4),
                        bottom: (theme) => theme.spacing(4),
                    }}
                    size={theme.typography.h4.fontSize}
                />
            )}
        </Stack>
    );
};
