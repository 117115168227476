import { UniqueIdentifier } from '@dnd-kit/core';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';
import { NotificationsSettings, Settings } from '../slices/settings';
import { userApi } from './user';

export const settingsApi = userApi
    .enhanceEndpoints({
        addTagTypes: ['settings'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUserSettings: builder.query<Settings, void>({
                query: () => `/user-settings`,
                providesTags: ['settings'],
            }),
            updateUserSettings: builder.mutation<Settings, Partial<Settings>>({
                query: (data) => ({
                    url: `/user-settings`,
                    method: 'PUT',
                    body: {
                        data,
                    },
                }),
            }),
            updateNotificationsSettings: builder.mutation<
                NotificationsSettings,
                Partial<NotificationsSettings>
            >({
                query: ({ id, ...data }) => ({
                    url: `/user-notifications/`,
                    method: 'PUT',
                    body: {
                        data,
                    },
                }),
            }),
        }),
    });

export const {
    useGetUserSettingsQuery,
    useUpdateUserSettingsMutation,
    useUpdateNotificationsSettingsMutation,
} = settingsApi;
