import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';
import { projectApi } from './project';
import { UniqueIdentifier } from '@dnd-kit/core';
import { Collaborator } from '../slices/collaborators';
import { number } from 'yup';

type CollaboratorsResponse = Record<UniqueIdentifier, Collaborator>;

type CollaboratorRequest = {
    collaborator: UniqueIdentifier;
    project: UniqueIdentifier;
};

type CollaboratorResponse = {
    success: string;
};

type AcceptCollaborationRequest = {
    collaborationToken: string | null;
    project: UniqueIdentifier | undefined;
};

export const collaborationApi = projectApi.enhanceEndpoints({}).injectEndpoints({
    endpoints: (builder) => ({
        getCollaborators: builder.query<CollaboratorsResponse, void>({
            query: () => '/collaborations',
        }),
        createCollaborator: builder.mutation<CollaboratorResponse, CollaboratorRequest>({
            query: (data) => ({
                url: '/collaborations',
                method: 'POST',
                body: {
                    data,
                },
            }),
        }),
        acceptCollaboration: builder.mutation<any, AcceptCollaborationRequest>({
            query: (data) => ({
                url: '/collaborations',
                method: 'PUT',
                body: {
                    data,
                },
            }),
            invalidatesTags: ['project', 'leaf', 'section', 'task'],
        }),
        removeCollaboration: builder.mutation<
            { success: string },
            { collaborator: UniqueIdentifier; project: UniqueIdentifier }
        >({
            query: (data) => ({
                url: '/collaborations',
                method: 'PATCH',
                body: {
                    data,
                },
            }),
        }),
    }),
});

export const {
    useGetCollaboratorsQuery,
    useCreateCollaboratorMutation,
    useAcceptCollaborationMutation,
    useRemoveCollaborationMutation,
} = collaborationApi;
