import { UniqueIdentifier } from '@dnd-kit/core';
import { IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { format, parseISO } from 'date-fns';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { UserAvatar } from '../../../../../components';
import { UploadItemStrapi } from '../../../../../components/upload';
import { StrapiMedia } from '../../../../../store/api';
import { useTypedSelector } from '../../../../../store/hooks';
import { Collaborator, collaboratorSelector, Message, userSelector } from '../../../../../store/slices';
import { useIsMobile } from '../../../dashboardContext';
import { MessageActions } from './MessageActions';
import { MessageReply } from './MessageReply';

type MessageRowComponent = {
    index: number;
    message: Message;
    collaborators: Collaborator[];
    messages: Record<UniqueIdentifier, Message>;
    onReplay: (message: Message) => void;
    onEdit: (message: Message) => void;
    onDelete: (message: Message) => void;
    onMediaClick: (message: Message, item: StrapiMedia) => void;
};

export const MessageRow = React.memo<MessageRowComponent>(
    ({ index, message, messages, collaborators, onReplay, onEdit, onDelete, onMediaClick }) => {
        const isMobile = useIsMobile();
        const theme = useTheme();
        const user = collaborators[0];
        const author = useTypedSelector((state) => collaboratorSelector(state, message.user));
        const date = format(parseISO(message.updatedAt), 'MM/dd HH:ss');

        const mentions = message.mentions?.flatMap((userId) =>
            collaborators.find(({ id }) => id === userId)?.username
                ? [`@${collaborators.find(({ id }) => id === userId)!.username}`]
                : []
        );

        const isAuthor = user.id === author?.id;

        return (
            <Stack
                direction='row'
                flexDirection={isAuthor ? 'row-reverse' : 'row'}
                alignItems='flex-end'
                sx={{ p: 0.5 }}
            >
                {author && !isAuthor && (
                    <Tooltip title={`@${author.username}`}>
                        <IconButton sx={{ p: 0, m: 0 }}>
                            <UserAvatar user={author} />
                        </IconButton>
                    </Tooltip>
                )}
                <Stack
                    sx={{
                        px: 1,
                        borderRadius: '8px',
                        background: (theme) =>
                            isAuthor
                                ? theme.palette.mode === 'dark'
                                    ? 'rgba(255,255,255,0.1)'
                                    : 'rgba(0,0,0,0.1)'
                                : theme.palette.mode === 'dark'
                                ? 'rgba(255,255,255,0.05)'
                                : 'rgba(0,0,0,0.05)',
                        ml: isAuthor ? 12 : 1,
                        mr: isAuthor ? 2 : 10,
                        pr: 4,
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-all',
                        position: 'relative',
                        ':hover': {
                            '&>*:first-of-type': {
                                display: 'block',
                            },
                        },
                        ...(isMobile && {
                            '&>*:first-of-type': {
                                display: 'block',
                            },
                        }),
                    }}
                >
                    <MessageActions
                        isAuthor={isAuthor}
                        message={message}
                        onReplay={onReplay}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                    {message.replay && messages && messages[message.replay] && (
                        <MessageReply
                            message={messages[message.replay]}
                            collaborators={collaborators}
                            onMediaClick={onMediaClick}
                        />
                    )}
                    <Stack direction='row' flexWrap='wrap' sx={{ py: 0.5 }}>
                        {message.media?.map((item) => (
                            <UploadItemStrapi
                                item={item}
                                key={`media-${item.id}-i`}
                                onClick={(item) => onMediaClick(message, item)}
                                //onClick={handleStrapiClick}
                                //onDelete={onDelete}
                                //size={size}

                                size='medium'
                            />
                        ))}
                    </Stack>
                    <Typography>
                        <Highlighter
                            searchWords={mentions}
                            textToHighlight={message.message}
                            highlightStyle={{
                                //fontWeight: 'bold',
                                background: 'inherit',
                                color: theme.palette.primary.main,
                            }}
                        />
                    </Typography>
                    <Typography
                        //component='span'
                        sx={{
                            fontSize: 10,
                            color: (theme) => theme.palette.action.disabled,
                            textAlign: 'right',
                        }}
                        variant='caption'
                    >
                        {date}
                    </Typography>
                </Stack>
            </Stack>
        );
    }
);
